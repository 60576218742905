(function () {
  'use strict';
  /**
   * @ngdoc directive
   * @name com.module.core.directive:adminBox
   * @description
   * # adminBox
   */
  angular
    .module('com.module.core')
    .component('controlButtons', {
        templateUrl: 'app/modules/core/directives/control-buttons/template.html',
        bindings:{
          elementId:'@'
        }
    });

})();
