(function () {
  'use strict';
  angular
    .module('com.module.core')
    .config(function ($stateProvider, $urlRouterProvider) {
      $stateProvider
        .state('router', {
          url: '/router',
          template: '<div class="lockscreen" style="height: 100%"></div>',
          controller: 'RouteCtrl',
          resolve: {
            currentUser: function(AppAuth){
              return AppAuth.getAuthenticatedUser();
            }
          }
        })
        .state('error', {
          url: '/error',
          template: '<div class="text-center alert alert-danger" style="margin: 100px">An error occurred.</div>',
          controller:function($scope,ApiService,$state){
            ApiService.checkConnection().then(function(){
              $state.go('router');
            }).catch(function(err){
              console.log(err)
            })
          }
        })
        .state('app', {
          abstract: true,
          url: '/app',
          templateUrl: 'app/modules/core/views/app.html',
          controller: 'MainCtrl',
          resolve: {
            currentUser: function(AppAuth){
              return AppAuth.getAuthenticatedUser();
            },
            settings: function(AppAuth,SettingService){
              return SettingService.find();
            }
          }
        })
        // .state('app.home', {
        //   url: '',
        //   templateUrl: 'app/modules/core/views/home.html',
        //   controller: 'HomeCtrl'
        // });
          
        $urlRouterProvider.otherwise( function($injector) {
          var $state = $injector.get("$state");
          $state.go('router');
        });
    });

})();
