(function () {
  'use strict';
  angular.module('com.module.users')
    .run(function ($rootScope, gettextCatalog, SystemUser) {
      // $rootScope.currentUser = SystemUser.currentUser().$promise;
      // $rootScope.currentUser.then(function(res){
      //   $rootScope.currentUser=res.user;
      //   if(res.user.roles.indexOf('superadmin')>=0) {
      //     $rootScope.addMenu(gettextCatalog.getString('Users'), 'app.users.list', 'fa-user');
      //   }
        
      // })
    });

})();
