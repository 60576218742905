(function() {
  'use strict';
  angular
    .module('com.module.reporting')
    .controller('ReportingSingleController', function(previewComponent,$timeout, $state, ReportingService, report,CoreService) {
      var vm = this;
      vm.report = report;
      vm.report.data = vm.report.data || {};
      if(vm.report.data.style && !vm.report.data.preview) {
        vm.report.data.preview = vm.report.data.style;
      }
      vm.formOptions = {};
      vm.reportData = vm.report.data;
      vm.getPreviewUrl = function(){
        return CoreService.env.sylvaHost + previewComponent +
        '?customStyle=report.template.'+vm.report.data.label.replace(/ /g,'_').toLowerCase();
      }
      vm.updatePreview = function() {
        vm.report.key = 'report.template.' + vm.report.data.label.replace(/ /g,'_').toLowerCase();
        ReportingService.upsert(vm.report)
        .then(function(){
          window.open(vm.getPreviewUrl(), '_blank');
        })
        .catch(function(err){
          console.error(err);
        })
      };
      vm.submit = function() {
        vm.report.key = 'report.template.' + vm.report.data.label.replace(/ /g,'_').toLowerCase();
        vm.report.data.style = vm.report.data.preview;
        vm.report.data.preview = null;
        ReportingService.upsert(vm.report).then(function() {
          $state.go('^.list');
        });
      };
      vm.formFields = [{
          className: "col-xs-4",
          key: 'data.label',
          type: 'input',
          templateOptions: {
            label: 'Label',
            required: true
          }
        },
        {
          className: "col-xs-4",
          key: 'data.sourceAPI',
          type: 'select',
          defaultValue: 'HMAC-SHA1',
          templateOptions: {
            label: 'Source API Function',
            options: [],
            valueProp: 'value',
            labelProp: 'label',
            required: false,
            placeholder: 'Source API Function'
          },
          controller: /* @ngInject */ function($scope) {
            $scope.to.options = [
              {value: {
                model:'Organization',
                func: 'getAssessmentReportData',
                preprocessFn: '',
                args: [
                  'organizationId',
                  'projectId',
                  'assessmentId'
                ]
              },label: 'SYLVA Assessment Report Data'}
            ]
          }
        },
      ]



    });

})();
