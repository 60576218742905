(function () {
  'use strict';

  /*jshint sub:true*/
  /*jshint camelcase: false */

angular
    .module('com.module.users')
    .config(function($stateProvider) {
        $stateProvider
            .state('app.userProfile', {
                url: '/profile',
                templateUrl: 'app/modules/users/user-profile/template.html',
                controllerAs: 'ctrl',
                controller: function(data,UserService,SystemUser,CoreService) {
                    this.user = data.user;
                    this.formFields = UserService.getFormFields('edit');
                    this.formOptions = {};
                    this.submit = function() {
                        UserService.upsert(this.user).then(function() {window.location.reload()});
                    };
                    this.resetPassword = function(email){
                        SystemUser.resetPassword({email:email}).$promise.then(function(result){
                            CoreService.toastSuccess('Password reset requested!','Please check your email: '+email);
                        }).catch(CoreService.error)
                    }
                    
                },
                resolve: {
                    'data': function(SystemUser) {
                        return SystemUser.currentUser().$promise;
                    }
                }
            });
    });
    
})();