(function () {
  'use strict';
  angular
    .module('com.module.settings')
    .service('SettingService', function ($state, CoreService, Setting, gettextCatalog,$http) {
      var vm = this;
      vm.settings = [];
      vm.find = function (refresh) {
        refresh = refresh || vm.settings.length===0; 
        return refresh?$http.get(CoreService.env.apiUrl+'/Settings').then(function(res){
          vm.settings = res.data;
          return vm.settings;
        }):vm.settings;
      };
      
      vm.getConf = function() {
        return $http.get(CoreService.env.apiUrl + '/Settings/key/admin.configuration', {
            ignoreLoadingBar: true
          })
          .then(function(res) {
            var roles = res.data[0].data.allowedLoginRoles;
            console.log(res);
            window.AESG.allowedLoginRoles = roles;
          })
      }

      vm.key = function (key) {
        for(var i=0,len=vm.settings.length;i<len;i++){
          if(vm.settings[i].key===key) return vm.settings[i];
        }
        return null;
      };

      vm.findById = function (id) {
        return Setting.findById({
          id: id
        }).$promise;
      };
      
      vm.findByKey = function (key) {
        return Setting.find({
          filter:{
            where:{
              key: key
            }
          }
        }).$promise.then(function(res){
          return res[0];
        });
        
      }

      vm.upsert = function (setting) {
        return Setting.upsert(setting).$promise
          .then(function () {
            CoreService.toastSuccess(
              gettextCatalog.getString('Setting saved'),
              gettextCatalog.getString('Your setting is safe with us!')
            );
          })
          .catch(function (err) {
            CoreService.toastError(
              gettextCatalog.getString('Error saving setting '),
              gettextCatalog.getString('This setting could no be saved: ' + err)
            );
          }
        );
      };

      vm.delete = function (id, successCb, cancelCb) {
        CoreService.confirm(
          gettextCatalog.getString('Are you sure?'),
          gettextCatalog.getString('Deleting this cannot be undone'),
          function () {
            Setting.deleteById({id: id}, function () {
              CoreService.toastSuccess(
                gettextCatalog.getString('Setting deleted'),
                gettextCatalog.getString('Your setting is deleted!'));
              successCb();
            }, function (err) {
              CoreService.toastError(
                gettextCatalog.getString('Error deleting setting'),
                gettextCatalog.getString('Your setting is not deleted! ') + err);
              cancelCb();
            });
          },
          function () {
            cancelCb();
          }
        );
      };


      vm.getFormFields = function () {
        var form = [
          {
            key: 'key',
            type: 'input',
            templateOptions: {
              label: gettextCatalog.getString('key'),
              required: true
            }
          },
          // {
          //   key: 'data',
          //   type: 'custom',
          //   templateOptions: {
          //     label: gettextCatalog.getString('data'),
          //     required: true
          //   },
          //   controller:function($timeout,$scope){
              
          //     $timeout(function(){
          //       // create the editor
          //       var container = document.getElementById("jsoneditor");
          //       var options = {onChange:dataChanged};
          //       var editor = new JSONEditor(container, options);
          //       // set json
          //       editor.set($scope.model.data);
          //       function dataChanged(){
          //         var json = editor.get();
          //         console.log(json,$scope.model);
          //         $scope.model.data = json;
                  
          //       }
                
          //     },100)
          //   }
          // }
        ];
        return form;
      };
      
      return vm;

    });

})();
