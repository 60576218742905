// CommonJS package manager support
if (typeof module !== 'undefined' && typeof exports !== 'undefined' &&
  module.exports === exports) {
  // Export the *name* of this Angular module
  // Sample usage:
  //
  //   import lbServices from './lb-services';
  //   angular.module('app', [lbServices]);
  //
  module.exports = "lbServices";
}

(function(window, angular, undefined) {
  'use strict';

  var urlBase = "/api";
  var authHeader = 'authorization';

  function getHost(url) {
    var m = url.match(/^(?:https?:)?\/\/([^\/]+)/);
    return m ? m[1] : null;
  }

  var urlBaseHost = getHost(urlBase) || location.host;

/**
 * @ngdoc overview
 * @name lbServices
 * @module
 * @description
 *
 * The `lbServices` module provides services for interacting with
 * the models exposed by the LoopBack server via the REST API.
 *
 */
  var module = angular.module("lbServices", ['ngResource']);

/**
 * @ngdoc object
 * @name lbServices.Setting
 * @header lbServices.Setting
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Setting` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Setting",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/settings/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Setting#create
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/settings",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#createMany
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/settings",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#upsert
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/settings",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#replaceOrCreate
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/settings/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#upsertWithWhere
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/settings/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#exists
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/settings/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#findById
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/settings/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#replaceById
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/settings/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#find
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/settings",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#findOne
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/settings/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#updateAll
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/settings/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#deleteById
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/settings/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#count
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/settings/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#prototype$updateAttributes
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/settings/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#createChangeStream
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/settings/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Setting#key
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Get Settings of a specific key pattern
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `key` – `{string}` - Search settings by key with regular expressions
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
            "key": {
              isArray: true,
              url: urlBase + "/settings/key/:key",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Setting#patchOrCreate
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Setting#updateOrCreate
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Setting#patchOrCreateWithWhere
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Setting#update
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Setting#destroyById
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Setting#removeById
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Setting#patchAttributes
             * @methodOf lbServices.Setting
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Setting` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Setting#modelName
        * @propertyOf lbServices.Setting
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Setting`.
        */
        R.modelName = "Setting";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SystemUser
 * @header lbServices.SystemUser
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SystemUser` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SystemUser",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q', '$http',
      function(LoopBackResource, LoopBackAuth, $injector, $q, $http) {
        var R = LoopBackResource(
        urlBase + "/SystemUsers/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.SystemUser#prototype$__exists__systemRoles
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Check the existence of systemRoles relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - User id
             *
             *  - `fk` – `{*}` - Foreign key for systemRoles
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
            "prototype$__exists__systemRoles": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SystemUsers/:id/systemRoles/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SystemUser.organizations.exists() instead.
            "prototype$__exists__organizations": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SystemUsers/:id/organizations/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SystemUser.projects.exists() instead.
            "prototype$__exists__projects": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SystemUsers/:id/projects/rel/:fk",
              method: "HEAD",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemUser#prototype$__get__systemRoles
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Queries systemRoles of SystemUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - User id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
            "prototype$__get__systemRoles": {
              isArray: true,
              url: urlBase + "/SystemUsers/:id/systemRoles",
              method: "GET",
            },

            // INTERNAL. Use SystemUser.organizations() instead.
            "prototype$__get__organizations": {
              isArray: true,
              url: urlBase + "/SystemUsers/:id/organizations",
              method: "GET",
            },

            // INTERNAL. Use SystemUser.projects() instead.
            "prototype$__get__projects": {
              isArray: true,
              url: urlBase + "/SystemUsers/:id/projects",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemUser#upsert
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/SystemUsers",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemUser#replaceOrCreate
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/SystemUsers/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemUser#upsertWithWhere
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/SystemUsers/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemUser#exists
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/SystemUsers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemUser#findById
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/SystemUsers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemUser#find
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/SystemUsers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemUser#deleteById
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/SystemUsers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemUser#count
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/SystemUsers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemUser#prototype$updateAttributes
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - User id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/SystemUsers/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemUser#login
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Login a user with username/email and password.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `include` – `{string=}` - Related objects to include in the response. See the description of return value for more details.
             *   Default value: `user`.
             *
             *  - `rememberMe` - `boolean` - Whether the authentication credentials
             *     should be remembered in localStorage across app/browser restarts.
             *     Default: `true`.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The response body contains properties of the AccessToken created on login.
             * Depending on the value of `include` parameter, the body may contain additional properties:
             *   - `user` - `U+007BUserU+007D` - Data of the currently logged in user. (`include=user`)
             *
             */
            "login": {
              params: {
                include: 'user',
              },
              interceptor: {
                response: function(response) {
                  var accessToken = response.data;
                  LoopBackAuth.setUser(
                    accessToken.id, accessToken.userId, accessToken.user);
                  LoopBackAuth.rememberMe =
                    response.config.params.rememberMe !== false;
                  LoopBackAuth.save();
                  
                  if(!window.AESG.canLogin(response)) {
                    $http.post(urlBase + "/SystemUsers/logout",null,{ ignoreLoadingBar: true }).then(function(res){
                      LoopBackAuth.clearUser();
                      LoopBackAuth.clearStorage();
                    });
                    var error = {
                      code: "LOGIN_FAILED",
                      message: "login failed, not enough permissions",
                      name: "Error",
                      status: 401,
                      statusCode: 401
                    }
                    return $q.reject({data:{error:error}});
                  }
                  
                  return response.resource;
                },
              },
              url: urlBase + "/SystemUsers/login",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemUser#logout
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Logout a user with access token.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `access_token` – `{string}` - Do not supply this argument, it is automatically extracted from request headers.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "logout": {
              interceptor: {
                response: function(response) {
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                  return response.resource;
                },
                responseError: function(responseError) {
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                  return responseError.resource;
                },
              },
              url: urlBase + "/SystemUsers/logout",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemUser#confirm
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Confirm a user registration with email verification token.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `uid` – `{string}` -
             *
             *  - `token` – `{string}` -
             *
             *  - `redirect` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "confirm": {
              url: urlBase + "/SystemUsers/confirm",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemUser#resetPassword
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Reset password for a user with email.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "resetPassword": {
              url: urlBase + "/SystemUsers/reset",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemUser#cleanup
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * clean related models from users that dont exist any more
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` - the number of records deleted by the cleanup
             */
            "cleanup": {
              url: urlBase + "/SystemUsers/cleanup",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemUser#currentUser
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Get the current loggedIn user based on Authorization token
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `user` – `{object=}` -
             */
            "currentUser": {
              url: urlBase + "/SystemUsers/currentUser",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemUser#emailExists
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Check if email exists
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `email` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "emailExists": {
              url: urlBase + "/SystemUsers/emailExists/:email",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemUser#inviteUser
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Find or invite a user
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `email` – `{string}` -
             *
             *  - `firstName` – `{string=}` -
             *
             *  - `lastName` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `user` – `{object=}` -
             */
            "inviteUser": {
              url: urlBase + "/SystemUsers/invite",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemUser#linkedProjects
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Get a list of projects associated with the current user
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
            "linkedProjects": {
              isArray: true,
              url: urlBase + "/SystemUsers/:id/linkedProjects",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemUser#projectRoles
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Get an aggregated list of project-associated roles for the current user
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
            "projectRoles": {
              isArray: true,
              url: urlBase + "/SystemUsers/:id/projectRoles",
              method: "GET",
            },

            // INTERNAL. Use SystemAccess.systemUser() instead.
            "::get::SystemAccess::systemUser": {
              url: urlBase + "/SystemAccesses/:id/systemUser",
              method: "GET",
            },

            // INTERNAL. Use Group.owner() instead.
            "::get::Group::owner": {
              url: urlBase + "/Groups/:id/owner",
              method: "GET",
            },

            // INTERNAL. Use Organization.systemUsers.findById() instead.
            "::findById::Organization::systemUsers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/systemUsers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Organization.systemUsers.destroyById() instead.
            "::destroyById::Organization::systemUsers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/systemUsers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Organization.systemUsers.updateById() instead.
            "::updateById::Organization::systemUsers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/systemUsers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Organization.systemUsers.link() instead.
            "::link::Organization::systemUsers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/systemUsers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Organization.systemUsers.unlink() instead.
            "::unlink::Organization::systemUsers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/systemUsers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Organization.systemUsers.exists() instead.
            "::exists::Organization::systemUsers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/systemUsers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Organization.systemUsers() instead.
            "::get::Organization::systemUsers": {
              isArray: true,
              url: urlBase + "/Organizations/:id/systemUsers",
              method: "GET",
            },

            // INTERNAL. Use Organization.systemUsers.create() instead.
            "::create::Organization::systemUsers": {
              url: urlBase + "/Organizations/:id/systemUsers",
              method: "POST",
            },

            // INTERNAL. Use Organization.systemUsers.createMany() instead.
            "::createMany::Organization::systemUsers": {
              isArray: true,
              url: urlBase + "/Organizations/:id/systemUsers",
              method: "POST",
            },

            // INTERNAL. Use Organization.systemUsers.destroyAll() instead.
            "::delete::Organization::systemUsers": {
              url: urlBase + "/Organizations/:id/systemUsers",
              method: "DELETE",
            },

            // INTERNAL. Use Organization.systemUsers.count() instead.
            "::count::Organization::systemUsers": {
              url: urlBase + "/Organizations/:id/systemUsers/count",
              method: "GET",
            },

            // INTERNAL. Use Exam.systemUser() instead.
            "::get::Exam::systemUser": {
              url: urlBase + "/Exams/:id/systemUser",
              method: "GET",
            },

            // INTERNAL. Use Lecture.systemUser() instead.
            "::get::Lecture::systemUser": {
              url: urlBase + "/Lectures/:id/systemUser",
              method: "GET",
            },

            // INTERNAL. Use LectureProposal.systemUser() instead.
            "::get::LectureProposal::systemUser": {
              url: urlBase + "/LectureProposals/:id/systemUser",
              method: "GET",
            },

            // INTERNAL. Use Syllabus.systemUser() instead.
            "::get::Syllabus::systemUser": {
              url: urlBase + "/syllabuses/:id/systemUser",
              method: "GET",
            },

            // INTERNAL. Use Assessment.systemUser() instead.
            "::get::Assessment::systemUser": {
              url: urlBase + "/Assessments/:id/systemUser",
              method: "GET",
            },

            // INTERNAL. Use Project.users.link() instead.
            "::link::Project::users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/users/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Project.users.unlink() instead.
            "::unlink::Project::users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/users/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Project.users.exists() instead.
            "::exists::Project::users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/users/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Project.users() instead.
            "::get::Project::users": {
              isArray: true,
              url: urlBase + "/Projects/:id/users",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemUser#getCurrent
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Get data of the currently logged user. Fail with HTTP result 401
             * when there is no user logged in.
             *
             * @param {function(Object,Object)=} successCb
             *    Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *    `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             */
            'getCurrent': {
              url: urlBase + "/SystemUsers" + '/:id',
              method: 'GET',
              params: {
                id: function() {
                  var id = LoopBackAuth.currentUserId;
                  if (id == null) id = '__anonymous__';
                  return id;
                },
              },
              interceptor: {
                response: function(response) {
                  LoopBackAuth.currentUserData = response.data;
                  return response.resource;
                },
                responseError: function(responseError) {
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                  return $q.reject(responseError);
                },
              },
              __isGetCurrentUser__: true,
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.SystemUser#patchOrCreate
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.SystemUser#updateOrCreate
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.SystemUser#patchOrCreateWithWhere
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.SystemUser#destroyById
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SystemUser#removeById
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SystemUser#patchAttributes
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - User id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];

        /**
         * @ngdoc method
         * @name lbServices.SystemUser#getCachedCurrent
         * @methodOf lbServices.SystemUser
         *
         * @description
         *
         * Get data of the currently logged user that was returned by the last
         * call to {@link lbServices.SystemUser#login} or
         * {@link lbServices.SystemUser#getCurrent}. Return null when there
         * is no user logged in or the data of the current user were not fetched
         * yet.
         *
         * @returns {Object} A SystemUser instance.
         */
        R.getCachedCurrent = function() {
          var data = LoopBackAuth.currentUserData;
          return data ? new R(data) : null;
        };

        /**
         * @ngdoc method
         * @name lbServices.SystemUser#isAuthenticated
         * @methodOf lbServices.SystemUser
         *
         * @returns {boolean} True if the current user is authenticated (logged in).
         */
        R.isAuthenticated = function() {
          return this.getCurrentId() != null;
        };

        /**
         * @ngdoc method
         * @name lbServices.SystemUser#getCurrentId
         * @methodOf lbServices.SystemUser
         *
         * @returns {Object} Id of the currently logged-in user or null.
         */
        R.getCurrentId = function() {
          return LoopBackAuth.currentUserId;
        };

        /**
        * @ngdoc property
        * @name lbServices.SystemUser#modelName
        * @propertyOf lbServices.SystemUser
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SystemUser`.
        */
        R.modelName = "SystemUser";

    /**
     * @ngdoc object
     * @name lbServices.SystemUser.organizations
     * @header lbServices.SystemUser.organizations
     * @object
     * @description
     *
     * The object `SystemUser.organizations` groups methods
     * manipulating `Project` instances related to `SystemUser`.
     *
     * Call {@link lbServices.SystemUser#organizations SystemUser.organizations()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SystemUser#organizations
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Queries organizations of SystemUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - User id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
        R.organizations = function() {
          var TargetResource = $injector.get("Project");
          var action = TargetResource["::get::SystemUser::organizations"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SystemUser.organizations#exists
             * @methodOf lbServices.SystemUser.organizations
             *
             * @description
             *
             * Check the existence of organizations relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - User id
             *
             *  - `fk` – `{*}` - Foreign key for organizations
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
        R.organizations.exists = function() {
          var TargetResource = $injector.get("Project");
          var action = TargetResource["::exists::SystemUser::organizations"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.SystemUser.projects
     * @header lbServices.SystemUser.projects
     * @object
     * @description
     *
     * The object `SystemUser.projects` groups methods
     * manipulating `Project` instances related to `SystemUser`.
     *
     * Call {@link lbServices.SystemUser#projects SystemUser.projects()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.SystemUser#projects
             * @methodOf lbServices.SystemUser
             *
             * @description
             *
             * Queries projects of SystemUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - User id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
        R.projects = function() {
          var TargetResource = $injector.get("Project");
          var action = TargetResource["::get::SystemUser::projects"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.SystemUser.projects#exists
             * @methodOf lbServices.SystemUser.projects
             *
             * @description
             *
             * Check the existence of projects relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - User id
             *
             *  - `fk` – `{*}` - Foreign key for projects
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
        R.projects.exists = function() {
          var TargetResource = $injector.get("Project");
          var action = TargetResource["::exists::SystemUser::projects"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SystemAccess
 * @header lbServices.SystemAccess
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SystemAccess` model.
 *
 * **Details**
 *
 * Map principals to roles
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SystemAccess",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/SystemAccesses/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.SystemAccess#prototype$__get__role
             * @methodOf lbServices.SystemAccess
             *
             * @description
             *
             * Fetches belongsTo relation role.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - RoleMapping id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAccess` object.)
             * </em>
             */
            "prototype$__get__role": {
              url: urlBase + "/SystemAccesses/:id/role",
              method: "GET",
            },

            // INTERNAL. Use SystemAccess.systemUser() instead.
            "prototype$__get__systemUser": {
              url: urlBase + "/SystemAccesses/:id/systemUser",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemAccess#create
             * @methodOf lbServices.SystemAccess
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAccess` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/SystemAccesses",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemAccess#createMany
             * @methodOf lbServices.SystemAccess
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAccess` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/SystemAccesses",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemAccess#upsert
             * @methodOf lbServices.SystemAccess
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAccess` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/SystemAccesses",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemAccess#replaceOrCreate
             * @methodOf lbServices.SystemAccess
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAccess` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/SystemAccesses/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemAccess#upsertWithWhere
             * @methodOf lbServices.SystemAccess
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAccess` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/SystemAccesses/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemAccess#exists
             * @methodOf lbServices.SystemAccess
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/SystemAccesses/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemAccess#findById
             * @methodOf lbServices.SystemAccess
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAccess` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/SystemAccesses/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemAccess#replaceById
             * @methodOf lbServices.SystemAccess
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAccess` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/SystemAccesses/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemAccess#find
             * @methodOf lbServices.SystemAccess
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAccess` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/SystemAccesses",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemAccess#findOne
             * @methodOf lbServices.SystemAccess
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAccess` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/SystemAccesses/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemAccess#updateAll
             * @methodOf lbServices.SystemAccess
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/SystemAccesses/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemAccess#deleteById
             * @methodOf lbServices.SystemAccess
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAccess` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/SystemAccesses/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemAccess#count
             * @methodOf lbServices.SystemAccess
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/SystemAccesses/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemAccess#prototype$updateAttributes
             * @methodOf lbServices.SystemAccess
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - RoleMapping id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAccess` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/SystemAccesses/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SystemAccess#createChangeStream
             * @methodOf lbServices.SystemAccess
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/SystemAccesses/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.SystemAccess#patchOrCreate
             * @methodOf lbServices.SystemAccess
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAccess` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.SystemAccess#updateOrCreate
             * @methodOf lbServices.SystemAccess
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAccess` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.SystemAccess#patchOrCreateWithWhere
             * @methodOf lbServices.SystemAccess
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAccess` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.SystemAccess#update
             * @methodOf lbServices.SystemAccess
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.SystemAccess#destroyById
             * @methodOf lbServices.SystemAccess
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAccess` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SystemAccess#removeById
             * @methodOf lbServices.SystemAccess
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAccess` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SystemAccess#patchAttributes
             * @methodOf lbServices.SystemAccess
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - RoleMapping id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemAccess` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.SystemAccess#modelName
        * @propertyOf lbServices.SystemAccess
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SystemAccess`.
        */
        R.modelName = "SystemAccess";


            /**
             * @ngdoc method
             * @name lbServices.SystemAccess#systemUser
             * @methodOf lbServices.SystemAccess
             *
             * @description
             *
             * Fetches belongsTo relation systemUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - RoleMapping id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
        R.systemUser = function() {
          var TargetResource = $injector.get("SystemUser");
          var action = TargetResource["::get::SystemAccess::systemUser"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Group
 * @header lbServices.Group
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Group` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Group",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Groups/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Group.owner() instead.
            "prototype$__get__owner": {
              url: urlBase + "/Groups/:id/owner",
              method: "GET",
            },

            // INTERNAL. Use Group.organization() instead.
            "prototype$__get__organization": {
              url: urlBase + "/Groups/:id/organization",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#create
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Groups",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#createMany
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Groups",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#upsert
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Groups",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#replaceOrCreate
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Groups/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#upsertWithWhere
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Groups/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#exists
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Groups/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#findById
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Groups/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#replaceById
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Groups/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#find
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Groups",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#findOne
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Groups/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#updateAll
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Groups/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#deleteById
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Groups/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#count
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Groups/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#prototype$updateAttributes
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Groups/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Group#createChangeStream
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Groups/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Organization.groups.findById() instead.
            "::findById::Organization::groups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/groups/:fk",
              method: "GET",
            },

            // INTERNAL. Use Organization.groups.destroyById() instead.
            "::destroyById::Organization::groups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/groups/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Organization.groups.updateById() instead.
            "::updateById::Organization::groups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/groups/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Organization.groups() instead.
            "::get::Organization::groups": {
              isArray: true,
              url: urlBase + "/Organizations/:id/groups",
              method: "GET",
            },

            // INTERNAL. Use Organization.groups.create() instead.
            "::create::Organization::groups": {
              url: urlBase + "/Organizations/:id/groups",
              method: "POST",
            },

            // INTERNAL. Use Organization.groups.createMany() instead.
            "::createMany::Organization::groups": {
              isArray: true,
              url: urlBase + "/Organizations/:id/groups",
              method: "POST",
            },

            // INTERNAL. Use Organization.groups.destroyAll() instead.
            "::delete::Organization::groups": {
              url: urlBase + "/Organizations/:id/groups",
              method: "DELETE",
            },

            // INTERNAL. Use Organization.groups.count() instead.
            "::count::Organization::groups": {
              url: urlBase + "/Organizations/:id/groups/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Group#patchOrCreate
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Group#updateOrCreate
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Group#patchOrCreateWithWhere
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Group#update
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Group#destroyById
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Group#removeById
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Group#patchAttributes
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Group#modelName
        * @propertyOf lbServices.Group
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Group`.
        */
        R.modelName = "Group";


            /**
             * @ngdoc method
             * @name lbServices.Group#owner
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Fetches belongsTo relation owner.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
        R.owner = function() {
          var TargetResource = $injector.get("SystemUser");
          var action = TargetResource["::get::Group::owner"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Group#organization
             * @methodOf lbServices.Group
             *
             * @description
             *
             * Fetches belongsTo relation organization.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Organization` object.)
             * </em>
             */
        R.organization = function() {
          var TargetResource = $injector.get("Organization");
          var action = TargetResource["::get::Group::organization"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Organization
 * @header lbServices.Organization
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Organization` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Organization",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Organizations/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Organization.groups.findById() instead.
            "prototype$__findById__groups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/groups/:fk",
              method: "GET",
            },

            // INTERNAL. Use Organization.groups.destroyById() instead.
            "prototype$__destroyById__groups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/groups/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Organization.groups.updateById() instead.
            "prototype$__updateById__groups": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/groups/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Organization.systemUsers.findById() instead.
            "prototype$__findById__systemUsers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/systemUsers/:fk",
              method: "GET",
            },

            // INTERNAL. Use Organization.systemUsers.destroyById() instead.
            "prototype$__destroyById__systemUsers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/systemUsers/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Organization.systemUsers.updateById() instead.
            "prototype$__updateById__systemUsers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/systemUsers/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Organization.systemUsers.link() instead.
            "prototype$__link__systemUsers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/systemUsers/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Organization.systemUsers.unlink() instead.
            "prototype$__unlink__systemUsers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/systemUsers/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Organization.systemUsers.exists() instead.
            "prototype$__exists__systemUsers": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/systemUsers/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Organization.projects.findById() instead.
            "prototype$__findById__projects": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/projects/:fk",
              method: "GET",
            },

            // INTERNAL. Use Organization.projects.destroyById() instead.
            "prototype$__destroyById__projects": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/projects/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Organization.projects.updateById() instead.
            "prototype$__updateById__projects": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/projects/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Organization.files.findById() instead.
            "prototype$__findById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/files/:fk",
              method: "GET",
            },

            // INTERNAL. Use Organization.files.destroyById() instead.
            "prototype$__destroyById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/files/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Organization.files.updateById() instead.
            "prototype$__updateById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/files/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Organization.groups() instead.
            "prototype$__get__groups": {
              isArray: true,
              url: urlBase + "/Organizations/:id/groups",
              method: "GET",
            },

            // INTERNAL. Use Organization.groups.create() instead.
            "prototype$__create__groups": {
              url: urlBase + "/Organizations/:id/groups",
              method: "POST",
            },

            // INTERNAL. Use Organization.groups.destroyAll() instead.
            "prototype$__delete__groups": {
              url: urlBase + "/Organizations/:id/groups",
              method: "DELETE",
            },

            // INTERNAL. Use Organization.groups.count() instead.
            "prototype$__count__groups": {
              url: urlBase + "/Organizations/:id/groups/count",
              method: "GET",
            },

            // INTERNAL. Use Organization.systemUsers() instead.
            "prototype$__get__systemUsers": {
              isArray: true,
              url: urlBase + "/Organizations/:id/systemUsers",
              method: "GET",
            },

            // INTERNAL. Use Organization.systemUsers.create() instead.
            "prototype$__create__systemUsers": {
              url: urlBase + "/Organizations/:id/systemUsers",
              method: "POST",
            },

            // INTERNAL. Use Organization.systemUsers.destroyAll() instead.
            "prototype$__delete__systemUsers": {
              url: urlBase + "/Organizations/:id/systemUsers",
              method: "DELETE",
            },

            // INTERNAL. Use Organization.systemUsers.count() instead.
            "prototype$__count__systemUsers": {
              url: urlBase + "/Organizations/:id/systemUsers/count",
              method: "GET",
            },

            // INTERNAL. Use Organization.projects() instead.
            "prototype$__get__projects": {
              isArray: true,
              url: urlBase + "/Organizations/:id/projects",
              method: "GET",
            },

            // INTERNAL. Use Organization.projects.create() instead.
            "prototype$__create__projects": {
              url: urlBase + "/Organizations/:id/projects",
              method: "POST",
            },

            // INTERNAL. Use Organization.projects.destroyAll() instead.
            "prototype$__delete__projects": {
              url: urlBase + "/Organizations/:id/projects",
              method: "DELETE",
            },

            // INTERNAL. Use Organization.projects.count() instead.
            "prototype$__count__projects": {
              url: urlBase + "/Organizations/:id/projects/count",
              method: "GET",
            },

            // INTERNAL. Use Organization.files() instead.
            "prototype$__get__files": {
              isArray: true,
              url: urlBase + "/Organizations/:id/files",
              method: "GET",
            },

            // INTERNAL. Use Organization.files.create() instead.
            "prototype$__create__files": {
              url: urlBase + "/Organizations/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Organization.files.destroyAll() instead.
            "prototype$__delete__files": {
              url: urlBase + "/Organizations/:id/files",
              method: "DELETE",
            },

            // INTERNAL. Use Organization.files.count() instead.
            "prototype$__count__files": {
              url: urlBase + "/Organizations/:id/files/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Organization#create
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Organization` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Organizations",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Organization#createMany
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Organization` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Organizations",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Organization#upsert
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Organization` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Organizations",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Organization#replaceOrCreate
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Organization` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Organizations/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Organization#upsertWithWhere
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Organization` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Organizations/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Organization#exists
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Organizations/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Organization#findById
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Organization` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Organizations/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Organization#replaceById
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Organization` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Organizations/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Organization#find
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Organization` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Organizations",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Organization#findOne
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Organization` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Organizations/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Organization#updateAll
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Organizations/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Organization#deleteById
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Organization` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Organizations/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Organization#count
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Organizations/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Organization#prototype$updateAttributes
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Organization` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Organizations/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Organization#createChangeStream
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Organizations/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Organization#getFiles
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Get files of a specific type : e.g Organizations/A/creo-lectures/files
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `componentModel` – `{string}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Organization` object.)
             * </em>
             */
            "getFiles": {
              isArray: true,
              url: urlBase + "/Organizations/:id/:componentModel/files",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Organization#relatedUsers
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Get all the related users of an organization
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Array of SystemUser instances
             */
            "relatedUsers": {
              isArray: true,
              url: urlBase + "/Organizations/:id/relatedUsers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Organization#uploadFile
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Uploads a file for this organization : e.g organizationAId/assessment/uploadFile
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `id` – `{string}` -
             *
             *  - `componentModel` – `{string}` -
             *
             *  - `meta` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Organization` object.)
             * </em>
             */
            "uploadFile": {
              url: urlBase + "/Organizations/:id/:componentModel/uploadFile",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Organization#updateFile
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Updates a file for this organization : e.g OrganizationsorganizationAId/assessment/updateFile/:FileId1
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `id` – `{string}` -
             *
             *  - `componentModel` – `{string}` -
             *
             *  - `fileId` – `{string}` -
             *
             *  - `meta` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Organization` object.)
             * </em>
             */
            "updateFile": {
              url: urlBase + "/Organizations/:id/:componentModel/updateFile/:fileId",
              method: "POST",
            },

            // INTERNAL. Use Group.organization() instead.
            "::get::Group::organization": {
              url: urlBase + "/Groups/:id/organization",
              method: "GET",
            },

            // INTERNAL. Use Project.organization() instead.
            "::get::Project::organization": {
              url: urlBase + "/Projects/:id/organization",
              method: "GET",
            },

            // INTERNAL. Use File.organization() instead.
            "::get::File::organization": {
              url: urlBase + "/Files/:id/organization",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Organization#patchOrCreate
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Organization` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Organization#updateOrCreate
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Organization` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Organization#patchOrCreateWithWhere
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Organization` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Organization#update
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Organization#destroyById
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Organization` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Organization#removeById
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Organization` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Organization#patchAttributes
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Organization` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Organization#modelName
        * @propertyOf lbServices.Organization
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Organization`.
        */
        R.modelName = "Organization";

    /**
     * @ngdoc object
     * @name lbServices.Organization.groups
     * @header lbServices.Organization.groups
     * @object
     * @description
     *
     * The object `Organization.groups` groups methods
     * manipulating `Group` instances related to `Organization`.
     *
     * Call {@link lbServices.Organization#groups Organization.groups()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Organization#groups
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Queries groups of Organization.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
        R.groups = function() {
          var TargetResource = $injector.get("Group");
          var action = TargetResource["::get::Organization::groups"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.groups#count
             * @methodOf lbServices.Organization.groups
             *
             * @description
             *
             * Counts groups of Organization.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.groups.count = function() {
          var TargetResource = $injector.get("Group");
          var action = TargetResource["::count::Organization::groups"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.groups#create
             * @methodOf lbServices.Organization.groups
             *
             * @description
             *
             * Creates a new instance in groups of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
        R.groups.create = function() {
          var TargetResource = $injector.get("Group");
          var action = TargetResource["::create::Organization::groups"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.groups#createMany
             * @methodOf lbServices.Organization.groups
             *
             * @description
             *
             * Creates a new instance in groups of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
        R.groups.createMany = function() {
          var TargetResource = $injector.get("Group");
          var action = TargetResource["::createMany::Organization::groups"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.groups#destroyAll
             * @methodOf lbServices.Organization.groups
             *
             * @description
             *
             * Deletes all groups of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.groups.destroyAll = function() {
          var TargetResource = $injector.get("Group");
          var action = TargetResource["::delete::Organization::groups"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.groups#destroyById
             * @methodOf lbServices.Organization.groups
             *
             * @description
             *
             * Delete a related item by id for groups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for groups
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.groups.destroyById = function() {
          var TargetResource = $injector.get("Group");
          var action = TargetResource["::destroyById::Organization::groups"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.groups#findById
             * @methodOf lbServices.Organization.groups
             *
             * @description
             *
             * Find a related item by id for groups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for groups
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
        R.groups.findById = function() {
          var TargetResource = $injector.get("Group");
          var action = TargetResource["::findById::Organization::groups"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.groups#updateById
             * @methodOf lbServices.Organization.groups
             *
             * @description
             *
             * Update a related item by id for groups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for groups
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Group` object.)
             * </em>
             */
        R.groups.updateById = function() {
          var TargetResource = $injector.get("Group");
          var action = TargetResource["::updateById::Organization::groups"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Organization.systemUsers
     * @header lbServices.Organization.systemUsers
     * @object
     * @description
     *
     * The object `Organization.systemUsers` groups methods
     * manipulating `SystemUser` instances related to `Organization`.
     *
     * Call {@link lbServices.Organization#systemUsers Organization.systemUsers()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Organization#systemUsers
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Queries systemUsers of Organization.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
        R.systemUsers = function() {
          var TargetResource = $injector.get("SystemUser");
          var action = TargetResource["::get::Organization::systemUsers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.systemUsers#count
             * @methodOf lbServices.Organization.systemUsers
             *
             * @description
             *
             * Counts systemUsers of Organization.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.systemUsers.count = function() {
          var TargetResource = $injector.get("SystemUser");
          var action = TargetResource["::count::Organization::systemUsers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.systemUsers#create
             * @methodOf lbServices.Organization.systemUsers
             *
             * @description
             *
             * Creates a new instance in systemUsers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
        R.systemUsers.create = function() {
          var TargetResource = $injector.get("SystemUser");
          var action = TargetResource["::create::Organization::systemUsers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.systemUsers#createMany
             * @methodOf lbServices.Organization.systemUsers
             *
             * @description
             *
             * Creates a new instance in systemUsers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
        R.systemUsers.createMany = function() {
          var TargetResource = $injector.get("SystemUser");
          var action = TargetResource["::createMany::Organization::systemUsers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.systemUsers#destroyAll
             * @methodOf lbServices.Organization.systemUsers
             *
             * @description
             *
             * Deletes all systemUsers of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.systemUsers.destroyAll = function() {
          var TargetResource = $injector.get("SystemUser");
          var action = TargetResource["::delete::Organization::systemUsers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.systemUsers#destroyById
             * @methodOf lbServices.Organization.systemUsers
             *
             * @description
             *
             * Delete a related item by id for systemUsers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for systemUsers
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.systemUsers.destroyById = function() {
          var TargetResource = $injector.get("SystemUser");
          var action = TargetResource["::destroyById::Organization::systemUsers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.systemUsers#exists
             * @methodOf lbServices.Organization.systemUsers
             *
             * @description
             *
             * Check the existence of systemUsers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for systemUsers
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
        R.systemUsers.exists = function() {
          var TargetResource = $injector.get("SystemUser");
          var action = TargetResource["::exists::Organization::systemUsers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.systemUsers#findById
             * @methodOf lbServices.Organization.systemUsers
             *
             * @description
             *
             * Find a related item by id for systemUsers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for systemUsers
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
        R.systemUsers.findById = function() {
          var TargetResource = $injector.get("SystemUser");
          var action = TargetResource["::findById::Organization::systemUsers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.systemUsers#link
             * @methodOf lbServices.Organization.systemUsers
             *
             * @description
             *
             * Add a related item by id for systemUsers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for systemUsers
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
        R.systemUsers.link = function() {
          var TargetResource = $injector.get("SystemUser");
          var action = TargetResource["::link::Organization::systemUsers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.systemUsers#unlink
             * @methodOf lbServices.Organization.systemUsers
             *
             * @description
             *
             * Remove the systemUsers relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for systemUsers
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.systemUsers.unlink = function() {
          var TargetResource = $injector.get("SystemUser");
          var action = TargetResource["::unlink::Organization::systemUsers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.systemUsers#updateById
             * @methodOf lbServices.Organization.systemUsers
             *
             * @description
             *
             * Update a related item by id for systemUsers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for systemUsers
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
        R.systemUsers.updateById = function() {
          var TargetResource = $injector.get("SystemUser");
          var action = TargetResource["::updateById::Organization::systemUsers"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Organization.projects
     * @header lbServices.Organization.projects
     * @object
     * @description
     *
     * The object `Organization.projects` groups methods
     * manipulating `Project` instances related to `Organization`.
     *
     * Call {@link lbServices.Organization#projects Organization.projects()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Organization#projects
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Queries projects of Organization.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
        R.projects = function() {
          var TargetResource = $injector.get("Project");
          var action = TargetResource["::get::Organization::projects"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.projects#count
             * @methodOf lbServices.Organization.projects
             *
             * @description
             *
             * Counts projects of Organization.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.projects.count = function() {
          var TargetResource = $injector.get("Project");
          var action = TargetResource["::count::Organization::projects"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.projects#create
             * @methodOf lbServices.Organization.projects
             *
             * @description
             *
             * Creates a new instance in projects of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
        R.projects.create = function() {
          var TargetResource = $injector.get("Project");
          var action = TargetResource["::create::Organization::projects"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.projects#createMany
             * @methodOf lbServices.Organization.projects
             *
             * @description
             *
             * Creates a new instance in projects of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
        R.projects.createMany = function() {
          var TargetResource = $injector.get("Project");
          var action = TargetResource["::createMany::Organization::projects"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.projects#destroyAll
             * @methodOf lbServices.Organization.projects
             *
             * @description
             *
             * Deletes all projects of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.projects.destroyAll = function() {
          var TargetResource = $injector.get("Project");
          var action = TargetResource["::delete::Organization::projects"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.projects#destroyById
             * @methodOf lbServices.Organization.projects
             *
             * @description
             *
             * Delete a related item by id for projects.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for projects
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.projects.destroyById = function() {
          var TargetResource = $injector.get("Project");
          var action = TargetResource["::destroyById::Organization::projects"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.projects#findById
             * @methodOf lbServices.Organization.projects
             *
             * @description
             *
             * Find a related item by id for projects.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for projects
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
        R.projects.findById = function() {
          var TargetResource = $injector.get("Project");
          var action = TargetResource["::findById::Organization::projects"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.projects#updateById
             * @methodOf lbServices.Organization.projects
             *
             * @description
             *
             * Update a related item by id for projects.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for projects
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
        R.projects.updateById = function() {
          var TargetResource = $injector.get("Project");
          var action = TargetResource["::updateById::Organization::projects"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Organization.files
     * @header lbServices.Organization.files
     * @object
     * @description
     *
     * The object `Organization.files` groups methods
     * manipulating `File` instances related to `Organization`.
     *
     * Call {@link lbServices.Organization#files Organization.files()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Organization#files
             * @methodOf lbServices.Organization
             *
             * @description
             *
             * Queries files of Organization.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.files = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::get::Organization::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.files#count
             * @methodOf lbServices.Organization.files
             *
             * @description
             *
             * Counts files of Organization.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.files.count = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::count::Organization::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.files#create
             * @methodOf lbServices.Organization.files
             *
             * @description
             *
             * Creates a new instance in files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.files.create = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::create::Organization::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.files#createMany
             * @methodOf lbServices.Organization.files
             *
             * @description
             *
             * Creates a new instance in files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.files.createMany = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::createMany::Organization::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.files#destroyAll
             * @methodOf lbServices.Organization.files
             *
             * @description
             *
             * Deletes all files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.destroyAll = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::delete::Organization::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.files#destroyById
             * @methodOf lbServices.Organization.files
             *
             * @description
             *
             * Delete a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.destroyById = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::destroyById::Organization::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.files#findById
             * @methodOf lbServices.Organization.files
             *
             * @description
             *
             * Find a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.files.findById = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::findById::Organization::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Organization.files#updateById
             * @methodOf lbServices.Organization.files
             *
             * @description
             *
             * Update a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.files.updateById = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::updateById::Organization::files"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Exam
 * @header lbServices.Exam
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Exam` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Exam",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Exams/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Exam.systemUser() instead.
            "prototype$__get__systemUser": {
              url: urlBase + "/Exams/:id/systemUser",
              method: "GET",
            },

            // INTERNAL. Use Exam.project() instead.
            "prototype$__get__project": {
              url: urlBase + "/Exams/:id/project",
              method: "GET",
            },

            // INTERNAL. Use Exam.file() instead.
            "prototype$__get__file": {
              url: urlBase + "/Exams/:id/file",
              method: "GET",
            },

            // INTERNAL. Use Exam.file.create() instead.
            "prototype$__create__file": {
              url: urlBase + "/Exams/:id/file",
              method: "POST",
            },

            // INTERNAL. Use Exam.file.update() instead.
            "prototype$__update__file": {
              url: urlBase + "/Exams/:id/file",
              method: "PUT",
            },

            // INTERNAL. Use Exam.file.destroy() instead.
            "prototype$__destroy__file": {
              url: urlBase + "/Exams/:id/file",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Exam#create
             * @methodOf lbServices.Exam
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Exam` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Exams",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Exam#createMany
             * @methodOf lbServices.Exam
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Exam` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Exams",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Exam#upsert
             * @methodOf lbServices.Exam
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Exam` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Exams",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Exam#replaceOrCreate
             * @methodOf lbServices.Exam
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Exam` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Exams/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Exam#upsertWithWhere
             * @methodOf lbServices.Exam
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Exam` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Exams/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Exam#exists
             * @methodOf lbServices.Exam
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Exams/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Exam#findById
             * @methodOf lbServices.Exam
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Exam` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Exams/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Exam#replaceById
             * @methodOf lbServices.Exam
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Exam` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Exams/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Exam#find
             * @methodOf lbServices.Exam
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Exam` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Exams",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Exam#findOne
             * @methodOf lbServices.Exam
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Exam` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Exams/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Exam#updateAll
             * @methodOf lbServices.Exam
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Exams/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Exam#deleteById
             * @methodOf lbServices.Exam
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Exam` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Exams/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Exam#count
             * @methodOf lbServices.Exam
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Exams/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Exam#prototype$updateAttributes
             * @methodOf lbServices.Exam
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Exam` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Exams/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Exam#createChangeStream
             * @methodOf lbServices.Exam
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Exams/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Project.exams.findById() instead.
            "::findById::Project::exams": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/exams/:fk",
              method: "GET",
            },

            // INTERNAL. Use Project.exams.destroyById() instead.
            "::destroyById::Project::exams": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/exams/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Project.exams.updateById() instead.
            "::updateById::Project::exams": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/exams/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Project.exams() instead.
            "::get::Project::exams": {
              isArray: true,
              url: urlBase + "/Projects/:id/exams",
              method: "GET",
            },

            // INTERNAL. Use Project.exams.create() instead.
            "::create::Project::exams": {
              url: urlBase + "/Projects/:id/exams",
              method: "POST",
            },

            // INTERNAL. Use Project.exams.createMany() instead.
            "::createMany::Project::exams": {
              isArray: true,
              url: urlBase + "/Projects/:id/exams",
              method: "POST",
            },

            // INTERNAL. Use Project.exams.destroyAll() instead.
            "::delete::Project::exams": {
              url: urlBase + "/Projects/:id/exams",
              method: "DELETE",
            },

            // INTERNAL. Use Project.exams.count() instead.
            "::count::Project::exams": {
              url: urlBase + "/Projects/:id/exams/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Exam#patchOrCreate
             * @methodOf lbServices.Exam
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Exam` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Exam#updateOrCreate
             * @methodOf lbServices.Exam
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Exam` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Exam#patchOrCreateWithWhere
             * @methodOf lbServices.Exam
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Exam` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Exam#update
             * @methodOf lbServices.Exam
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Exam#destroyById
             * @methodOf lbServices.Exam
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Exam` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Exam#removeById
             * @methodOf lbServices.Exam
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Exam` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Exam#patchAttributes
             * @methodOf lbServices.Exam
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Exam` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Exam#modelName
        * @propertyOf lbServices.Exam
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Exam`.
        */
        R.modelName = "Exam";


            /**
             * @ngdoc method
             * @name lbServices.Exam#systemUser
             * @methodOf lbServices.Exam
             *
             * @description
             *
             * Fetches belongsTo relation systemUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
        R.systemUser = function() {
          var TargetResource = $injector.get("SystemUser");
          var action = TargetResource["::get::Exam::systemUser"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Exam#project
             * @methodOf lbServices.Exam
             *
             * @description
             *
             * Fetches belongsTo relation project.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
        R.project = function() {
          var TargetResource = $injector.get("Project");
          var action = TargetResource["::get::Exam::project"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Exam.file
     * @header lbServices.Exam.file
     * @object
     * @description
     *
     * The object `Exam.file` groups methods
     * manipulating `File` instances related to `Exam`.
     *
     * Call {@link lbServices.Exam#file Exam.file()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Exam#file
             * @methodOf lbServices.Exam
             *
             * @description
             *
             * Fetches hasOne relation file.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.file = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::get::Exam::file"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Exam.file#create
             * @methodOf lbServices.Exam.file
             *
             * @description
             *
             * Creates a new instance in file of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.file.create = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::create::Exam::file"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Exam.file#createMany
             * @methodOf lbServices.Exam.file
             *
             * @description
             *
             * Creates a new instance in file of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.file.createMany = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::createMany::Exam::file"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Exam.file#destroy
             * @methodOf lbServices.Exam.file
             *
             * @description
             *
             * Deletes file of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.file.destroy = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::destroy::Exam::file"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Exam.file#update
             * @methodOf lbServices.Exam.file
             *
             * @description
             *
             * Update file of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.file.update = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::update::Exam::file"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Lecture
 * @header lbServices.Lecture
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Lecture` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Lecture",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Lectures/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Lecture.systemUser() instead.
            "prototype$__get__systemUser": {
              url: urlBase + "/Lectures/:id/systemUser",
              method: "GET",
            },

            // INTERNAL. Use Lecture.proposals.findById() instead.
            "prototype$__findById__proposals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Lectures/:id/proposals/:fk",
              method: "GET",
            },

            // INTERNAL. Use Lecture.proposals.destroyById() instead.
            "prototype$__destroyById__proposals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Lectures/:id/proposals/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Lecture.proposals.updateById() instead.
            "prototype$__updateById__proposals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Lectures/:id/proposals/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Lecture.project() instead.
            "prototype$__get__project": {
              url: urlBase + "/Lectures/:id/project",
              method: "GET",
            },

            // INTERNAL. Use Lecture.file() instead.
            "prototype$__get__file": {
              url: urlBase + "/Lectures/:id/file",
              method: "GET",
            },

            // INTERNAL. Use Lecture.file.create() instead.
            "prototype$__create__file": {
              url: urlBase + "/Lectures/:id/file",
              method: "POST",
            },

            // INTERNAL. Use Lecture.file.update() instead.
            "prototype$__update__file": {
              url: urlBase + "/Lectures/:id/file",
              method: "PUT",
            },

            // INTERNAL. Use Lecture.file.destroy() instead.
            "prototype$__destroy__file": {
              url: urlBase + "/Lectures/:id/file",
              method: "DELETE",
            },

            // INTERNAL. Use Lecture.proposals() instead.
            "prototype$__get__proposals": {
              isArray: true,
              url: urlBase + "/Lectures/:id/proposals",
              method: "GET",
            },

            // INTERNAL. Use Lecture.proposals.create() instead.
            "prototype$__create__proposals": {
              url: urlBase + "/Lectures/:id/proposals",
              method: "POST",
            },

            // INTERNAL. Use Lecture.proposals.destroyAll() instead.
            "prototype$__delete__proposals": {
              url: urlBase + "/Lectures/:id/proposals",
              method: "DELETE",
            },

            // INTERNAL. Use Lecture.proposals.count() instead.
            "prototype$__count__proposals": {
              url: urlBase + "/Lectures/:id/proposals/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lecture#create
             * @methodOf lbServices.Lecture
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lecture` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Lectures",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lecture#createMany
             * @methodOf lbServices.Lecture
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lecture` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Lectures",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lecture#upsert
             * @methodOf lbServices.Lecture
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lecture` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Lectures",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lecture#replaceOrCreate
             * @methodOf lbServices.Lecture
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lecture` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Lectures/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lecture#upsertWithWhere
             * @methodOf lbServices.Lecture
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lecture` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Lectures/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lecture#exists
             * @methodOf lbServices.Lecture
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Lectures/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lecture#findById
             * @methodOf lbServices.Lecture
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lecture` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Lectures/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lecture#replaceById
             * @methodOf lbServices.Lecture
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lecture` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Lectures/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lecture#find
             * @methodOf lbServices.Lecture
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lecture` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Lectures",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lecture#findOne
             * @methodOf lbServices.Lecture
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lecture` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Lectures/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lecture#updateAll
             * @methodOf lbServices.Lecture
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Lectures/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lecture#deleteById
             * @methodOf lbServices.Lecture
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lecture` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Lectures/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lecture#count
             * @methodOf lbServices.Lecture
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Lectures/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lecture#prototype$updateAttributes
             * @methodOf lbServices.Lecture
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lecture` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Lectures/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lecture#createChangeStream
             * @methodOf lbServices.Lecture
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Lectures/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Lecture#uploadProposal
             * @methodOf lbServices.Lecture
             *
             * @description
             *
             * Uploads a proposal for a lecture
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `id` – `{string}` -
             *
             *  - `meta` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lecture` object.)
             * </em>
             */
            "uploadProposal": {
              url: urlBase + "/Lectures/:id/uploadProposal",
              method: "POST",
            },

            // INTERNAL. Use LectureProposal.lecture() instead.
            "::get::LectureProposal::lecture": {
              url: urlBase + "/LectureProposals/:id/lecture",
              method: "GET",
            },

            // INTERNAL. Use Project.lectures.findById() instead.
            "::findById::Project::lectures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/lectures/:fk",
              method: "GET",
            },

            // INTERNAL. Use Project.lectures.destroyById() instead.
            "::destroyById::Project::lectures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/lectures/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Project.lectures.updateById() instead.
            "::updateById::Project::lectures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/lectures/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Project.lectures() instead.
            "::get::Project::lectures": {
              isArray: true,
              url: urlBase + "/Projects/:id/lectures",
              method: "GET",
            },

            // INTERNAL. Use Project.lectures.create() instead.
            "::create::Project::lectures": {
              url: urlBase + "/Projects/:id/lectures",
              method: "POST",
            },

            // INTERNAL. Use Project.lectures.createMany() instead.
            "::createMany::Project::lectures": {
              isArray: true,
              url: urlBase + "/Projects/:id/lectures",
              method: "POST",
            },

            // INTERNAL. Use Project.lectures.destroyAll() instead.
            "::delete::Project::lectures": {
              url: urlBase + "/Projects/:id/lectures",
              method: "DELETE",
            },

            // INTERNAL. Use Project.lectures.count() instead.
            "::count::Project::lectures": {
              url: urlBase + "/Projects/:id/lectures/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Lecture#patchOrCreate
             * @methodOf lbServices.Lecture
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lecture` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Lecture#updateOrCreate
             * @methodOf lbServices.Lecture
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lecture` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Lecture#patchOrCreateWithWhere
             * @methodOf lbServices.Lecture
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lecture` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Lecture#update
             * @methodOf lbServices.Lecture
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Lecture#destroyById
             * @methodOf lbServices.Lecture
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lecture` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Lecture#removeById
             * @methodOf lbServices.Lecture
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lecture` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Lecture#patchAttributes
             * @methodOf lbServices.Lecture
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lecture` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Lecture#modelName
        * @propertyOf lbServices.Lecture
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Lecture`.
        */
        R.modelName = "Lecture";


            /**
             * @ngdoc method
             * @name lbServices.Lecture#systemUser
             * @methodOf lbServices.Lecture
             *
             * @description
             *
             * Fetches belongsTo relation systemUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
        R.systemUser = function() {
          var TargetResource = $injector.get("SystemUser");
          var action = TargetResource["::get::Lecture::systemUser"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Lecture.proposals
     * @header lbServices.Lecture.proposals
     * @object
     * @description
     *
     * The object `Lecture.proposals` groups methods
     * manipulating `LectureProposal` instances related to `Lecture`.
     *
     * Call {@link lbServices.Lecture#proposals Lecture.proposals()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Lecture#proposals
             * @methodOf lbServices.Lecture
             *
             * @description
             *
             * Queries proposals of Lecture.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LectureProposal` object.)
             * </em>
             */
        R.proposals = function() {
          var TargetResource = $injector.get("LectureProposal");
          var action = TargetResource["::get::Lecture::proposals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Lecture.proposals#count
             * @methodOf lbServices.Lecture.proposals
             *
             * @description
             *
             * Counts proposals of Lecture.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.proposals.count = function() {
          var TargetResource = $injector.get("LectureProposal");
          var action = TargetResource["::count::Lecture::proposals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Lecture.proposals#create
             * @methodOf lbServices.Lecture.proposals
             *
             * @description
             *
             * Creates a new instance in proposals of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LectureProposal` object.)
             * </em>
             */
        R.proposals.create = function() {
          var TargetResource = $injector.get("LectureProposal");
          var action = TargetResource["::create::Lecture::proposals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Lecture.proposals#createMany
             * @methodOf lbServices.Lecture.proposals
             *
             * @description
             *
             * Creates a new instance in proposals of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LectureProposal` object.)
             * </em>
             */
        R.proposals.createMany = function() {
          var TargetResource = $injector.get("LectureProposal");
          var action = TargetResource["::createMany::Lecture::proposals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Lecture.proposals#destroyAll
             * @methodOf lbServices.Lecture.proposals
             *
             * @description
             *
             * Deletes all proposals of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.proposals.destroyAll = function() {
          var TargetResource = $injector.get("LectureProposal");
          var action = TargetResource["::delete::Lecture::proposals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Lecture.proposals#destroyById
             * @methodOf lbServices.Lecture.proposals
             *
             * @description
             *
             * Delete a related item by id for proposals.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             *  - `fk` – `{*}` - Foreign key for proposals
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.proposals.destroyById = function() {
          var TargetResource = $injector.get("LectureProposal");
          var action = TargetResource["::destroyById::Lecture::proposals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Lecture.proposals#findById
             * @methodOf lbServices.Lecture.proposals
             *
             * @description
             *
             * Find a related item by id for proposals.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             *  - `fk` – `{*}` - Foreign key for proposals
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LectureProposal` object.)
             * </em>
             */
        R.proposals.findById = function() {
          var TargetResource = $injector.get("LectureProposal");
          var action = TargetResource["::findById::Lecture::proposals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Lecture.proposals#updateById
             * @methodOf lbServices.Lecture.proposals
             *
             * @description
             *
             * Update a related item by id for proposals.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             *  - `fk` – `{*}` - Foreign key for proposals
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LectureProposal` object.)
             * </em>
             */
        R.proposals.updateById = function() {
          var TargetResource = $injector.get("LectureProposal");
          var action = TargetResource["::updateById::Lecture::proposals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Lecture#project
             * @methodOf lbServices.Lecture
             *
             * @description
             *
             * Fetches belongsTo relation project.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
        R.project = function() {
          var TargetResource = $injector.get("Project");
          var action = TargetResource["::get::Lecture::project"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Lecture.file
     * @header lbServices.Lecture.file
     * @object
     * @description
     *
     * The object `Lecture.file` groups methods
     * manipulating `File` instances related to `Lecture`.
     *
     * Call {@link lbServices.Lecture#file Lecture.file()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Lecture#file
             * @methodOf lbServices.Lecture
             *
             * @description
             *
             * Fetches hasOne relation file.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.file = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::get::Lecture::file"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Lecture.file#create
             * @methodOf lbServices.Lecture.file
             *
             * @description
             *
             * Creates a new instance in file of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.file.create = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::create::Lecture::file"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Lecture.file#createMany
             * @methodOf lbServices.Lecture.file
             *
             * @description
             *
             * Creates a new instance in file of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.file.createMany = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::createMany::Lecture::file"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Lecture.file#destroy
             * @methodOf lbServices.Lecture.file
             *
             * @description
             *
             * Deletes file of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.file.destroy = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::destroy::Lecture::file"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Lecture.file#update
             * @methodOf lbServices.Lecture.file
             *
             * @description
             *
             * Update file of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.file.update = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::update::Lecture::file"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.LectureProposal
 * @header lbServices.LectureProposal
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `LectureProposal` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "LectureProposal",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/LectureProposals/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use LectureProposal.systemUser() instead.
            "prototype$__get__systemUser": {
              url: urlBase + "/LectureProposals/:id/systemUser",
              method: "GET",
            },

            // INTERNAL. Use LectureProposal.lecture() instead.
            "prototype$__get__lecture": {
              url: urlBase + "/LectureProposals/:id/lecture",
              method: "GET",
            },

            // INTERNAL. Use LectureProposal.project() instead.
            "prototype$__get__project": {
              url: urlBase + "/LectureProposals/:id/project",
              method: "GET",
            },

            // INTERNAL. Use LectureProposal.file() instead.
            "prototype$__get__file": {
              url: urlBase + "/LectureProposals/:id/file",
              method: "GET",
            },

            // INTERNAL. Use LectureProposal.file.create() instead.
            "prototype$__create__file": {
              url: urlBase + "/LectureProposals/:id/file",
              method: "POST",
            },

            // INTERNAL. Use LectureProposal.file.update() instead.
            "prototype$__update__file": {
              url: urlBase + "/LectureProposals/:id/file",
              method: "PUT",
            },

            // INTERNAL. Use LectureProposal.file.destroy() instead.
            "prototype$__destroy__file": {
              url: urlBase + "/LectureProposals/:id/file",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.LectureProposal#create
             * @methodOf lbServices.LectureProposal
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LectureProposal` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/LectureProposals",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.LectureProposal#createMany
             * @methodOf lbServices.LectureProposal
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LectureProposal` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/LectureProposals",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.LectureProposal#upsert
             * @methodOf lbServices.LectureProposal
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LectureProposal` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/LectureProposals",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.LectureProposal#replaceOrCreate
             * @methodOf lbServices.LectureProposal
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LectureProposal` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/LectureProposals/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.LectureProposal#upsertWithWhere
             * @methodOf lbServices.LectureProposal
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LectureProposal` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/LectureProposals/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.LectureProposal#exists
             * @methodOf lbServices.LectureProposal
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/LectureProposals/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.LectureProposal#findById
             * @methodOf lbServices.LectureProposal
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LectureProposal` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/LectureProposals/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.LectureProposal#replaceById
             * @methodOf lbServices.LectureProposal
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LectureProposal` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/LectureProposals/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.LectureProposal#find
             * @methodOf lbServices.LectureProposal
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LectureProposal` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/LectureProposals",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.LectureProposal#findOne
             * @methodOf lbServices.LectureProposal
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LectureProposal` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/LectureProposals/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.LectureProposal#updateAll
             * @methodOf lbServices.LectureProposal
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/LectureProposals/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.LectureProposal#deleteById
             * @methodOf lbServices.LectureProposal
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LectureProposal` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/LectureProposals/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.LectureProposal#count
             * @methodOf lbServices.LectureProposal
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/LectureProposals/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.LectureProposal#prototype$updateAttributes
             * @methodOf lbServices.LectureProposal
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LectureProposal` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/LectureProposals/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.LectureProposal#createChangeStream
             * @methodOf lbServices.LectureProposal
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/LectureProposals/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.LectureProposal#download
             * @methodOf lbServices.LectureProposal
             *
             * @description
             *
             * Download proposal
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `res` – `{object=}` -
             *
             *  - `id` – `{string}` -
             *
             *  - `type` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LectureProposal` object.)
             * </em>
             */
            "download": {
              url: urlBase + "/LectureProposals/:id/download",
              method: "GET",
            },

            // INTERNAL. Use Lecture.proposals.findById() instead.
            "::findById::Lecture::proposals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Lectures/:id/proposals/:fk",
              method: "GET",
            },

            // INTERNAL. Use Lecture.proposals.destroyById() instead.
            "::destroyById::Lecture::proposals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Lectures/:id/proposals/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Lecture.proposals.updateById() instead.
            "::updateById::Lecture::proposals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Lectures/:id/proposals/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Lecture.proposals() instead.
            "::get::Lecture::proposals": {
              isArray: true,
              url: urlBase + "/Lectures/:id/proposals",
              method: "GET",
            },

            // INTERNAL. Use Lecture.proposals.create() instead.
            "::create::Lecture::proposals": {
              url: urlBase + "/Lectures/:id/proposals",
              method: "POST",
            },

            // INTERNAL. Use Lecture.proposals.createMany() instead.
            "::createMany::Lecture::proposals": {
              isArray: true,
              url: urlBase + "/Lectures/:id/proposals",
              method: "POST",
            },

            // INTERNAL. Use Lecture.proposals.destroyAll() instead.
            "::delete::Lecture::proposals": {
              url: urlBase + "/Lectures/:id/proposals",
              method: "DELETE",
            },

            // INTERNAL. Use Lecture.proposals.count() instead.
            "::count::Lecture::proposals": {
              url: urlBase + "/Lectures/:id/proposals/count",
              method: "GET",
            },

            // INTERNAL. Use Project.lectureProposals.findById() instead.
            "::findById::Project::lectureProposals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/lectureProposals/:fk",
              method: "GET",
            },

            // INTERNAL. Use Project.lectureProposals.destroyById() instead.
            "::destroyById::Project::lectureProposals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/lectureProposals/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Project.lectureProposals.updateById() instead.
            "::updateById::Project::lectureProposals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/lectureProposals/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Project.lectureProposals() instead.
            "::get::Project::lectureProposals": {
              isArray: true,
              url: urlBase + "/Projects/:id/lectureProposals",
              method: "GET",
            },

            // INTERNAL. Use Project.lectureProposals.create() instead.
            "::create::Project::lectureProposals": {
              url: urlBase + "/Projects/:id/lectureProposals",
              method: "POST",
            },

            // INTERNAL. Use Project.lectureProposals.createMany() instead.
            "::createMany::Project::lectureProposals": {
              isArray: true,
              url: urlBase + "/Projects/:id/lectureProposals",
              method: "POST",
            },

            // INTERNAL. Use Project.lectureProposals.destroyAll() instead.
            "::delete::Project::lectureProposals": {
              url: urlBase + "/Projects/:id/lectureProposals",
              method: "DELETE",
            },

            // INTERNAL. Use Project.lectureProposals.count() instead.
            "::count::Project::lectureProposals": {
              url: urlBase + "/Projects/:id/lectureProposals/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.LectureProposal#patchOrCreate
             * @methodOf lbServices.LectureProposal
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LectureProposal` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.LectureProposal#updateOrCreate
             * @methodOf lbServices.LectureProposal
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LectureProposal` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.LectureProposal#patchOrCreateWithWhere
             * @methodOf lbServices.LectureProposal
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LectureProposal` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.LectureProposal#update
             * @methodOf lbServices.LectureProposal
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.LectureProposal#destroyById
             * @methodOf lbServices.LectureProposal
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LectureProposal` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.LectureProposal#removeById
             * @methodOf lbServices.LectureProposal
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LectureProposal` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.LectureProposal#patchAttributes
             * @methodOf lbServices.LectureProposal
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LectureProposal` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.LectureProposal#modelName
        * @propertyOf lbServices.LectureProposal
        * @description
        * The name of the model represented by this $resource,
        * i.e. `LectureProposal`.
        */
        R.modelName = "LectureProposal";


            /**
             * @ngdoc method
             * @name lbServices.LectureProposal#systemUser
             * @methodOf lbServices.LectureProposal
             *
             * @description
             *
             * Fetches belongsTo relation systemUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
        R.systemUser = function() {
          var TargetResource = $injector.get("SystemUser");
          var action = TargetResource["::get::LectureProposal::systemUser"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.LectureProposal#lecture
             * @methodOf lbServices.LectureProposal
             *
             * @description
             *
             * Fetches belongsTo relation lecture.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lecture` object.)
             * </em>
             */
        R.lecture = function() {
          var TargetResource = $injector.get("Lecture");
          var action = TargetResource["::get::LectureProposal::lecture"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.LectureProposal#project
             * @methodOf lbServices.LectureProposal
             *
             * @description
             *
             * Fetches belongsTo relation project.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
        R.project = function() {
          var TargetResource = $injector.get("Project");
          var action = TargetResource["::get::LectureProposal::project"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.LectureProposal.file
     * @header lbServices.LectureProposal.file
     * @object
     * @description
     *
     * The object `LectureProposal.file` groups methods
     * manipulating `File` instances related to `LectureProposal`.
     *
     * Call {@link lbServices.LectureProposal#file LectureProposal.file()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.LectureProposal#file
             * @methodOf lbServices.LectureProposal
             *
             * @description
             *
             * Fetches hasOne relation file.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.file = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::get::LectureProposal::file"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.LectureProposal.file#create
             * @methodOf lbServices.LectureProposal.file
             *
             * @description
             *
             * Creates a new instance in file of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.file.create = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::create::LectureProposal::file"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.LectureProposal.file#createMany
             * @methodOf lbServices.LectureProposal.file
             *
             * @description
             *
             * Creates a new instance in file of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.file.createMany = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::createMany::LectureProposal::file"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.LectureProposal.file#destroy
             * @methodOf lbServices.LectureProposal.file
             *
             * @description
             *
             * Deletes file of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.file.destroy = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::destroy::LectureProposal::file"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.LectureProposal.file#update
             * @methodOf lbServices.LectureProposal.file
             *
             * @description
             *
             * Update file of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.file.update = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::update::LectureProposal::file"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Syllabus
 * @header lbServices.Syllabus
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Syllabus` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Syllabus",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/syllabuses/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Syllabus.systemUser() instead.
            "prototype$__get__systemUser": {
              url: urlBase + "/syllabuses/:id/systemUser",
              method: "GET",
            },

            // INTERNAL. Use Syllabus.project() instead.
            "prototype$__get__project": {
              url: urlBase + "/syllabuses/:id/project",
              method: "GET",
            },

            // INTERNAL. Use Syllabus.file() instead.
            "prototype$__get__file": {
              url: urlBase + "/syllabuses/:id/file",
              method: "GET",
            },

            // INTERNAL. Use Syllabus.file.create() instead.
            "prototype$__create__file": {
              url: urlBase + "/syllabuses/:id/file",
              method: "POST",
            },

            // INTERNAL. Use Syllabus.file.update() instead.
            "prototype$__update__file": {
              url: urlBase + "/syllabuses/:id/file",
              method: "PUT",
            },

            // INTERNAL. Use Syllabus.file.destroy() instead.
            "prototype$__destroy__file": {
              url: urlBase + "/syllabuses/:id/file",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Syllabus#create
             * @methodOf lbServices.Syllabus
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Syllabus` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/syllabuses",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Syllabus#createMany
             * @methodOf lbServices.Syllabus
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Syllabus` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/syllabuses",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Syllabus#upsert
             * @methodOf lbServices.Syllabus
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Syllabus` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/syllabuses",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Syllabus#replaceOrCreate
             * @methodOf lbServices.Syllabus
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Syllabus` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/syllabuses/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Syllabus#upsertWithWhere
             * @methodOf lbServices.Syllabus
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Syllabus` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/syllabuses/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Syllabus#exists
             * @methodOf lbServices.Syllabus
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/syllabuses/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Syllabus#findById
             * @methodOf lbServices.Syllabus
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Syllabus` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/syllabuses/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Syllabus#replaceById
             * @methodOf lbServices.Syllabus
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Syllabus` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/syllabuses/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Syllabus#find
             * @methodOf lbServices.Syllabus
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Syllabus` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/syllabuses",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Syllabus#findOne
             * @methodOf lbServices.Syllabus
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Syllabus` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/syllabuses/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Syllabus#updateAll
             * @methodOf lbServices.Syllabus
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/syllabuses/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Syllabus#deleteById
             * @methodOf lbServices.Syllabus
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Syllabus` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/syllabuses/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Syllabus#count
             * @methodOf lbServices.Syllabus
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/syllabuses/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Syllabus#prototype$updateAttributes
             * @methodOf lbServices.Syllabus
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Syllabus` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/syllabuses/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Syllabus#createChangeStream
             * @methodOf lbServices.Syllabus
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/syllabuses/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Project.syllabuses.findById() instead.
            "::findById::Project::syllabuses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/syllabuses/:fk",
              method: "GET",
            },

            // INTERNAL. Use Project.syllabuses.destroyById() instead.
            "::destroyById::Project::syllabuses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/syllabuses/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Project.syllabuses.updateById() instead.
            "::updateById::Project::syllabuses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/syllabuses/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Project.syllabuses() instead.
            "::get::Project::syllabuses": {
              isArray: true,
              url: urlBase + "/Projects/:id/syllabuses",
              method: "GET",
            },

            // INTERNAL. Use Project.syllabuses.create() instead.
            "::create::Project::syllabuses": {
              url: urlBase + "/Projects/:id/syllabuses",
              method: "POST",
            },

            // INTERNAL. Use Project.syllabuses.createMany() instead.
            "::createMany::Project::syllabuses": {
              isArray: true,
              url: urlBase + "/Projects/:id/syllabuses",
              method: "POST",
            },

            // INTERNAL. Use Project.syllabuses.destroyAll() instead.
            "::delete::Project::syllabuses": {
              url: urlBase + "/Projects/:id/syllabuses",
              method: "DELETE",
            },

            // INTERNAL. Use Project.syllabuses.count() instead.
            "::count::Project::syllabuses": {
              url: urlBase + "/Projects/:id/syllabuses/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Syllabus#patchOrCreate
             * @methodOf lbServices.Syllabus
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Syllabus` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Syllabus#updateOrCreate
             * @methodOf lbServices.Syllabus
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Syllabus` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Syllabus#patchOrCreateWithWhere
             * @methodOf lbServices.Syllabus
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Syllabus` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Syllabus#update
             * @methodOf lbServices.Syllabus
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Syllabus#destroyById
             * @methodOf lbServices.Syllabus
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Syllabus` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Syllabus#removeById
             * @methodOf lbServices.Syllabus
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Syllabus` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Syllabus#patchAttributes
             * @methodOf lbServices.Syllabus
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Syllabus` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Syllabus#modelName
        * @propertyOf lbServices.Syllabus
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Syllabus`.
        */
        R.modelName = "Syllabus";


            /**
             * @ngdoc method
             * @name lbServices.Syllabus#systemUser
             * @methodOf lbServices.Syllabus
             *
             * @description
             *
             * Fetches belongsTo relation systemUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
        R.systemUser = function() {
          var TargetResource = $injector.get("SystemUser");
          var action = TargetResource["::get::Syllabus::systemUser"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Syllabus#project
             * @methodOf lbServices.Syllabus
             *
             * @description
             *
             * Fetches belongsTo relation project.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
        R.project = function() {
          var TargetResource = $injector.get("Project");
          var action = TargetResource["::get::Syllabus::project"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Syllabus.file
     * @header lbServices.Syllabus.file
     * @object
     * @description
     *
     * The object `Syllabus.file` groups methods
     * manipulating `File` instances related to `Syllabus`.
     *
     * Call {@link lbServices.Syllabus#file Syllabus.file()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Syllabus#file
             * @methodOf lbServices.Syllabus
             *
             * @description
             *
             * Fetches hasOne relation file.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.file = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::get::Syllabus::file"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Syllabus.file#create
             * @methodOf lbServices.Syllabus.file
             *
             * @description
             *
             * Creates a new instance in file of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.file.create = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::create::Syllabus::file"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Syllabus.file#createMany
             * @methodOf lbServices.Syllabus.file
             *
             * @description
             *
             * Creates a new instance in file of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.file.createMany = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::createMany::Syllabus::file"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Syllabus.file#destroy
             * @methodOf lbServices.Syllabus.file
             *
             * @description
             *
             * Deletes file of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.file.destroy = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::destroy::Syllabus::file"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Syllabus.file#update
             * @methodOf lbServices.Syllabus.file
             *
             * @description
             *
             * Update file of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.file.update = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::update::Syllabus::file"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Assessment
 * @header lbServices.Assessment
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Assessment` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Assessment",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Assessments/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Assessment.systemUser() instead.
            "prototype$__get__systemUser": {
              url: urlBase + "/Assessments/:id/systemUser",
              method: "GET",
            },

            // INTERNAL. Use Assessment.project() instead.
            "prototype$__get__project": {
              url: urlBase + "/Assessments/:id/project",
              method: "GET",
            },

            // INTERNAL. Use Assessment.file() instead.
            "prototype$__get__file": {
              url: urlBase + "/Assessments/:id/file",
              method: "GET",
            },

            // INTERNAL. Use Assessment.file.create() instead.
            "prototype$__create__file": {
              url: urlBase + "/Assessments/:id/file",
              method: "POST",
            },

            // INTERNAL. Use Assessment.file.update() instead.
            "prototype$__update__file": {
              url: urlBase + "/Assessments/:id/file",
              method: "PUT",
            },

            // INTERNAL. Use Assessment.file.destroy() instead.
            "prototype$__destroy__file": {
              url: urlBase + "/Assessments/:id/file",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Assessment#create
             * @methodOf lbServices.Assessment
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Assessment` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Assessments",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Assessment#createMany
             * @methodOf lbServices.Assessment
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Assessment` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Assessments",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Assessment#upsert
             * @methodOf lbServices.Assessment
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Assessment` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Assessments",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Assessment#replaceOrCreate
             * @methodOf lbServices.Assessment
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Assessment` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Assessments/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Assessment#upsertWithWhere
             * @methodOf lbServices.Assessment
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Assessment` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Assessments/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Assessment#exists
             * @methodOf lbServices.Assessment
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Assessments/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Assessment#findById
             * @methodOf lbServices.Assessment
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Assessment` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Assessments/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Assessment#replaceById
             * @methodOf lbServices.Assessment
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Assessment` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Assessments/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Assessment#find
             * @methodOf lbServices.Assessment
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Assessment` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Assessments",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Assessment#findOne
             * @methodOf lbServices.Assessment
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Assessment` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Assessments/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Assessment#updateAll
             * @methodOf lbServices.Assessment
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Assessments/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Assessment#deleteById
             * @methodOf lbServices.Assessment
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Assessment` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Assessments/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Assessment#count
             * @methodOf lbServices.Assessment
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Assessments/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Assessment#prototype$updateAttributes
             * @methodOf lbServices.Assessment
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Assessment` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Assessments/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Assessment#createChangeStream
             * @methodOf lbServices.Assessment
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Assessments/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Project.assessments.findById() instead.
            "::findById::Project::assessments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/assessments/:fk",
              method: "GET",
            },

            // INTERNAL. Use Project.assessments.destroyById() instead.
            "::destroyById::Project::assessments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/assessments/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Project.assessments.updateById() instead.
            "::updateById::Project::assessments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/assessments/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Project.assessments() instead.
            "::get::Project::assessments": {
              isArray: true,
              url: urlBase + "/Projects/:id/assessments",
              method: "GET",
            },

            // INTERNAL. Use Project.assessments.create() instead.
            "::create::Project::assessments": {
              url: urlBase + "/Projects/:id/assessments",
              method: "POST",
            },

            // INTERNAL. Use Project.assessments.createMany() instead.
            "::createMany::Project::assessments": {
              isArray: true,
              url: urlBase + "/Projects/:id/assessments",
              method: "POST",
            },

            // INTERNAL. Use Project.assessments.destroyAll() instead.
            "::delete::Project::assessments": {
              url: urlBase + "/Projects/:id/assessments",
              method: "DELETE",
            },

            // INTERNAL. Use Project.assessments.count() instead.
            "::count::Project::assessments": {
              url: urlBase + "/Projects/:id/assessments/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Assessment#patchOrCreate
             * @methodOf lbServices.Assessment
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Assessment` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Assessment#updateOrCreate
             * @methodOf lbServices.Assessment
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Assessment` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Assessment#patchOrCreateWithWhere
             * @methodOf lbServices.Assessment
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Assessment` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Assessment#update
             * @methodOf lbServices.Assessment
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Assessment#destroyById
             * @methodOf lbServices.Assessment
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Assessment` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Assessment#removeById
             * @methodOf lbServices.Assessment
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Assessment` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Assessment#patchAttributes
             * @methodOf lbServices.Assessment
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Assessment` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Assessment#modelName
        * @propertyOf lbServices.Assessment
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Assessment`.
        */
        R.modelName = "Assessment";


            /**
             * @ngdoc method
             * @name lbServices.Assessment#systemUser
             * @methodOf lbServices.Assessment
             *
             * @description
             *
             * Fetches belongsTo relation systemUser.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
        R.systemUser = function() {
          var TargetResource = $injector.get("SystemUser");
          var action = TargetResource["::get::Assessment::systemUser"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Assessment#project
             * @methodOf lbServices.Assessment
             *
             * @description
             *
             * Fetches belongsTo relation project.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
        R.project = function() {
          var TargetResource = $injector.get("Project");
          var action = TargetResource["::get::Assessment::project"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Assessment.file
     * @header lbServices.Assessment.file
     * @object
     * @description
     *
     * The object `Assessment.file` groups methods
     * manipulating `File` instances related to `Assessment`.
     *
     * Call {@link lbServices.Assessment#file Assessment.file()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Assessment#file
             * @methodOf lbServices.Assessment
             *
             * @description
             *
             * Fetches hasOne relation file.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.file = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::get::Assessment::file"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Assessment.file#create
             * @methodOf lbServices.Assessment.file
             *
             * @description
             *
             * Creates a new instance in file of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.file.create = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::create::Assessment::file"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Assessment.file#createMany
             * @methodOf lbServices.Assessment.file
             *
             * @description
             *
             * Creates a new instance in file of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.file.createMany = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::createMany::Assessment::file"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Assessment.file#destroy
             * @methodOf lbServices.Assessment.file
             *
             * @description
             *
             * Deletes file of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.file.destroy = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::destroy::Assessment::file"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Assessment.file#update
             * @methodOf lbServices.Assessment.file
             *
             * @description
             *
             * Update file of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProjectComponent id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.file.update = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::update::Assessment::file"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Project
 * @header lbServices.Project
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Project` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Project",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Projects/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Project#prototype$__findById__access
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Find a related item by id for access.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for access
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
            "prototype$__findById__access": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/access/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Project#prototype$__destroyById__access
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Delete a related item by id for access.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for access
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__access": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/access/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Project#prototype$__updateById__access
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Update a related item by id for access.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for access
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
            "prototype$__updateById__access": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/access/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Project.users.link() instead.
            "prototype$__link__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/users/rel/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Project.users.unlink() instead.
            "prototype$__unlink__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/users/rel/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Project.users.exists() instead.
            "prototype$__exists__users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/users/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use Project.assessments.findById() instead.
            "prototype$__findById__assessments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/assessments/:fk",
              method: "GET",
            },

            // INTERNAL. Use Project.assessments.destroyById() instead.
            "prototype$__destroyById__assessments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/assessments/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Project.assessments.updateById() instead.
            "prototype$__updateById__assessments": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/assessments/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Project.exams.findById() instead.
            "prototype$__findById__exams": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/exams/:fk",
              method: "GET",
            },

            // INTERNAL. Use Project.exams.destroyById() instead.
            "prototype$__destroyById__exams": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/exams/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Project.exams.updateById() instead.
            "prototype$__updateById__exams": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/exams/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Project.lectures.findById() instead.
            "prototype$__findById__lectures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/lectures/:fk",
              method: "GET",
            },

            // INTERNAL. Use Project.lectures.destroyById() instead.
            "prototype$__destroyById__lectures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/lectures/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Project.lectures.updateById() instead.
            "prototype$__updateById__lectures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/lectures/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Project.lectureProposals.findById() instead.
            "prototype$__findById__lectureProposals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/lectureProposals/:fk",
              method: "GET",
            },

            // INTERNAL. Use Project.lectureProposals.destroyById() instead.
            "prototype$__destroyById__lectureProposals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/lectureProposals/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Project.lectureProposals.updateById() instead.
            "prototype$__updateById__lectureProposals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/lectureProposals/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Project.syllabuses.findById() instead.
            "prototype$__findById__syllabuses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/syllabuses/:fk",
              method: "GET",
            },

            // INTERNAL. Use Project.syllabuses.destroyById() instead.
            "prototype$__destroyById__syllabuses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/syllabuses/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Project.syllabuses.updateById() instead.
            "prototype$__updateById__syllabuses": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/syllabuses/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Project.organization() instead.
            "prototype$__get__organization": {
              url: urlBase + "/Projects/:id/organization",
              method: "GET",
            },

            // INTERNAL. Use Project.files.findById() instead.
            "prototype$__findById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/files/:fk",
              method: "GET",
            },

            // INTERNAL. Use Project.files.destroyById() instead.
            "prototype$__destroyById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/files/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Project.files.updateById() instead.
            "prototype$__updateById__files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/files/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Project#prototype$__get__access
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Queries access of Project.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
            "prototype$__get__access": {
              isArray: true,
              url: urlBase + "/Projects/:id/access",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Project#prototype$__create__access
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Creates a new instance in access of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
            "prototype$__create__access": {
              url: urlBase + "/Projects/:id/access",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Project#prototype$__delete__access
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Deletes all access of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__access": {
              url: urlBase + "/Projects/:id/access",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Project#prototype$__count__access
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Counts access of Project.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__access": {
              url: urlBase + "/Projects/:id/access/count",
              method: "GET",
            },

            // INTERNAL. Use Project.users() instead.
            "prototype$__get__users": {
              isArray: true,
              url: urlBase + "/Projects/:id/users",
              method: "GET",
            },

            // INTERNAL. Use Project.assessments() instead.
            "prototype$__get__assessments": {
              isArray: true,
              url: urlBase + "/Projects/:id/assessments",
              method: "GET",
            },

            // INTERNAL. Use Project.assessments.create() instead.
            "prototype$__create__assessments": {
              url: urlBase + "/Projects/:id/assessments",
              method: "POST",
            },

            // INTERNAL. Use Project.assessments.destroyAll() instead.
            "prototype$__delete__assessments": {
              url: urlBase + "/Projects/:id/assessments",
              method: "DELETE",
            },

            // INTERNAL. Use Project.assessments.count() instead.
            "prototype$__count__assessments": {
              url: urlBase + "/Projects/:id/assessments/count",
              method: "GET",
            },

            // INTERNAL. Use Project.exams() instead.
            "prototype$__get__exams": {
              isArray: true,
              url: urlBase + "/Projects/:id/exams",
              method: "GET",
            },

            // INTERNAL. Use Project.exams.create() instead.
            "prototype$__create__exams": {
              url: urlBase + "/Projects/:id/exams",
              method: "POST",
            },

            // INTERNAL. Use Project.exams.destroyAll() instead.
            "prototype$__delete__exams": {
              url: urlBase + "/Projects/:id/exams",
              method: "DELETE",
            },

            // INTERNAL. Use Project.exams.count() instead.
            "prototype$__count__exams": {
              url: urlBase + "/Projects/:id/exams/count",
              method: "GET",
            },

            // INTERNAL. Use Project.lectures() instead.
            "prototype$__get__lectures": {
              isArray: true,
              url: urlBase + "/Projects/:id/lectures",
              method: "GET",
            },

            // INTERNAL. Use Project.lectures.create() instead.
            "prototype$__create__lectures": {
              url: urlBase + "/Projects/:id/lectures",
              method: "POST",
            },

            // INTERNAL. Use Project.lectures.destroyAll() instead.
            "prototype$__delete__lectures": {
              url: urlBase + "/Projects/:id/lectures",
              method: "DELETE",
            },

            // INTERNAL. Use Project.lectures.count() instead.
            "prototype$__count__lectures": {
              url: urlBase + "/Projects/:id/lectures/count",
              method: "GET",
            },

            // INTERNAL. Use Project.lectureProposals() instead.
            "prototype$__get__lectureProposals": {
              isArray: true,
              url: urlBase + "/Projects/:id/lectureProposals",
              method: "GET",
            },

            // INTERNAL. Use Project.lectureProposals.create() instead.
            "prototype$__create__lectureProposals": {
              url: urlBase + "/Projects/:id/lectureProposals",
              method: "POST",
            },

            // INTERNAL. Use Project.lectureProposals.destroyAll() instead.
            "prototype$__delete__lectureProposals": {
              url: urlBase + "/Projects/:id/lectureProposals",
              method: "DELETE",
            },

            // INTERNAL. Use Project.lectureProposals.count() instead.
            "prototype$__count__lectureProposals": {
              url: urlBase + "/Projects/:id/lectureProposals/count",
              method: "GET",
            },

            // INTERNAL. Use Project.syllabuses() instead.
            "prototype$__get__syllabuses": {
              isArray: true,
              url: urlBase + "/Projects/:id/syllabuses",
              method: "GET",
            },

            // INTERNAL. Use Project.syllabuses.create() instead.
            "prototype$__create__syllabuses": {
              url: urlBase + "/Projects/:id/syllabuses",
              method: "POST",
            },

            // INTERNAL. Use Project.syllabuses.destroyAll() instead.
            "prototype$__delete__syllabuses": {
              url: urlBase + "/Projects/:id/syllabuses",
              method: "DELETE",
            },

            // INTERNAL. Use Project.syllabuses.count() instead.
            "prototype$__count__syllabuses": {
              url: urlBase + "/Projects/:id/syllabuses/count",
              method: "GET",
            },

            // INTERNAL. Use Project.files() instead.
            "prototype$__get__files": {
              isArray: true,
              url: urlBase + "/Projects/:id/files",
              method: "GET",
            },

            // INTERNAL. Use Project.files.create() instead.
            "prototype$__create__files": {
              url: urlBase + "/Projects/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Project.files.destroyAll() instead.
            "prototype$__delete__files": {
              url: urlBase + "/Projects/:id/files",
              method: "DELETE",
            },

            // INTERNAL. Use Project.files.count() instead.
            "prototype$__count__files": {
              url: urlBase + "/Projects/:id/files/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Project#create
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Projects",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Project#createMany
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Projects",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Project#upsert
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Projects",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Project#replaceOrCreate
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Projects/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Project#upsertWithWhere
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Projects/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Project#exists
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Projects/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Project#findById
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Projects/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Project#replaceById
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Projects/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Project#find
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Projects",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Project#findOne
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Projects/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Project#updateAll
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Projects/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Project#deleteById
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Projects/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Project#count
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Projects/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Project#prototype$updateAttributes
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Projects/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Project#createChangeStream
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Projects/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Project#getFiles
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Get files of a specific type : e.g Projects/A/creo-lectures/files
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             *  - `componentModel` – `{string}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
            "getFiles": {
              isArray: true,
              url: urlBase + "/Projects/:id/:componentModel/files",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Project#inviteUsers
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Invite users with roles to a project
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `users` – `{*}` - JSON Array of users
             *
             *  - `id` – `{string}` - Valid Project ID
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `usersInvited` – `{number=}` -
             */
            "inviteUsers": {
              url: urlBase + "/Projects/:id/inviteUsers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Project#lectureOrder
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Update the order of a Project's order
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{string}` -
             *
             *  - `lectureIdsInOrder` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
            "lectureOrder": {
              url: urlBase + "/Projects/:id/lectureOrder/:lectureIdsInOrder",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Project#updateProposal
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Accept or Reject a Lecture's Proposal
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{string}` -
             *
             *  - `proposalId` – `{string}` -
             *
             *  - `action` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
            "updateProposal": {
              url: urlBase + "/Projects/:id/lectureProposal/:proposalId/:action",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Project#uploadFile
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Uploads a file for this project : e.g projectAId/assessment/uploadFile
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `id` – `{string}` -
             *
             *  - `componentModel` – `{string}` -
             *
             *  - `meta` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
            "uploadFile": {
              url: urlBase + "/Projects/:id/:componentModel/uploadFile",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Project#updateFile
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Updates a file for this project : e.g ProjectsprojectAId/assessment/updateFile/:FileId1
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `id` – `{string}` -
             *
             *  - `componentModel` – `{string}` -
             *
             *  - `fileId` – `{string}` -
             *
             *  - `meta` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
            "updateFile": {
              url: urlBase + "/Projects/:id/:componentModel/updateFile/:fileId",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Project#userRoles
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Get the current user roles in this project
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `user` – `{object=}` -
             */
            "userRoles": {
              url: urlBase + "/Projects/:id/userRoles",
              method: "GET",
            },

            // INTERNAL. Use SystemUser.organizations.exists() instead.
            "::exists::SystemUser::organizations": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SystemUsers/:id/organizations/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SystemUser.projects.exists() instead.
            "::exists::SystemUser::projects": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/SystemUsers/:id/projects/rel/:fk",
              method: "HEAD",
            },

            // INTERNAL. Use SystemUser.organizations() instead.
            "::get::SystemUser::organizations": {
              isArray: true,
              url: urlBase + "/SystemUsers/:id/organizations",
              method: "GET",
            },

            // INTERNAL. Use SystemUser.projects() instead.
            "::get::SystemUser::projects": {
              isArray: true,
              url: urlBase + "/SystemUsers/:id/projects",
              method: "GET",
            },

            // INTERNAL. Use Organization.projects.findById() instead.
            "::findById::Organization::projects": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/projects/:fk",
              method: "GET",
            },

            // INTERNAL. Use Organization.projects.destroyById() instead.
            "::destroyById::Organization::projects": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/projects/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Organization.projects.updateById() instead.
            "::updateById::Organization::projects": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/projects/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Organization.projects() instead.
            "::get::Organization::projects": {
              isArray: true,
              url: urlBase + "/Organizations/:id/projects",
              method: "GET",
            },

            // INTERNAL. Use Organization.projects.create() instead.
            "::create::Organization::projects": {
              url: urlBase + "/Organizations/:id/projects",
              method: "POST",
            },

            // INTERNAL. Use Organization.projects.createMany() instead.
            "::createMany::Organization::projects": {
              isArray: true,
              url: urlBase + "/Organizations/:id/projects",
              method: "POST",
            },

            // INTERNAL. Use Organization.projects.destroyAll() instead.
            "::delete::Organization::projects": {
              url: urlBase + "/Organizations/:id/projects",
              method: "DELETE",
            },

            // INTERNAL. Use Organization.projects.count() instead.
            "::count::Organization::projects": {
              url: urlBase + "/Organizations/:id/projects/count",
              method: "GET",
            },

            // INTERNAL. Use Exam.project() instead.
            "::get::Exam::project": {
              url: urlBase + "/Exams/:id/project",
              method: "GET",
            },

            // INTERNAL. Use Lecture.project() instead.
            "::get::Lecture::project": {
              url: urlBase + "/Lectures/:id/project",
              method: "GET",
            },

            // INTERNAL. Use LectureProposal.project() instead.
            "::get::LectureProposal::project": {
              url: urlBase + "/LectureProposals/:id/project",
              method: "GET",
            },

            // INTERNAL. Use Syllabus.project() instead.
            "::get::Syllabus::project": {
              url: urlBase + "/syllabuses/:id/project",
              method: "GET",
            },

            // INTERNAL. Use Assessment.project() instead.
            "::get::Assessment::project": {
              url: urlBase + "/Assessments/:id/project",
              method: "GET",
            },

            // INTERNAL. Use File.project() instead.
            "::get::File::project": {
              url: urlBase + "/Files/:id/project",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Project#patchOrCreate
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Project#updateOrCreate
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Project#patchOrCreateWithWhere
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Project#update
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Project#destroyById
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Project#removeById
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Project#patchAttributes
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Project#modelName
        * @propertyOf lbServices.Project
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Project`.
        */
        R.modelName = "Project";

    /**
     * @ngdoc object
     * @name lbServices.Project.users
     * @header lbServices.Project.users
     * @object
     * @description
     *
     * The object `Project.users` groups methods
     * manipulating `SystemUser` instances related to `Project`.
     *
     * Call {@link lbServices.Project#users Project.users()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Project#users
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Queries users of Project.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
        R.users = function() {
          var TargetResource = $injector.get("SystemUser");
          var action = TargetResource["::get::Project::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.users#exists
             * @methodOf lbServices.Project.users
             *
             * @description
             *
             * Check the existence of users relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
        R.users.exists = function() {
          var TargetResource = $injector.get("SystemUser");
          var action = TargetResource["::exists::Project::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.users#link
             * @methodOf lbServices.Project.users
             *
             * @description
             *
             * Add a related item by id for users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SystemUser` object.)
             * </em>
             */
        R.users.link = function() {
          var TargetResource = $injector.get("SystemUser");
          var action = TargetResource["::link::Project::users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.users#unlink
             * @methodOf lbServices.Project.users
             *
             * @description
             *
             * Remove the users relation to an item by id.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.users.unlink = function() {
          var TargetResource = $injector.get("SystemUser");
          var action = TargetResource["::unlink::Project::users"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Project.assessments
     * @header lbServices.Project.assessments
     * @object
     * @description
     *
     * The object `Project.assessments` groups methods
     * manipulating `Assessment` instances related to `Project`.
     *
     * Call {@link lbServices.Project#assessments Project.assessments()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Project#assessments
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Queries assessments of Project.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Assessment` object.)
             * </em>
             */
        R.assessments = function() {
          var TargetResource = $injector.get("Assessment");
          var action = TargetResource["::get::Project::assessments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.assessments#count
             * @methodOf lbServices.Project.assessments
             *
             * @description
             *
             * Counts assessments of Project.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.assessments.count = function() {
          var TargetResource = $injector.get("Assessment");
          var action = TargetResource["::count::Project::assessments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.assessments#create
             * @methodOf lbServices.Project.assessments
             *
             * @description
             *
             * Creates a new instance in assessments of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Assessment` object.)
             * </em>
             */
        R.assessments.create = function() {
          var TargetResource = $injector.get("Assessment");
          var action = TargetResource["::create::Project::assessments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.assessments#createMany
             * @methodOf lbServices.Project.assessments
             *
             * @description
             *
             * Creates a new instance in assessments of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Assessment` object.)
             * </em>
             */
        R.assessments.createMany = function() {
          var TargetResource = $injector.get("Assessment");
          var action = TargetResource["::createMany::Project::assessments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.assessments#destroyAll
             * @methodOf lbServices.Project.assessments
             *
             * @description
             *
             * Deletes all assessments of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.assessments.destroyAll = function() {
          var TargetResource = $injector.get("Assessment");
          var action = TargetResource["::delete::Project::assessments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.assessments#destroyById
             * @methodOf lbServices.Project.assessments
             *
             * @description
             *
             * Delete a related item by id for assessments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for assessments
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.assessments.destroyById = function() {
          var TargetResource = $injector.get("Assessment");
          var action = TargetResource["::destroyById::Project::assessments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.assessments#findById
             * @methodOf lbServices.Project.assessments
             *
             * @description
             *
             * Find a related item by id for assessments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for assessments
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Assessment` object.)
             * </em>
             */
        R.assessments.findById = function() {
          var TargetResource = $injector.get("Assessment");
          var action = TargetResource["::findById::Project::assessments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.assessments#updateById
             * @methodOf lbServices.Project.assessments
             *
             * @description
             *
             * Update a related item by id for assessments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for assessments
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Assessment` object.)
             * </em>
             */
        R.assessments.updateById = function() {
          var TargetResource = $injector.get("Assessment");
          var action = TargetResource["::updateById::Project::assessments"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Project.exams
     * @header lbServices.Project.exams
     * @object
     * @description
     *
     * The object `Project.exams` groups methods
     * manipulating `Exam` instances related to `Project`.
     *
     * Call {@link lbServices.Project#exams Project.exams()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Project#exams
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Queries exams of Project.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Exam` object.)
             * </em>
             */
        R.exams = function() {
          var TargetResource = $injector.get("Exam");
          var action = TargetResource["::get::Project::exams"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.exams#count
             * @methodOf lbServices.Project.exams
             *
             * @description
             *
             * Counts exams of Project.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.exams.count = function() {
          var TargetResource = $injector.get("Exam");
          var action = TargetResource["::count::Project::exams"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.exams#create
             * @methodOf lbServices.Project.exams
             *
             * @description
             *
             * Creates a new instance in exams of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Exam` object.)
             * </em>
             */
        R.exams.create = function() {
          var TargetResource = $injector.get("Exam");
          var action = TargetResource["::create::Project::exams"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.exams#createMany
             * @methodOf lbServices.Project.exams
             *
             * @description
             *
             * Creates a new instance in exams of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Exam` object.)
             * </em>
             */
        R.exams.createMany = function() {
          var TargetResource = $injector.get("Exam");
          var action = TargetResource["::createMany::Project::exams"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.exams#destroyAll
             * @methodOf lbServices.Project.exams
             *
             * @description
             *
             * Deletes all exams of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.exams.destroyAll = function() {
          var TargetResource = $injector.get("Exam");
          var action = TargetResource["::delete::Project::exams"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.exams#destroyById
             * @methodOf lbServices.Project.exams
             *
             * @description
             *
             * Delete a related item by id for exams.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for exams
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.exams.destroyById = function() {
          var TargetResource = $injector.get("Exam");
          var action = TargetResource["::destroyById::Project::exams"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.exams#findById
             * @methodOf lbServices.Project.exams
             *
             * @description
             *
             * Find a related item by id for exams.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for exams
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Exam` object.)
             * </em>
             */
        R.exams.findById = function() {
          var TargetResource = $injector.get("Exam");
          var action = TargetResource["::findById::Project::exams"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.exams#updateById
             * @methodOf lbServices.Project.exams
             *
             * @description
             *
             * Update a related item by id for exams.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for exams
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Exam` object.)
             * </em>
             */
        R.exams.updateById = function() {
          var TargetResource = $injector.get("Exam");
          var action = TargetResource["::updateById::Project::exams"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Project.lectures
     * @header lbServices.Project.lectures
     * @object
     * @description
     *
     * The object `Project.lectures` groups methods
     * manipulating `Lecture` instances related to `Project`.
     *
     * Call {@link lbServices.Project#lectures Project.lectures()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Project#lectures
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Queries lectures of Project.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lecture` object.)
             * </em>
             */
        R.lectures = function() {
          var TargetResource = $injector.get("Lecture");
          var action = TargetResource["::get::Project::lectures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.lectures#count
             * @methodOf lbServices.Project.lectures
             *
             * @description
             *
             * Counts lectures of Project.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.lectures.count = function() {
          var TargetResource = $injector.get("Lecture");
          var action = TargetResource["::count::Project::lectures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.lectures#create
             * @methodOf lbServices.Project.lectures
             *
             * @description
             *
             * Creates a new instance in lectures of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lecture` object.)
             * </em>
             */
        R.lectures.create = function() {
          var TargetResource = $injector.get("Lecture");
          var action = TargetResource["::create::Project::lectures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.lectures#createMany
             * @methodOf lbServices.Project.lectures
             *
             * @description
             *
             * Creates a new instance in lectures of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lecture` object.)
             * </em>
             */
        R.lectures.createMany = function() {
          var TargetResource = $injector.get("Lecture");
          var action = TargetResource["::createMany::Project::lectures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.lectures#destroyAll
             * @methodOf lbServices.Project.lectures
             *
             * @description
             *
             * Deletes all lectures of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.lectures.destroyAll = function() {
          var TargetResource = $injector.get("Lecture");
          var action = TargetResource["::delete::Project::lectures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.lectures#destroyById
             * @methodOf lbServices.Project.lectures
             *
             * @description
             *
             * Delete a related item by id for lectures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for lectures
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.lectures.destroyById = function() {
          var TargetResource = $injector.get("Lecture");
          var action = TargetResource["::destroyById::Project::lectures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.lectures#findById
             * @methodOf lbServices.Project.lectures
             *
             * @description
             *
             * Find a related item by id for lectures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for lectures
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lecture` object.)
             * </em>
             */
        R.lectures.findById = function() {
          var TargetResource = $injector.get("Lecture");
          var action = TargetResource["::findById::Project::lectures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.lectures#updateById
             * @methodOf lbServices.Project.lectures
             *
             * @description
             *
             * Update a related item by id for lectures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for lectures
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Lecture` object.)
             * </em>
             */
        R.lectures.updateById = function() {
          var TargetResource = $injector.get("Lecture");
          var action = TargetResource["::updateById::Project::lectures"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Project.lectureProposals
     * @header lbServices.Project.lectureProposals
     * @object
     * @description
     *
     * The object `Project.lectureProposals` groups methods
     * manipulating `LectureProposal` instances related to `Project`.
     *
     * Call {@link lbServices.Project#lectureProposals Project.lectureProposals()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Project#lectureProposals
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Queries lectureProposals of Project.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LectureProposal` object.)
             * </em>
             */
        R.lectureProposals = function() {
          var TargetResource = $injector.get("LectureProposal");
          var action = TargetResource["::get::Project::lectureProposals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.lectureProposals#count
             * @methodOf lbServices.Project.lectureProposals
             *
             * @description
             *
             * Counts lectureProposals of Project.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.lectureProposals.count = function() {
          var TargetResource = $injector.get("LectureProposal");
          var action = TargetResource["::count::Project::lectureProposals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.lectureProposals#create
             * @methodOf lbServices.Project.lectureProposals
             *
             * @description
             *
             * Creates a new instance in lectureProposals of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LectureProposal` object.)
             * </em>
             */
        R.lectureProposals.create = function() {
          var TargetResource = $injector.get("LectureProposal");
          var action = TargetResource["::create::Project::lectureProposals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.lectureProposals#createMany
             * @methodOf lbServices.Project.lectureProposals
             *
             * @description
             *
             * Creates a new instance in lectureProposals of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LectureProposal` object.)
             * </em>
             */
        R.lectureProposals.createMany = function() {
          var TargetResource = $injector.get("LectureProposal");
          var action = TargetResource["::createMany::Project::lectureProposals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.lectureProposals#destroyAll
             * @methodOf lbServices.Project.lectureProposals
             *
             * @description
             *
             * Deletes all lectureProposals of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.lectureProposals.destroyAll = function() {
          var TargetResource = $injector.get("LectureProposal");
          var action = TargetResource["::delete::Project::lectureProposals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.lectureProposals#destroyById
             * @methodOf lbServices.Project.lectureProposals
             *
             * @description
             *
             * Delete a related item by id for lectureProposals.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for lectureProposals
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.lectureProposals.destroyById = function() {
          var TargetResource = $injector.get("LectureProposal");
          var action = TargetResource["::destroyById::Project::lectureProposals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.lectureProposals#findById
             * @methodOf lbServices.Project.lectureProposals
             *
             * @description
             *
             * Find a related item by id for lectureProposals.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for lectureProposals
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LectureProposal` object.)
             * </em>
             */
        R.lectureProposals.findById = function() {
          var TargetResource = $injector.get("LectureProposal");
          var action = TargetResource["::findById::Project::lectureProposals"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.lectureProposals#updateById
             * @methodOf lbServices.Project.lectureProposals
             *
             * @description
             *
             * Update a related item by id for lectureProposals.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for lectureProposals
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LectureProposal` object.)
             * </em>
             */
        R.lectureProposals.updateById = function() {
          var TargetResource = $injector.get("LectureProposal");
          var action = TargetResource["::updateById::Project::lectureProposals"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Project.syllabuses
     * @header lbServices.Project.syllabuses
     * @object
     * @description
     *
     * The object `Project.syllabuses` groups methods
     * manipulating `Syllabus` instances related to `Project`.
     *
     * Call {@link lbServices.Project#syllabuses Project.syllabuses()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Project#syllabuses
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Queries syllabuses of Project.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Syllabus` object.)
             * </em>
             */
        R.syllabuses = function() {
          var TargetResource = $injector.get("Syllabus");
          var action = TargetResource["::get::Project::syllabuses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.syllabuses#count
             * @methodOf lbServices.Project.syllabuses
             *
             * @description
             *
             * Counts syllabuses of Project.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.syllabuses.count = function() {
          var TargetResource = $injector.get("Syllabus");
          var action = TargetResource["::count::Project::syllabuses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.syllabuses#create
             * @methodOf lbServices.Project.syllabuses
             *
             * @description
             *
             * Creates a new instance in syllabuses of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Syllabus` object.)
             * </em>
             */
        R.syllabuses.create = function() {
          var TargetResource = $injector.get("Syllabus");
          var action = TargetResource["::create::Project::syllabuses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.syllabuses#createMany
             * @methodOf lbServices.Project.syllabuses
             *
             * @description
             *
             * Creates a new instance in syllabuses of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Syllabus` object.)
             * </em>
             */
        R.syllabuses.createMany = function() {
          var TargetResource = $injector.get("Syllabus");
          var action = TargetResource["::createMany::Project::syllabuses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.syllabuses#destroyAll
             * @methodOf lbServices.Project.syllabuses
             *
             * @description
             *
             * Deletes all syllabuses of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.syllabuses.destroyAll = function() {
          var TargetResource = $injector.get("Syllabus");
          var action = TargetResource["::delete::Project::syllabuses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.syllabuses#destroyById
             * @methodOf lbServices.Project.syllabuses
             *
             * @description
             *
             * Delete a related item by id for syllabuses.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for syllabuses
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.syllabuses.destroyById = function() {
          var TargetResource = $injector.get("Syllabus");
          var action = TargetResource["::destroyById::Project::syllabuses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.syllabuses#findById
             * @methodOf lbServices.Project.syllabuses
             *
             * @description
             *
             * Find a related item by id for syllabuses.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for syllabuses
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Syllabus` object.)
             * </em>
             */
        R.syllabuses.findById = function() {
          var TargetResource = $injector.get("Syllabus");
          var action = TargetResource["::findById::Project::syllabuses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.syllabuses#updateById
             * @methodOf lbServices.Project.syllabuses
             *
             * @description
             *
             * Update a related item by id for syllabuses.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for syllabuses
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Syllabus` object.)
             * </em>
             */
        R.syllabuses.updateById = function() {
          var TargetResource = $injector.get("Syllabus");
          var action = TargetResource["::updateById::Project::syllabuses"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project#organization
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Fetches belongsTo relation organization.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Organization` object.)
             * </em>
             */
        R.organization = function() {
          var TargetResource = $injector.get("Organization");
          var action = TargetResource["::get::Project::organization"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Project.files
     * @header lbServices.Project.files
     * @object
     * @description
     *
     * The object `Project.files` groups methods
     * manipulating `File` instances related to `Project`.
     *
     * Call {@link lbServices.Project#files Project.files()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Project#files
             * @methodOf lbServices.Project
             *
             * @description
             *
             * Queries files of Project.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.files = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::get::Project::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.files#count
             * @methodOf lbServices.Project.files
             *
             * @description
             *
             * Counts files of Project.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.files.count = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::count::Project::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.files#create
             * @methodOf lbServices.Project.files
             *
             * @description
             *
             * Creates a new instance in files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.files.create = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::create::Project::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.files#createMany
             * @methodOf lbServices.Project.files
             *
             * @description
             *
             * Creates a new instance in files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.files.createMany = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::createMany::Project::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.files#destroyAll
             * @methodOf lbServices.Project.files
             *
             * @description
             *
             * Deletes all files of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.destroyAll = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::delete::Project::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.files#destroyById
             * @methodOf lbServices.Project.files
             *
             * @description
             *
             * Delete a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.files.destroyById = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::destroyById::Project::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.files#findById
             * @methodOf lbServices.Project.files
             *
             * @description
             *
             * Find a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.files.findById = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::findById::Project::files"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Project.files#updateById
             * @methodOf lbServices.Project.files
             *
             * @description
             *
             * Update a related item by id for files.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `fk` – `{*}` - Foreign key for files
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R.files.updateById = function() {
          var TargetResource = $injector.get("File");
          var action = TargetResource["::updateById::Project::files"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.File
 * @header lbServices.File
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `File` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "File",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Files/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use File.project() instead.
            "prototype$__get__project": {
              url: urlBase + "/Files/:id/project",
              method: "GET",
            },

            // INTERNAL. Use File.organization() instead.
            "prototype$__get__organization": {
              url: urlBase + "/Files/:id/organization",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#create
             * @methodOf lbServices.File
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Files",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#createMany
             * @methodOf lbServices.File
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Files",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#upsert
             * @methodOf lbServices.File
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Files",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#replaceOrCreate
             * @methodOf lbServices.File
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Files/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#upsertWithWhere
             * @methodOf lbServices.File
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Files/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#exists
             * @methodOf lbServices.File
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Files/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#findById
             * @methodOf lbServices.File
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Files/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#replaceById
             * @methodOf lbServices.File
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Files/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#find
             * @methodOf lbServices.File
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Files",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#findOne
             * @methodOf lbServices.File
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Files/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#updateAll
             * @methodOf lbServices.File
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Files/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#deleteById
             * @methodOf lbServices.File
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Files/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#count
             * @methodOf lbServices.File
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Files/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#prototype$updateAttributes
             * @methodOf lbServices.File
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Files/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#createChangeStream
             * @methodOf lbServices.File
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Files/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#download
             * @methodOf lbServices.File
             *
             * @description
             *
             * Download file
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `res` – `{object=}` -
             *
             *  - `id` – `{string}` -
             *
             *  - `type` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "download": {
              url: urlBase + "/Files/:id/download",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#getFiles
             * @methodOf lbServices.File
             *
             * @description
             *
             * Get files of a specific type : e.g subfolder/files
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` -
             *
             *  - `componentModel` – `{string}` - Search files by type with regular expressions , e.g. ^creo- 
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
            "getFiles": {
              isArray: true,
              url: urlBase + "/Files/:componentModel/files",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#uploadFile
             * @methodOf lbServices.File
             *
             * @description
             *
             * Uploads a file : e.g subfolder/uploadFile
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `componentModel` – `{string}` -
             *
             *  - `meta` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
            "uploadFile": {
              url: urlBase + "/Files/:componentModel/uploadFile",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.File#updateFile
             * @methodOf lbServices.File
             *
             * @description
             *
             * Updates a file : e.g subfolder/updateFile/:FileId1
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `componentModel` – `{string}` -
             *
             *  - `fileId` – `{string}` -
             *
             *  - `meta` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
            "updateFile": {
              url: urlBase + "/Files/:componentModel/updateFile/:fileId",
              method: "POST",
            },

            // INTERNAL. Use Organization.files.findById() instead.
            "::findById::Organization::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/files/:fk",
              method: "GET",
            },

            // INTERNAL. Use Organization.files.destroyById() instead.
            "::destroyById::Organization::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/files/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Organization.files.updateById() instead.
            "::updateById::Organization::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Organizations/:id/files/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Organization.files() instead.
            "::get::Organization::files": {
              isArray: true,
              url: urlBase + "/Organizations/:id/files",
              method: "GET",
            },

            // INTERNAL. Use Organization.files.create() instead.
            "::create::Organization::files": {
              url: urlBase + "/Organizations/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Organization.files.createMany() instead.
            "::createMany::Organization::files": {
              isArray: true,
              url: urlBase + "/Organizations/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Organization.files.destroyAll() instead.
            "::delete::Organization::files": {
              url: urlBase + "/Organizations/:id/files",
              method: "DELETE",
            },

            // INTERNAL. Use Organization.files.count() instead.
            "::count::Organization::files": {
              url: urlBase + "/Organizations/:id/files/count",
              method: "GET",
            },

            // INTERNAL. Use Exam.file() instead.
            "::get::Exam::file": {
              url: urlBase + "/Exams/:id/file",
              method: "GET",
            },

            // INTERNAL. Use Exam.file.create() instead.
            "::create::Exam::file": {
              url: urlBase + "/Exams/:id/file",
              method: "POST",
            },

            // INTERNAL. Use Exam.file.createMany() instead.
            "::createMany::Exam::file": {
              isArray: true,
              url: urlBase + "/Exams/:id/file",
              method: "POST",
            },

            // INTERNAL. Use Exam.file.update() instead.
            "::update::Exam::file": {
              url: urlBase + "/Exams/:id/file",
              method: "PUT",
            },

            // INTERNAL. Use Exam.file.destroy() instead.
            "::destroy::Exam::file": {
              url: urlBase + "/Exams/:id/file",
              method: "DELETE",
            },

            // INTERNAL. Use Lecture.file() instead.
            "::get::Lecture::file": {
              url: urlBase + "/Lectures/:id/file",
              method: "GET",
            },

            // INTERNAL. Use Lecture.file.create() instead.
            "::create::Lecture::file": {
              url: urlBase + "/Lectures/:id/file",
              method: "POST",
            },

            // INTERNAL. Use Lecture.file.createMany() instead.
            "::createMany::Lecture::file": {
              isArray: true,
              url: urlBase + "/Lectures/:id/file",
              method: "POST",
            },

            // INTERNAL. Use Lecture.file.update() instead.
            "::update::Lecture::file": {
              url: urlBase + "/Lectures/:id/file",
              method: "PUT",
            },

            // INTERNAL. Use Lecture.file.destroy() instead.
            "::destroy::Lecture::file": {
              url: urlBase + "/Lectures/:id/file",
              method: "DELETE",
            },

            // INTERNAL. Use LectureProposal.file() instead.
            "::get::LectureProposal::file": {
              url: urlBase + "/LectureProposals/:id/file",
              method: "GET",
            },

            // INTERNAL. Use LectureProposal.file.create() instead.
            "::create::LectureProposal::file": {
              url: urlBase + "/LectureProposals/:id/file",
              method: "POST",
            },

            // INTERNAL. Use LectureProposal.file.createMany() instead.
            "::createMany::LectureProposal::file": {
              isArray: true,
              url: urlBase + "/LectureProposals/:id/file",
              method: "POST",
            },

            // INTERNAL. Use LectureProposal.file.update() instead.
            "::update::LectureProposal::file": {
              url: urlBase + "/LectureProposals/:id/file",
              method: "PUT",
            },

            // INTERNAL. Use LectureProposal.file.destroy() instead.
            "::destroy::LectureProposal::file": {
              url: urlBase + "/LectureProposals/:id/file",
              method: "DELETE",
            },

            // INTERNAL. Use Syllabus.file() instead.
            "::get::Syllabus::file": {
              url: urlBase + "/syllabuses/:id/file",
              method: "GET",
            },

            // INTERNAL. Use Syllabus.file.create() instead.
            "::create::Syllabus::file": {
              url: urlBase + "/syllabuses/:id/file",
              method: "POST",
            },

            // INTERNAL. Use Syllabus.file.createMany() instead.
            "::createMany::Syllabus::file": {
              isArray: true,
              url: urlBase + "/syllabuses/:id/file",
              method: "POST",
            },

            // INTERNAL. Use Syllabus.file.update() instead.
            "::update::Syllabus::file": {
              url: urlBase + "/syllabuses/:id/file",
              method: "PUT",
            },

            // INTERNAL. Use Syllabus.file.destroy() instead.
            "::destroy::Syllabus::file": {
              url: urlBase + "/syllabuses/:id/file",
              method: "DELETE",
            },

            // INTERNAL. Use Assessment.file() instead.
            "::get::Assessment::file": {
              url: urlBase + "/Assessments/:id/file",
              method: "GET",
            },

            // INTERNAL. Use Assessment.file.create() instead.
            "::create::Assessment::file": {
              url: urlBase + "/Assessments/:id/file",
              method: "POST",
            },

            // INTERNAL. Use Assessment.file.createMany() instead.
            "::createMany::Assessment::file": {
              isArray: true,
              url: urlBase + "/Assessments/:id/file",
              method: "POST",
            },

            // INTERNAL. Use Assessment.file.update() instead.
            "::update::Assessment::file": {
              url: urlBase + "/Assessments/:id/file",
              method: "PUT",
            },

            // INTERNAL. Use Assessment.file.destroy() instead.
            "::destroy::Assessment::file": {
              url: urlBase + "/Assessments/:id/file",
              method: "DELETE",
            },

            // INTERNAL. Use Project.files.findById() instead.
            "::findById::Project::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/files/:fk",
              method: "GET",
            },

            // INTERNAL. Use Project.files.destroyById() instead.
            "::destroyById::Project::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/files/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Project.files.updateById() instead.
            "::updateById::Project::files": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Projects/:id/files/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Project.files() instead.
            "::get::Project::files": {
              isArray: true,
              url: urlBase + "/Projects/:id/files",
              method: "GET",
            },

            // INTERNAL. Use Project.files.create() instead.
            "::create::Project::files": {
              url: urlBase + "/Projects/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Project.files.createMany() instead.
            "::createMany::Project::files": {
              isArray: true,
              url: urlBase + "/Projects/:id/files",
              method: "POST",
            },

            // INTERNAL. Use Project.files.destroyAll() instead.
            "::delete::Project::files": {
              url: urlBase + "/Projects/:id/files",
              method: "DELETE",
            },

            // INTERNAL. Use Project.files.count() instead.
            "::count::Project::files": {
              url: urlBase + "/Projects/:id/files/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.File#patchOrCreate
             * @methodOf lbServices.File
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.File#updateOrCreate
             * @methodOf lbServices.File
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.File#patchOrCreateWithWhere
             * @methodOf lbServices.File
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.File#update
             * @methodOf lbServices.File
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.File#destroyById
             * @methodOf lbServices.File
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.File#removeById
             * @methodOf lbServices.File
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.File#patchAttributes
             * @methodOf lbServices.File
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `File` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.File#modelName
        * @propertyOf lbServices.File
        * @description
        * The name of the model represented by this $resource,
        * i.e. `File`.
        */
        R.modelName = "File";


            /**
             * @ngdoc method
             * @name lbServices.File#project
             * @methodOf lbServices.File
             *
             * @description
             *
             * Fetches belongsTo relation project.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Project` object.)
             * </em>
             */
        R.project = function() {
          var TargetResource = $injector.get("Project");
          var action = TargetResource["::get::File::project"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.File#organization
             * @methodOf lbServices.File
             *
             * @description
             *
             * Fetches belongsTo relation organization.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Organization` object.)
             * </em>
             */
        R.organization = function() {
          var TargetResource = $injector.get("Organization");
          var action = TargetResource["::get::File::organization"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Queue
 * @header lbServices.Queue
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Queue` model.
 *
 * **Details**
 *
 * Provide access to data about the queues and the rabbitmq server
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Queue",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Queue/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Queue#status
             * @methodOf lbServices.Queue
             *
             * @description
             *
             * Get an status overview of the connected rabbitmq server
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Queue` object.)
             * </em>
             */
            "status": {
              url: urlBase + "/Queue/status",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Queue#queues
             * @methodOf lbServices.Queue
             *
             * @description
             *
             * Get queues of connected rabbitmq server
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Queue` object.)
             * </em>
             */
            "queues": {
              url: urlBase + "/Queue/queues",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.Queue#modelName
        * @propertyOf lbServices.Queue
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Queue`.
        */
        R.modelName = "Queue";



        return R;
      }]);


  module
  .factory('LoopBackAuth', function() {
    var props = ['accessTokenId', 'currentUserId', 'rememberMe'];
    var propsPrefix = '$LoopBack$';

    function LoopBackAuth() {
      var self = this;
      props.forEach(function(name) {
        self[name] = load(name);
      });
      this.currentUserData = null;
    }

    LoopBackAuth.prototype.save = function() {
      var self = this;
      var storage = this.rememberMe ? localStorage : sessionStorage;
      props.forEach(function(name) {
        save(storage, name, self[name]);
      });
    };

    LoopBackAuth.prototype.setUser = function(accessTokenId, userId, userData) {
      this.accessTokenId = accessTokenId;
      this.currentUserId = userId;
      this.currentUserData = userData;
    };

    LoopBackAuth.prototype.clearUser = function() {
      this.accessTokenId = null;
      this.currentUserId = null;
      this.currentUserData = null;
    };

    LoopBackAuth.prototype.clearStorage = function() {
      props.forEach(function(name) {
        save(sessionStorage, name, null);
        save(localStorage, name, null);
      });
    };

    return new LoopBackAuth();

    // Note: LocalStorage converts the value to string
    // We are using empty string as a marker for null/undefined values.
    function save(storage, name, value) {
      try {
        var key = propsPrefix + name;
        if (value == null) value = '';
        storage[key] = value;
      } catch (err) {
        console.log('Cannot access local/session storage:', err);
      }
    }

    function load(name) {
      var key = propsPrefix + name;
      return localStorage[key] || sessionStorage[key] || null;
    }
  })
  .config(['$httpProvider', function($httpProvider) {
    $httpProvider.interceptors.push('LoopBackAuthRequestInterceptor');
  }])
  .factory('LoopBackAuthRequestInterceptor', ['$q', 'LoopBackAuth',
    function($q, LoopBackAuth) {
      return {
        'request': function(config) {
          // filter out external requests
          var host = getHost(config.url);
          if (host && host !== urlBaseHost) {
            return config;
          }

          if (LoopBackAuth.accessTokenId) {
            config.headers[authHeader] = LoopBackAuth.accessTokenId;
          } else if (config.__isGetCurrentUser__) {
            // Return a stub 401 error for User.getCurrent() when
            // there is no user logged in
            var res = {
              body: { error: { status: 401 }},
              status: 401,
              config: config,
              headers: function() { return undefined; },
            };
            return $q.reject(res);
          }
          return config || $q.when(config);
        },
      };
    }])

  /**
   * @ngdoc object
   * @name lbServices.LoopBackResourceProvider
   * @header lbServices.LoopBackResourceProvider
   * @description
   * Use `LoopBackResourceProvider` to change the global configuration
   * settings used by all models. Note that the provider is available
   * to Configuration Blocks only, see
   * {@link https://docs.angularjs.org/guide/module#module-loading-dependencies Module Loading & Dependencies}
   * for more details.
   *
   * ## Example
   *
   * ```js
   * angular.module('app')
   *  .config(function(LoopBackResourceProvider) {
   *     LoopBackResourceProvider.setAuthHeader('X-Access-Token');
   *  });
   * ```
   */
  .provider('LoopBackResource', function LoopBackResourceProvider() {
    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#setAuthHeader
     * @methodOf lbServices.LoopBackResourceProvider
     * @param {string} header The header name to use, e.g. `X-Access-Token`
     * @description
     * Configure the REST transport to use a different header for sending
     * the authentication token. It is sent in the `Authorization` header
     * by default.
     */
    this.setAuthHeader = function(header) {
      authHeader = header;
    };

    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#getAuthHeader
     * @methodOf lbServices.LoopBackResourceProvider
     * @description
     * Get the header name that is used for sending the authentication token.
     */
    this.getAuthHeader = function() {
      return authHeader;
    };

    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#setUrlBase
     * @methodOf lbServices.LoopBackResourceProvider
     * @param {string} url The URL to use, e.g. `/api` or `//example.com/api`.
     * @description
     * Change the URL of the REST API server. By default, the URL provided
     * to the code generator (`lb-ng` or `grunt-loopback-sdk-angular`) is used.
     */
    this.setUrlBase = function(url) {
      urlBase = url;
      urlBaseHost = getHost(urlBase) || location.host;
    };

    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#getUrlBase
     * @methodOf lbServices.LoopBackResourceProvider
     * @description
     * Get the URL of the REST API server. The URL provided
     * to the code generator (`lb-ng` or `grunt-loopback-sdk-angular`) is used.
     */
    this.getUrlBase = function() {
      return urlBase;
    };

    this.$get = ['$resource', function($resource) {
      var LoopBackResource = function(url, params, actions) {
        var resource = $resource(url, params, actions);

        // Angular always calls POST on $save()
        // This hack is based on
        // http://kirkbushell.me/angular-js-using-ng-resource-in-a-more-restful-manner/
        resource.prototype.$save = function(success, error) {
          // Fortunately, LoopBack provides a convenient `upsert` method
          // that exactly fits our needs.
          var result = resource.upsert.call(this, {}, this, success, error);
          return result.$promise || result;
        };
        return resource;
      };

      LoopBackResource.getUrlBase = function() {
        return urlBase;
      };

      LoopBackResource.getAuthHeader = function() {
        return authHeader;
      };

      return LoopBackResource;
    }];
  });
})(window, window.angular);
