(function() {
  'use strict';
  angular
    .module('com.module.customstyles')
    .controller('CustomStylesSingleController', function(previewComponent,$timeout, $state, CustomStylesService, customstyle,CoreService) {
      var vm = this;
      vm.customstyle = customstyle;
      vm.customstyle.data = vm.customstyle.data || {};
      if(vm.customstyle.data.style && !vm.customstyle.data.preview) {
        vm.customstyle.data.preview = vm.customstyle.data.style;
      }
      vm.formOptions = {};
      vm.customstyleData = vm.customstyle.data;
      vm.getPreviewUrl = function(){
        return CoreService.env.sylvaHost + previewComponent +
        '?customStyle=custom.style.'+vm.customstyle.data.label.replace(/ /g,'_').toLowerCase();
      }
      vm.updatePreview = function() {
        vm.customstyle.key = 'custom.style.' + vm.customstyle.data.label.replace(/ /g,'_').toLowerCase();
        CustomStylesService.upsert(vm.customstyle)
        .then(function(){
          window.open(vm.getPreviewUrl(), '_blank');
        })
        .catch(function(err){
          console.error(err);
        })
      };
      vm.submit = function() {
        vm.customstyle.key = 'custom.style.' + vm.customstyle.data.label.replace(/ /g,'_').toLowerCase();
        vm.customstyle.data.style = vm.customstyle.data.preview;
        vm.customstyle.data.preview = null;
        CustomStylesService.upsert(vm.customstyle).then(function() {
          $state.go('^.list');
        });
      };
      vm.formFields = [{
          className: "col-xs-7",
          key: 'data.label',
          type: 'input',
          templateOptions: {
            label: 'Label',
            required: true
          }
        },
      ]



    });

})();
