(function () {
  'use strict';
  angular
    .module('com.module.customstyles')
    .service('CustomStylesService', function ($state, CoreService, Setting, gettextCatalog,$http,LoopBackAuth) {
      var vm = this;
      vm.customstyles = [];
      vm.find = function () {
        return $http.get(CoreService.env.apiUrl+'/Settings/key/custom.style');
      };
      
      vm.key = function (key) {
        for(var i=0,len=vm.customstyles.length;i<len;i++){
          if(vm.customstyles[i].key===key) return vm.customstyles[i];
        }
        return null;
      };

      vm.findById = function (id) {
        return Setting.findById({
          id: id
        }).$promise;
      };
      
      vm.findByKey = function (key) {
        return Setting.find({
          filter:{
            where:{
              key: key
            }
          }
        }).$promise.then(function(res){
          return res[0];
        });
        
      }

      vm.upsert = function (customstyle) {
        return Setting.upsert(customstyle).$promise
          .then(function () {
            CoreService.toastSuccess(
              gettextCatalog.getString('Setting saved'),
              gettextCatalog.getString('Your custom style is safe with us!')
            );
          })
          .catch(function (err) {
            CoreService.toastError(
              gettextCatalog.getString('Error saving custom style '),
              gettextCatalog.getString('This custom style could no be saved: ' + err)
            );
          }
        );
      };

      vm.delete = function (id, successCb, cancelCb) {
        CoreService.confirm(
          gettextCatalog.getString('Are you sure?'),
          gettextCatalog.getString('Deleting this cannot be undone'),
          function () {
            Setting.deleteById({id: id}, function () {
              CoreService.toastSuccess(
                gettextCatalog.getString('Setting deleted'),
                gettextCatalog.getString('Your custom style is deleted!'));
              successCb();
            }, function (err) {
              CoreService.toastError(
                gettextCatalog.getString('Error deleting custom style'),
                gettextCatalog.getString('Your custom style is not deleted! ') + err);
              cancelCb();
            });
          },
          function () {
            cancelCb();
          }
        );
      };
      

      return vm;

    });

})();
