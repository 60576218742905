'use strict';

angular.module('com.module.projects')
  .service('ProjectUsers', function(Project,CoreService,CONFIG,$http,TeamService,$q) {
    var vm = this;
    vm.projectId = null;
    vm.ProjectRoles = CONFIG.ProjectRoles;
    vm.load = function(userId,projectId) {
      projectId = projectId || vm.projectId;
      var filters = {"include": {systemUser:"teams"}};
      if(userId) filters.where = {systemuserId:userId};
      return $http.get(CoreService.env.apiUrl+'/Projects/'+projectId+'/access',{
        params:{
          filter: filters
        }
      }).then(function(res){
        return res.data
        // filter teams from other projects
        .map(function(a){
          if(a.systemUser && a.systemUser.teams) {
            a.systemUser.teams = a.systemUser.teams.filter(function(team){
              return team.projectId===projectId;
            })
          }
          return a;
        });
      })
      .catch(CoreService.error);
    };
    
    vm.searchUsers = function(orgId,filters){
      return $http.get(CoreService.env.apiUrl+'/Organizations/'+orgId+'/systemUsers',{
        cache:true,
        params: {
            filter: filters
        }
      });
    }
    
    
    vm.insert = function(obj,user) {
      
      var rolesArray = obj.map(function(o){
        return {email:user.email, role:o.role}
      })
      console.log("Insert roles",rolesArray);
      if(rolesArray.length>0) return vm.inviteBulk(rolesArray);
      else return rolesArray;
      // return $http.post(CoreService.env.apiUrl+'/Projects/'+vm.projectId+'/access',insertObj)
      // .then(CoreService.success)
      // .catch(CoreService.error);
    };
    
    vm.update = function(obj) {
      console.log("Update roles",obj)
      var updateObj = {
        role: obj.role,
        systemuserId: obj.systemuserId
      };
      return $http.put(CoreService.env.apiUrl+'/Projects/'+vm.projectId+'/access/'+obj.id,updateObj)
      .then(CoreService.success)
      .catch(CoreService.error);
    };
    
    vm.delete = function(obj) {
        console.log(obj);
        if(!obj.id || !obj.projectId) return Promise.reject('No access id or no project id');
        return $http.delete(CoreService.env.apiUrl+'/Projects/'+obj.projectId+'/access/'+obj.id)
        
        // remove him from teams
        // .then(function(){
        //   return $http.get(CoreService.env.apiUrl+'/SystemUsers/'+obj.systemuserId+'/teams',{where:{projectId:obj.projectId}});
        // })
        // .then(function(res){
        //   var teams = res.data;
        //   var p=[];
        //   if(teams.length>0) {
        //     teams.forEach(function(t){
        //       p.push(TeamService.unlinkUser(obj.systemuserId,t.id));
        //     });
        //   }
        //   return $q.all(p);
          
        // })
        
        .then(CoreService.success)
        .catch(CoreService.error);
    };
    
    vm.inviteBulk = function(rolesArray,projectId) {
      projectId = projectId || vm.projectId;
      return $http.post(CoreService.env.apiUrl+'/Projects/'+projectId+'/inviteUsers',{users:rolesArray})
      .then(CoreService.success)
      .catch(CoreService.error);
    };
    
    vm.invite = function(users) {
      var rolesArray = [];
      users.forEach(function(user){
        user.roles.forEach(function(role){
          rolesArray.push({email:user.email,role:role})
        })
        
      });
      return vm.inviteBulk(rolesArray);
    };
    
    /* helpers */
    vm.groupRoles = function(res) {
      var resultArray = [];
      if(!res || res.length===0) return resultArray;
      var hash = res.reduce(function(a, b) {
        var roleObj = {
          projectId:b.projectId,
          systemuserId:b.systemuserId,
          id:b.id,
          role:b.role
        };
        
        if(!b.systemUser) {
          console.warn("Bug in projectAccess data: User doesnt exist", b);
          return a;
        }
        
        if (a[b.systemUser.email]) {
          // obj exists
          a[b.systemUser.email].roles.push(roleObj);
        }
        else {
          // new obj
          a[b.systemUser.email] = {
            systemuserId: b.systemuserId,
            email: b.systemUser.email,
            firstName: b.systemUser.firstName,
            lastName: b.systemUser.lastName,
            teams: b.systemUser.teams?b.systemUser.teams.map(function(t){return t.name}).join(","):"",
          };
          a[b.systemUser.email].roles = [roleObj]; //make it an array
        }
        return a;
      }, {});
      for (var item in hash) {
        hash[item].roles_label = hash[item].roles.map(function(roleObj){
          return vm.getRoleLabel(roleObj.role);}).join(',');
        resultArray.push(hash[item]);
      }
      return resultArray;
    }
    
    vm.getRoleLabel = function(search) {
      var label = vm.ProjectRoles.filter(function(role){ return search===role.map}).map(function(role){return role.label});
      if(label.length>0) return label;
      return search;
    }

  });
