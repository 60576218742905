'use strict';

angular.module('com.module.projects')
  .service('CaseStudyService', function($http,CONFIG,CoreService,SchemaMaker) {
    var vm = this;
    vm.project = {};
    vm.setProject=function(project){vm.project=project;};    
    vm.filters = {limit:25,skip:0}
    
    
    /* CaseStudies Configuration */;
    
    // basic endpoint
    vm.endpoint = function(){ 
        return CoreService.env.apiUrl 
                +'/Projects/'+vm.project.id+'/casestudies';
    };
    // basic schema
    vm.schema = {
        "name": {
            "type": "string"
        },
        // "status": {
        //     "type": "string"
        // },
        "teamAccess.name": {
          "displayName": "Connected Team", 
          "type": "relation"
        },
        "createdAt": {
          "type": "date"
        }
        // "order": {
        //     "type": "number"
        // }
    };
    
    // create schema for angular-formly
    vm.getFormSchema = function () {
        return [
          {
            key: 'name',
            type: 'input',
            templateOptions: {
              label: 'Name',
              required: true
            }
          },
          {
            key: 'description',
            type: 'textarea',
            templateOptions: {
              label: 'Teaser Description',
            }
          },
          {
            key: 'description_long',
            type: 'textarea',
            templateOptions: {
              label: 'Full Description',
            }
          },
          {
            key: 'startDate',
            type: 'datetimepicker',
            templateOptions: {
              label: 'Start accessible date',
            }
          },
          {
            key: 'endDate',
            type: 'datetimepicker',
            templateOptions: {
              label: 'End accessible date',
            }
          },
          {
            key: 'publicationStatus',
            type: 'input',
            templateOptions: {
              label: 'Status',
            }
          },
          {
            key: 'organizationId',
            type: 'select',
            templateOptions: {
              label: 'Organization',
              options: [],
              valueProp: 'id',
              labelProp: 'name',
              required: true,
              placeholder:'Select Organization'
            },
            controller: /* @ngInject */ function($scope, OrganizationService) {
              OrganizationService.get().then(function(data){
                $scope.to.options = data;
                // note, the line above is shorthand for:
                // $scope.options.templateOptions.options = data;
                return data;
              });
            }
          }
        ];    
    };
    
    // auto create schema for ui-grid;
    vm.gridSchema = SchemaMaker.uiGrid(vm.schema,vm.filters);
    vm.saveOrder = function(casestudyIds){
      return $http.post(CoreService.env.apiUrl  + '/Projects/'+vm.project.id+'/casestudyOrder/'+casestudyIds.join(','));
    };    
    
    /* Teams */
    vm.searchTeams = function(filters){
      return $http.get(CoreService.env.apiUrl  + '/Projects/'+vm.project.id+'/teams/',{
        cache:true,
        params: {
            filter: filters
        }
      });
    }
    vm.getTeam = function(casestudyId){
      return $http.get(CoreService.env.apiUrl  + '/CaseStudies/'+casestudyId+'/teamAccess');
    };    
    vm.linkTeam = function(casestudyId,teamId){
      return $http.put(CoreService.env.apiUrl  + '/CaseStudies/'+casestudyId+'/teamAccess/rel/'+teamId,{
        componentId:casestudyId,
        projectteamId:teamId
      });
    };    
    vm.unlinkTeam = function(casestudyId,teamId){
      return $http.delete(CoreService.env.apiUrl  + '/ProjectTeams/'+teamId+'/components/rel/'+casestudyId);
    };
    
    
    /* CaseStudies Tokens for abma */
    
    vm.getTokens = function(caseId){
      return $http.get(CoreService.env.apiUrl  + '/CaseStudies/'+caseId+'/tokens');
    };
    vm.createToken = function(caseId,data){
      data.projectId = vm.project.id;
      return $http.post(CoreService.env.apiUrl  + '/CaseStudies/'+caseId+'/tokens',data);
    };
    vm.deleteToken = function(caseId,tokenId){
      return $http.delete(CoreService.env.apiUrl  + '/CaseStudies/'+caseId+'/tokens/'+tokenId);
    };
    
    
    
    /* CRUD methods */
    vm.get = function(filters){
      filters = filters || {};
      filters = Object.assign({},filters,vm.filters);
      return $http.get(vm.endpoint(),{
          params: {
              filter: filters
          }
      });
    };
    vm.save = function(item){
      if(item.id) {
        
        for (var prop in item.images){
          if (item.images[prop] && item.images[prop].id) item.images[prop] = item.images[prop].id;
        }
        
        return $http.put(vm.endpoint()+'/'+item.id,item);
      }
      else return $http.post(vm.endpoint(),item);
    };   
    vm.delete = function(item){
      return $http.delete(vm.endpoint()+'/'+item.id);
    };
    
      
    return vm;
  });
