'use strict';

angular.module('com.module.projects')
  .service('SyllabusService', function($http, CONFIG, CoreService, SchemaMaker,LectureService) {
    var vm = this;
    vm.project = {};
    vm.setProject = function(project) { vm.project = project; };
    vm.filters = { limit: 25, skip: 0 }

    /* Syllabus Configuration */

    // basic endpoint
    vm.endpoint = function() {
      return CoreService.env.apiUrl +
        '/Projects/' + vm.project.id + '/syllabus';
    };

    // create schema for angular-formly
    vm.getFormSchema = function() {
      return [{
          "className": "row",
          "fieldGroup": [{
              className: "col-sm-5",
              key: 'name',
              type: 'input',
              templateOptions: {
                label: 'Name',
                required: true
              }
            },
            {
              className: "col-sm-3",
              key: 'moduleNumber',
              type: 'input',
              templateOptions: {
                label: 'Module number',
              }
            },
            {
              className: "col-sm-4",
              key: 'website',
              type: 'input',
              templateOptions: {
                label: 'Website link',
              }
            }
          ]
        },
        {
          "className": "row",
          "fieldGroup": [{
            className: "col-sm-12",
            key: 'description',
            type: 'textarea',
            templateOptions: {
              label: 'Description',
            }
          }]
        },
        {
          "className": "row",
          "fieldGroup": [{
              className: "col-sm-4",
              key: 'teachingStaff',
              type: 'multiInput',
              templateOptions: {
                label: 'Teaching Staff',
                inputOptions: {
                  type: 'input'
                }
              }
            },
            {
              className: "col-sm-4",
              key: 'feedbackEmail',
              type: 'multiInput',
              templateOptions: {
                label: 'Email destination for feedback forms',
                inputOptions: {
                  type: 'input'
                }
              }
            },
            {
              className: "col-sm-4",
              key: 'officeHours',
              type: 'multiInput',
              templateOptions: {
                label: 'Office hours',
                inputOptions: {
                  type: 'input'
                }
              }
            }
          ]
        },


        {
          "className": "row",
          "fieldGroup": [{
              className: "col-sm-4",
              key: 'learningObjectives',
              type: 'multiInput',
              templateOptions: {
                label: 'Learning Objectives',
                inputOptions: {
                  type: 'input'
                }
              }
            },
            {
              className: "col-sm-4",
              key: 'assessment',
              type: 'multiInput',
              templateOptions: {
                label: 'Assessment',
                inputOptions: {
                  type: 'input'
                }
              }
            },
            {
              className: "col-sm-4",
              key: 'requirements',
              type: 'multiInput',
              templateOptions: {
                label: 'Requirements',
                inputOptions: {
                  type: 'input'
                }
              }
            }
          ]
        },
        {
          "className": "row",
          "fieldGroup": [{
            className: "col-xs-12",
            key: 'schedule',
            type: 'repeatSection',
            "templateOptions": {
              "label": "Schedule",
              "btnText": "Add schedule",
              "fields": [
                {
                  "className": "row",
                  "fieldGroup": [
                  {
                    className: "col-xs-4",
                    key: 'title',
                    type: 'select',
                    templateOptions: {
                      label: 'Lecture',
                      options: [],
                      valueProp: 'id',
                      labelProp: 'name',
                      required: false,
                      placeholder: 'Select Lecture'
                    },
                    controller: /* @ngInject */ function($scope, LectureService) {
                      $scope.to.options = [];
                      // init config
                      LectureService.setProject(vm.project);
                      LectureService.get().then(function(res) {
                        $scope.to.options = res.data.map(function(s) {
                          return { id: s.id, name: s.name };
                        });
                      });
                    }
                  },                  
                  {
                    className: "col-xs-3",
                    key: 'lecturer',
                    type: 'select',
                    templateOptions: {
                      label: 'Lecturer',
                      options: [],
                      valueProp: 'id',
                      labelProp: 'name',
                      required: false,
                      placeholder: 'Select Lecturer'
                    },
                    controller: /* @ngInject */ function($scope, LectureService) {
                      $scope.to.options = [];
                      // init config
                      $http.get(CoreService.env.apiUrl+'/Projects/'+vm.project.id+'/access',{
                        params:{
                          filter: {
                            where: {
                              role:'lecturer'
                            },
                            include:'systemUser'
                          }
                        }
                      }).then(function(res) {
                        $scope.to.options = res.data.map(function(s) {
                          return { id: s.systemUser.id, name: s.systemUser.firstName +' '+ s.systemUser.lastName };
                        });
                        if(res.data.length===1) {
                          $scope.model.lecturer = res.data[0].systemUser.id
                        }
                      });
                    }
                  },
                  {
                    "key": "sessionType",
                    "className": "col-xs-2",
                    type: 'select',
                    templateOptions: {
                      label: 'Session Type',
                      options: [
                        {
                          id:'kickoff',
                          label:'Kick-off'
                        },
                        {
                          id:'lecture',
                          label:'Lecture'
                        },
                        {
                          id:'practice_session',
                          label:'Practice Session'
                        },
                        {
                          id:'qa',
                          label:'Q&A'
                        },
                        {
                          id:'midterm',
                          label:'Midterm'
                        },
                        {
                          id:'finalexam',
                          label:'Final exam'
                        }
                      ],
                      valueProp: 'id',
                      labelProp: 'label',
                      required: true,
                      placeholder: 'Select session type'
                    }
                  },                 
                    {
                      "key": "room",
                      "type": "input",
                      "className": "col-xs-3",
                      "templateOptions": {
                        "label": "Room",
                      }
                    }, 
                    {
                      "key": "roomLink",
                      "type": "input",
                      "className": "col-xs-6",
                      "templateOptions": {
                        "label": "Room link (www)",
                      }
                    },
                   {
                      "key": "startDate",
                      "type": "datetimepicker",
                      "className": "col-xs-3",
                      "templateOptions": {
                        "label": "Start Date",
                        "placeholder": "Date..."
                      }
                    },                
                    {
                      "key": "endDate",
                      "type": "datetimepicker",
                      "className": "col-xs-3",
                      "templateOptions": {
                        "label": "End Date",
                        "placeholder": "Date..."
                      }
                    }
                  ]
                }
              ]
            }
            
          }]
        },
        {
          className: "col-sm-4",
          key: 'gradingScheme',
          type: 'select',
          templateOptions: {
            label: 'Grading Scheme',
            options: [],
            valueProp: 'id',
            labelProp: 'label',
            required: true,
            placeholder: 'Select grading scheme'
          },
          controller: /* @ngInject */ function($scope, GradingSchemeService) {
            $scope.to.options = [];
            GradingSchemeService.find().then(function(res) {
              console.log("GradingScheme", res.data);
              $scope.to.options = res.data.map(function(s) {
                return { id: s.key, label: s.data.label };
              });
            });
          }
        },
        {
          className: "col-sm-8",
          key: 'gradingPolicy',
          type: 'textarea',
          templateOptions: {
            label: 'Grading Policy',
          }
        }
      ]
    }

    // {
    //   key: 'order',
    //   type: 'input',
    //   templateOptions: {
    //     label: 'Order',
    //   }
    // }


    /* CRUD methods */
    vm.get = function(filters) {
      filters = filters || {};
      filters = Object.assign({}, filters, vm.filters);
      return $http.get(vm.endpoint(), {
        params: {
          filter: filters
        }
      });
    };
    vm.save = function(item) {
      if (item.id) return $http.put(vm.endpoint(), item);
      else return $http.post(vm.endpoint(), item);
    };

    return vm;
  });
