(function () {
  'use strict';
  
  angular
    .module('com.module.core')
    .directive('relationViewer',function($uibModal) {
      return {
        templateUrl: 'app/modules/core/directives/relation-viewer/ui-grid.cell.template.html',
        scope: {
          entity: '<',
          model: '@',
          field: '@'
        },
        link:function(scope,elem,attrs){
          var vm = scope;
          init();
          function process(name){return name.substring(name.lastIndexOf('/')+1); }
          function init(){
            var rel = vm.entity[vm.model];
            vm.items = rel?(rel instanceof Array?rel:[rel]):[];
            vm.simpleList = vm.items.map(function(i) { return i.hasOwnProperty(vm.field)?process(i[vm.field]):'Entity not exists'; }).join(',');
          }
          vm.showDetails = function(){
            vm.modal = $uibModal.open({
              templateUrl: 'app/modules/core/directives/relation-viewer/modal.template.html',
              size: 'md',
              scope:vm
            });
          }
          
          // setup watcher
          scope.$watch('entity.id', function(value) {init()});
          
        }
      }
    });  
    
    // angular
    // .module('com.module.core')
    // .component('relationViewer', {
    //     templateUrl: 'app/modules/core/directives/relation-viewer/ui-grid.cell.template.html',
    //     controllerAs:'vm',
    //     bindings: {
    //       entity: '<',
    //       model: '@',
    //       field: '@'
    //     },
    //     controller:function($uibModal){
    //       var vm = this;
    //       vm.$onChanges = init;
    //       function process(name){return name.substring(name.lastIndexOf('/')+1); }
    //       function init(){
    //         var rel = vm.entity[vm.model];
    //         vm.items = rel instanceof Array?rel:[rel];
    //         vm.simpleList = vm.items.map(function(i) { return process(i[vm.field]); }).join(',');
    //         console.log(vm.simpleList);
    //       }
    //       vm.showDetails = function(){
    //         vm.modal = $uibModal.open({
    //           templateUrl: 'app/modules/core/directives/relation-viewer/modal.template.html',
    //           size: 'md',
    //           scope:vm
    //         });
    //       }
          
    //     }
    // });

})();
