(function () {
  'use strict';
  
  angular
    .module('com.module.core')
    .directive('objectViewer', function (CoreService,LoopBackAuth) {
      return {
        restrict: 'E',
        templateUrl:'app/modules/core/directives/object-viewer/template.html',
        scope: {
          item: '<'
        },
        controller:function($scope){
          var vm = $scope;
          vm.props = Object.keys(vm.item);
          vm.isJSON = function(val){ return !!(val instanceof Object);}
        }
      };
    });

})();
