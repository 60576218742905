(function () {
  'use strict';
  
  angular
    .module('com.module.core')
    .directive('fileDownloader', function (CoreService,LoopBackAuth) {
      return {
        restrict: 'E',
        templateUrl:'app/modules/core/directives/file-downloader/template.html',
        scope: {
          file: '<'
        },
        controller:function($scope){
          $scope.$watch('file.id',function(n){          
            $scope.url=CoreService.env.apiUrl+'/Files/'+n+'/download?access_token='+LoopBackAuth.accessTokenId
          })
        }
      };
    });

})();
