  angular
    .module('com.module.core')
    .filter('propsFilter', function() {
        return function(items, props) {
          var out = [];
      
          if (angular.isArray(items)) {
            var keys = Object.keys(props);
      
            items.forEach(function(item) {
              var itemMatches = false;
              var text;
              for (var i = 0; i < keys.length; i++) {
                var prop = keys[i];
                var nested = prop.split('.');
                if(nested.length===1) {
                  text = props[prop].toLowerCase();
                  if (item[prop] && item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                    itemMatches = true;
                    break;
                  }
                } else if(nested.length===2) {
                  text = props[prop].toLowerCase();
                  if (item[nested[0]][nested[1]].toString().toLowerCase().indexOf(text) !== -1) {
                    itemMatches = true;
                    break;
                  }
                } else {
                  console.log("propsFilter: Only 2 levels of nested properties are supported.");
                }
              }
      
              if (itemMatches) {
                out.push(item);
              }
            });
          } else {
            // Let the output be the input untouched
            out = items;
          }
      
          return out;
        };
      })
      .filter('formatByte', function () {
       
        return function (size) {
          var base = 1024;
          return ((size / base ).toFixed(1) + ' KB');
          // auto calculate prefix - nice but ruins sorting
          // var prefixes = ['K','M','G','T','P','E','Z','Y'];
          // var exp = Math.log(size) / Math.log(base) | 0;
          // return (size / Math.pow(base, exp)).toFixed(1) + ' ' +
          //   ((exp > 0) ? prefixes[exp - 1] + 'B' : 'Bytes');
        };
      });
