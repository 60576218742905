'use strict';

angular.module('com.module.projects')
  .service('TeamService', function($http,CONFIG,CoreService,SchemaMaker) {
    var vm = this;
    vm.project = {};
    vm.setProject=function(project){vm.project=project;};    
    vm.filters = {limit:25,skip:0}
    
    
    /* Teams Configuration */;
    
    // basic endpoint
    vm.endpoint = function(){ 
        return CoreService.env.apiUrl 
                +'/Projects/'+vm.project.id+'/teams';
    };
    // basic schema
    vm.schema = {
        "name": {
            "type": "string"
        }
    };
    
    // create schema for angular-formly
    vm.getFormSchema = function () {
        return [
          {
            key: 'name',
            type: 'input',
            templateOptions: {
              label: 'Name',
              required: true
            }
          }
        ];    
    };    
    
    vm.fillTeams = function(){
      return $http.post(CoreService.env.apiUrl+'/Projects/'+vm.project.id+'/partitionStudentsIntoTeams');
    }
    vm.searchUsers = function(filters){
      return $http.get(CoreService.env.apiUrl+'/Projects/'+vm.project.id+'/users',{
        cache:true,
        params: {
            filter: filters
        }
      });
    }
    
    // auto create schema for ui-grid;
    vm.gridSchema = SchemaMaker.uiGrid(vm.schema,vm.filters);
    vm.getMembers = function(teamId) {
      return $http.get(CoreService.env.apiUrl  + '/ProjectTeams/' + teamId+'/members');
    };
    vm.linkUser = function(systemUserId,projectTeamId) {
      return $http.put(CoreService.env.apiUrl  + '/ProjectTeams/'+projectTeamId+'/members/rel/'+systemUserId,{ 
        "role":"member",
        "projectId":vm.project.id
      });
    };
    vm.unlinkUser = function(systemUserId,projectTeamId) {
      return $http.delete(CoreService.env.apiUrl  + '/ProjectTeams/'+projectTeamId+'/members/rel/'+systemUserId);
    };
    
    /* CRUD methods */
    vm.get = function(filters){
      filters = filters || {};
      filters = Object.assign({},filters,vm.filters);
      return $http.get(vm.endpoint(),{
          params: {
              filter: filters
          }
      });
    };
    vm.save = function(item){
      if(item.id) return $http.put(vm.endpoint()+'/'+item.id,item);
      else return $http.post(vm.endpoint(),item);
    };   
    vm.delete = function(item){
      return $http.delete(vm.endpoint()+'/'+item.id);
    };
      
    return vm;
  });
