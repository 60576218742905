'use strict';

angular.module('com.module.organizations')
  .service('OrganizationUsers', function(CoreService,CONFIG,$http,SchemaMaker,ProjectUsers,$q) {
    var vm = this;
    vm.organization = {};
    vm.setOrganization = function(organization){vm.organization = organization;};
    vm.filters = {};
    
    // basic endpoint
    vm.endpoint = function(){ 
        return CoreService.env.apiUrl 
                +'/Organizations/'+vm.organization.id+'/systemUsers';
    };
    // basic schema
    vm.schema = {
      "firstName": {
          "type": "string"
      },
      "lastName": {
          "type": "string"
      },
      "email": {
          "type": "string"
      }
    };
    
    // auto create schema for ui-grid;
    vm.gridSchema = SchemaMaker.uiGrid(vm.schema,vm.filters);
    
    vm.searchUsers = function(filters){
      return $http.get(CoreService.env.apiUrl+'/SystemUsers',{
        cache:true,
        params: {
            filter: filters
        }
      });
    }
    
    /* CRUD methods */
    vm.get = function(filters){
        filters = filters || {};
        filters = Object.assign({},filters,vm.filters)
        return $http.get(vm.endpoint(),{
            params: {
                filter: filters
            }
        });
    }
    
    vm.editElement = function(elem){ vm.formOpen =!vm.formOpen; }
    vm.link = function(userId){ return $http.put(vm.endpoint()+'/rel/'+userId); }   
    
    vm.unlink = function(userId,organizationId){ 
      organizationId = organizationId || vm.organization.id;
      return $http.delete(CoreService.env.apiUrl+'/Organizations/'+organizationId+'/systemUsers/rel/'+userId)
      
        // remove him from projects too
        // .then(function(){
        //   return $http.get(CoreService.env.apiUrl+'/SystemUsers/'+userId+'/projects',{where:{organizationId:organizationId}});
        // })
        // .then(function(res){
        //   var projects = res.data;
        //   var p=[];
        //   if(projects.length>0) {
        //     projects.forEach(function(project){
        //         p.push(ProjectUsers.load(userId,project.id).then(function(data){
        //           var p=[];
        //           data.forEach(function(a){
        //             p.push(ProjectUsers.delete(a));  
        //           });
        //           return $q.all(p);
        //         }));
        //     });
        //   }
        //   return $q.all(p);
          
        // })
      
      // .then(CoreService.success)
      // .catch(CoreService.error);
    }
    
        
    return vm;

  });
