(function () {
  'use strict';
  angular.module('com.module.permissions')
  .controller('PermissionsListController',function ($scope,PermissionService,data) {
    
    
    var vm = this;
    vm.loadData = function(models){
      models = models || PermissionService.models.get()
      vm.permissions = PermissionService.groupByModel(data,models);
      console.log(vm.permissions);
      PermissionService.groupByProperty(vm.permissions);
      
      PermissionService.getRequests().then(function(res){
        var filtered = res.data.filter(function(o){
          return models.indexOf(o.model)>=0;
        });
        console.log(filtered);
      })
    }
    $scope.$on('model.selection',function(event,models){
      console.log(models);
      vm.loadData(models);
    })
    vm.getMethodClass = function(r) {
      var c = 'info';
      switch (r.accessType) {
        case 'READ':
          c = 'success'
          break;
        case 'EXECUTE':
          c = 'warning'
          break;
        case 'WRITE':
          c = 'danger'
          break;
    
        default:
          c = 'info'
      }
      return 'label-' + c;
    
    }
    
    vm.goToAPI = function(method, model) {
      if (method.indexOf('__') === 0) {
        // this is a related method
        method = method.replace(/__/g, '_');
        method = 'prototype' + method;
      }
      var url = 'https://dev.sylva.ac/api-explorer/#!/' + model + '/' + model + '_' + method;
      window.open(url, '_blank');
    }
    
    
    vm.loadData();
    
    
    
    
  })
    
})();
