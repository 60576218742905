'use strict';

angular.module('com.module.organizations')
  .service('OrganizationService', function($q,$http,CONFIG,CoreService,SchemaMaker) {
    var vm = this;
    vm.filters = {};
    // basic endpoint
    vm.endpoint = function(){  return CoreService.env.apiUrl+'/Organizations';};
    
    // basic schema
    vm.schema = {   
        "name": {
          "type": "string",
          "required": true
        },
        "shortName": {
          "type": "string"
        },
        "logo.base64": {
          "type": "image"
        },
        "description": {
          "type": "string"
        }
    };
    
    // create schema for angular-formly
    vm.getFormSchema = function () {
        return [
          {
            key: 'name',
            type: 'input',
            templateOptions: {
              label: 'Name',
              required: true
            }
          },
          {
            key: 'shortName',
            type: 'input',
            templateOptions: {
              label: 'Short Name'
            }
          },      
          {
            key: 'logo',
            templateUrl: 'app/modules/core/views/elements/fields/formly-field-file-upload.html',
            templateOptions: {
              label: 'Logo',
              buttonLabel: 'Select logo'
            }
          },
          {
            key: 'description',
            type: 'textarea',
            templateOptions: {
              label: 'Description'
            }
          },
          {
            key: 'allowedEmails',
            type: 'multiInput',
            templateOptions: {
              label: 'Supported emails for registration (e.g. xxx@stanford.edu)',
              inputOptions: {
                type: 'input'
              }
            }
          },
          {
            key: 'orgType',
            type: 'select',
            templateOptions: {
              label: 'Organization type',
              options: [],
              valueProp: 'id',
              labelProp: 'label',
              required: true,
              placeholder:'Select type'
            },
            controller: /* @ngInject */ function($scope) {
              $scope.to.options = CONFIG.OrganizationTypes;
              // $scope.to.options = SettingService.key('Orga').data;
              // SettingService.findByKey('styleDefinitions').then(function(res){
              //   $scope.to.options = res.data;
              // });
            }
          },
          {
            key: 'autoLoginLinks',
            type: 'checkbox',
            templateOptions: {
              label: 'Use Easy auto Login Links (Email link expires in 6 months)'
            }
          },
          {
            key: 'styleDefinitions',
            type: 'select',
            templateOptions: {
              label: 'Style Definitions',
              options: [],
              valueProp: 'id',
              labelProp: 'label',
              required: true,
              placeholder:'Select style'
            },
            controller: /* @ngInject */ function($scope, CustomStylesService) {
              $scope.to.options = [];
              CustomStylesService.find().then(function(res){
                $scope.to.options = res.data.map(function(style){
                  return {
                    id:style.key,
                    label:style.data.label
                  };
                });
              });
            }
          },
          {
            key: 'gradingScheme',
            type: 'select',
            templateOptions: {
              label: 'Grading Scheme',
              options: [],
              valueProp: 'id',
              labelProp: 'label',
              required: true,
              placeholder:'Select default grading scheme'
            },
            controller: /* @ngInject */ function($scope, GradingSchemeService) {
              $scope.to.options = [];
              GradingSchemeService.find().then(function(res){
                $scope.to.options = res.data.map(function(style){
                  return {
                    id:style.key,
                    label:style.data.label
                  };
                });
              });
            }
          },
          {
            key: 'website',
            type: 'input',
            templateOptions: {
              label: 'Website Url'
            }
          },
          {
            key: 'lti',
            type: 'checkbox',
            templateOptions: {
              label: 'Enable LTI support as a Tool Provider'
            }
          }
        ];    
    };
    
    // auto create schema for ui-grid;
    vm.gridSchema = SchemaMaker.uiGrid(vm.schema,vm.filters);
    
    /* CRUD methods */
    vm.get = function(filters){
        filters = filters || {};
        filters = Object.assign({},filters,vm.filters)
        return $http.get(vm.endpoint(),{
            params: {
                filter: filters
            }
        }).then(function(res){ return res.data; });
    }
    vm.getById = function(id){
      return $http.get(vm.endpoint()+'/'+id).then(function(res){ return res.data; });
    }
    vm.save = function(item){
        if(item.id) return $http.put(vm.endpoint()+'/'+item.id,item);
        else return $http.post(vm.endpoint(),item);
    }   
    vm.delete = function(item){
        return $http.delete(vm.endpoint()+'/'+item.id);
    }
    
    /* Extra Methods */
    
    vm.getAssessmentReportData = function(config) {
      var params = {};
      if(config.projectId) params.projectId = config.projectId;
      if(config.assessmentId) params.assessmentId = config.assessmentId;
      return $http.get(vm.endpoint()+'/'+config.organizationId+'/getAssessmentReportData',params)
        .then(function(res){ return res.data; });
    }
    
    vm.updateBilling = function(id,billing){
      var data = {
        billing: billing
      };
      return $http.post(vm.endpoint()+'/'+id+'/updateBilling',data).then(function(res){ return res.data; });
    }
    
    
    // LTI 
    vm.getLTIconfig = function(id){
      return $http.get(vm.endpoint()+'/'+id+'/LTI_config').then(function(res){ return res.data; });
    }
    vm.updateLTIconfig = function(id,data){
      return $http.post(vm.endpoint()+'/'+id+'/LTI_config',{config:data}).then(function(res){ return res.data; });
    }
    vm.deleteLTIconfig = function(id){
      return $http.delete(vm.endpoint()+'/'+id+'/LTI_config').then(function(res){ return res.data; });
    }
    
    // Premium Cloud Config for SYLVA Next
    vm.getCloudConfig = function(id){
      return $http.get(vm.endpoint()+'/'+id+'/cloudConfig').then(function(res){ return res.data; });
    }
    vm.updateCloudConfig = function(id,data){
      if(data.id) {
        return $http.put(vm.endpoint()+'/'+id+'/cloudConfig',data).then(function(res){ return res.data; });
      } else {
        return $http.post(vm.endpoint()+'/'+id+'/cloudConfig',data).then(function(res){ return res.data; });
      }
    }
    vm.deleteCloudConfig = function(id,data){
      return $http.delete(vm.endpoint()+'/'+id+'/cloudConfig');
    }
    
    return vm;
  });
