"use strict";

if (window.ENV !== 'test' && window.ENV !== 'production' && window.ENV !== 'beta') window.ENV = 'development';
window.AESG = {
    allowedLoginRoles: ["superadmin"],
    canLogin: function(response) {
        console.log(response)
        var res = response.data;
        res.roles = res.roles || [];
        var userAllowedToLogin = false;
        for (var i = 0; i < res.roles.length; i++) {
            if (window.AESG.allowedLoginRoles.indexOf(res.roles[i]) >= 0) { userAllowedToLogin = true; }
        }
        return userAllowedToLogin;
    }

};
var host = window.location.origin;
var path = window.location.pathname;
var cdnUrl = 'https://storage.googleapis.com/';
angular.module('config', [])
    .constant('SERVER', {
        host: host,
        path: path
    })
    .constant('ENV', {
        development: {
            sylvaHost: "https://a.dev.sylva.ac",
            host: host,
            path: path,
            apiUrl: 'https://api.' + host.split('.')[1] + '.sylva.ac/api',
            cdnUrl: cdnUrl,
            siteUrl: '/',
            'StorageContainers': [
                { label: 'Application', value: 'application', id: "sylva-app-test" },
                { label: 'Projects', value: 'projects', id: "sylva-projects-test" },
                { label: 'Organizations', value: 'organizations', id: "sylva-organizations-test" },
                { label: 'Public', value: 'public', id: "sylva-files-test" }
            ]
        },
        beta: {
            sylvaHost: "https://beta.sylva.ac",
            host: host,
            path: path,
            apiUrl: host + '/api',
            cdnUrl: cdnUrl,
            siteUrl: host + '/admin',
            'StorageContainers': [
                { label: 'Application', value: 'application', id: "sylva-app-beta" },
                { label: 'Projects', value: 'projects', id: "sylva-projects-beta" },
                { label: 'Organizations', value: 'organizations', id: "sylva-organizations-beta" }
            ]
        },
        test: {
            sylvaHost: "https://test.sylva.ac",
            host: host,
            path: path,
            apiUrl: host + '/api',
            cdnUrl: cdnUrl,
            siteUrl: host + '/admin',
            'StorageContainers': [
                { label: 'Application', value: 'application', id: "sylva-app-test" },
                { label: 'Projects', value: 'projects', id: "sylva-projects-test" },
                { label: 'Organizations', value: 'organizations', id: "sylva-organizations-test" },
                { label: 'Public', value: 'public', id: "sylva-files-test" }
            ]
        },
        production: {
            sylvaHost: "https://app.sylva.ac",
            host: 'https://identity.sylva.ac',
            path: path,
            apiUrl: host + '/api',
            cdnUrl: cdnUrl,
            siteUrl: host + '/admin',
            'StorageContainers': [
                { label: 'Application', value: 'application', id: "sylva-app-prod" },
                { label: 'Projects', value: 'projects', id: "sylva-projects-prod" },
                { label: 'Organizations', value: 'organizations', id: "sylva-organizations-prod" },
                { label: 'Public', value: 'public', id: "sylva-files" }
            ]
        }
    })
    .constant('CONFIG', {
        'ProjectRoles': [
            { map: 'admin', label: 'Project Admin', value: 'Project Admin' },
            { map: 'contentmanager', label: 'Content Manager', value: 'Content Manager' },
            { map: 'lecturer', label: 'Lecturer', value: 'Lecturer' },
            { map: 'teachingassistant', label: 'Teaching Assistant', value: 'Teaching Assistant' },
            { map: 'student', label: 'Student', value: 'Student' },
            { map: 'owner', label: 'Owner', value: 'Owner' }
        ],
        'SystemRoles': [
            { label: 'Super Admin', id: 'adminRoleID', value: "superadmin" },
            { label: 'Creo User', id: 'creouserRoleID', value: "creouser" },
            { label: 'Sylva User', id: 'sylvaRoleID', value: "sylvauser" },
            { label: 'Developer User', id: 'developerRoleID', value: "developer" }
        ],
        'OrganizationTypes': [
            { label: 'University', id: 'university', value: "university" },
            { label: 'Company', id: 'company', value: "company" }
        ],
        'ProjectBackgroundSizes': {
            "1024x768": "", //1M resolution
            "1366x768": "", //hd ready
            "1600x1024": "", //wide
            "1920x1280": "" //full hd
        }

    })
