(function () {
  'use strict';
  angular
    .module('com.module.core')
    .service('CoreService', function (ENV, SweetAlert, toasty,$log,$uibModal) {

      this.env = ENV[window.ENV];
      
      this.log = function(info,type){
        type = type || "info"
        $log[type](type+':',info);
      }
      this.success = function(obj) {
        this.log(obj);
        this.toastSuccess('Success');
      }.bind(this)
      this.error = function(obj) {
        this.log(obj,'error');
        obj = obj || {};
        obj.data = obj.data || {};
        var err = obj.data.error || {};
        if(err.status>=500) this.toastError(err.name+': '+err.status, err.message);
        else this.toastWarning(err.name+': '+err.status, err.message);
      }.bind(this)

      this.alert = function (title, text) {
        SweetAlert.swal(title, text);
      };

      this.alertSuccess = function (title, text) {
        SweetAlert.swal(title, text, 'success');
      };

      this.alertError = function (title, text) {
        SweetAlert.swal(title, text, 'error');
      };

      this.alertWarning = function (title, text) {
        SweetAlert.swal(title, text, 'warning');
      };

      this.alertInfo = function (title, text) {
        SweetAlert.swal(title, text, 'info');
      };
      this.imageInfo = function (title, text) {
        SweetAlert.swal("Success: Image Processing started","The Processing should complete in a couple seconds depending on our server loads. You should visit this page after a while", 'info');
      };

      this.confirm = function (title, text, successCb, cancelCb) {
        var config = {
          title: title,
          text: text,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55'
        };
        this._swal(config, successCb, cancelCb);
      };

      this._swal = function (config, successCb, cancelCb) {
        SweetAlert.swal(config,
          function (confirmed) {
            if (confirmed) {
              successCb();
            } else {
              cancelCb();
            }
          });
      };

      this.toastSuccess = function (title, text) {
        toasty.pop.success({
          title: title,
          msg: text,
          sound: false
        });
      };

      this.toastError = function (title, text) {
        toasty.pop.error({
          title: title,
          msg: text,
          sound: false
        });
      };

      this.toastWarning = function (title, text) {
        toasty.pop.warning({
          title: title,
          msg: text,
          sound: false
        });
      };

      this.toastInfo = function (title, text) {
        toasty.pop.info({
          title: title,
          msg: text,
          sound: false
        });
      };
      
      this.copyToClipboard = function(data) {
        if(!data) return;
        var str = typeof data === 'object'?JSON.stringify(data):data;
                
        // // Create new element
        // var el = document.getElementById('clipboard-textarea');
        // // Set value (string to be copied)
        // el.value = str;
        // // Select text inside element
        // el.select();
        // // Copy text to clipboard
        // document.execCommand('copy');
        
        // el.value = "";
        navigator.clipboard.writeText(str).then(function(){
          console.log("navigator.clipboard.writeText Done");
        });
        var msg = str.length<1000 ? str.substr(0,100)+'...' :'Total bytes: '+Math.round(lengthInUtf8Bytes(str)/1024)+'KB' 
        toasty.pop.info({
          title: 'Copied to Clipboard',
          msg: msg,
          sound: false
        });

      }
      function lengthInUtf8Bytes(str) {
        // Matches only the 10.. bytes that are non-initial characters in a multi-byte sequence.
        var m = encodeURIComponent(str).match(/%[89ABab]/g);
        return str.length + (m ? m.length : 0);
      }
      
      this.downloadObjectAsJson = function(exportObj, exportName){
        var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
        var downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href",     dataStr);
        downloadAnchorNode.setAttribute("download", exportName + ".json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      }
      
      this.viewElement = function(item) {
        // console.log("View", item);
        var modal = $uibModal.open({
          template: '<div class="panel panel-default">'+
          '<div class="panel-heading"><h1>Details<a href="" class="pull-right" ng-click="close()"><i class="fa fa-close"></i></a></h1></div>'+
          '<div class="panel-body"><object-viewer item="item"></object-viewer></div>'+
          '</div>',
          size: 'md',
          controller: function($scope) {
            $scope.item = item;
            $scope.close = modal.close; // link inner scope to outer variable
          }
        });
      }

    });

})();
