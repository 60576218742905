(function () {
  'use strict';
  angular
    .module('com.module.permissions')
    .service('PermissionService', function ($q, $http, CoreService) {
    
        var vm = this;
        vm.models = {
            __data:[],
            get:function(){
                return this.__data; 
            },
            set:function(models){
                 this.__data = models   
            }
        };
        
        vm.get = function(filter){
            return $http.get(CoreService.env.apiUrl + '/Settings/viewPermissions',{cache:true})
            .then(function(res){return res.data.filter(function(m){
                if (!m.acls) m.acls = [];
                return m.acls.length>0;
            })})
            .catch(function(err){console.log(err)});
        }       
        
        vm.groupByModel = function(data,filter){
            return data.map(function(m) {
                if (!m.acls) m.acls = [];
                m.acls = m.acls.map(function(a) {
                    a.property = a.property || a.properties;
                    if (!(a.property instanceof Array)) {
                        a.property = a.property ? [a.property] : ["*"];
                    }
                    return a;
                })
                return m;
            }).filter(function(m) {
                if(filter) {
                    return filter.indexOf(m.name)>=0;
                }
                if (!m.acls) return false;
                if (m.acls.filter(function(a) { return a.property && a.property.length > 0; }).length > 0) return true;
                return false;
    
            }).sort(function(a, b) {
                return b.acls.length - a.acls.length;
            });
        }
        vm.groupByProperty = function(data) {
            return data.map(function(m){
                m.roles = {};
                m.properties = {};
                if (!m.acls) m.acls = [];
                m.acls.forEach(function(a){
                    if(!a.principalId) return;
                    a.principalId = a.principalId.replace('$','').replace('group:','')
                    .replace("teachingassistant","TA")
                    .replace("contentmanager","CM");
                    m.roles[a.principalId] = {name:a.principalId};
                    a.property.forEach(function(p){
                        if(!m.properties[p]) {
                            m.properties[p] = {
                                name:p,
                                accessType:a.accessType,
                                roles:{}
                            };
                            m.properties[p].roles[a.principalId] = a.permission.toUpperCase()==='ALLOW'?2:1;
                        } else {
                            
                            m.properties[p].roles[a.principalId] = a.permission.toUpperCase()==='ALLOW'?2:1;
                        }
                            
                    })
                })
            })
            
        }
        
        vm.getRequests = function(query){
            query = query || 'is:unresolved level:info';
            return $http.get(CoreService.env.apiUrl + '/Settings/fetchEvents',{params:{query:query},cache:true});
        }
        
        
        
        return vm;
        
    });

})();
