(function() {
  'use strict';

  angular
    .module('com.module.core')
    .factory('SchemaMaker', function() {

      var vm = this;

      vm.uiGrid = function(schema, filters) {
        var gridSchema = [];
        var relations = [];
        Object.keys(schema).forEach(function(key) {
          var obj = Object.assign(
            {},
            schema[key],
            {
            'name': key,
            'type': schema[key].type || "string"
          });
          gridSchema.push(obj);
        });
        
        var ref,model,field;
        
        gridSchema = gridSchema.map(function(item) {
          switch (item.type) {
            case 'date':
              item.cellFilter = item.cellFilter || 'date:\'yyyy-MM-dd h:mm\'';
              item.width = 110;
              break;

            case 'number':
              item.width = 100;
              break;

            case 'string':
              break;

            case 'relation':
              ref = item.name.split('.');
              model = ref[0];
              field = ref[1];
              relations.push({
                model: model,
                field: field
              });
              item.field = item.name; //file.name
              item.name = model; //file

              item.cellTemplate = 
              '<div class="ui-grid-cell-contents" title="TOOLTIP">'+
              '<relation-viewer entity="row.entity" model="'+model+'" field="'+field+'"></relation-viewer>'+
              '</div>';

              item.enableFiltering = false;
              break;
              
            case 'image':
              ref = item.name.split('.');
              model = ref[0];
              field = ref[1];
              item.name = model;
              item.field = model;
              item.cellTemplate = 
              '<img style="max-height:50px" ng-src="data:image/*;base64,{{ row.entity.logo.'+field+' }}" alt="Organization logo" title="Organization logo">'

              item.enableFiltering = false;
              break;

            default:
              // other
          }
          // default props
          item.cellTooltip = true;
          return item;
        });


        // add relations (includes)
        if (relations.length > 0) {
          filters.include = [];
          relations.forEach(function(rel) {
            filters.include.push(rel.model);
          });
        }

        console.log('GridSchema calculated')

        return gridSchema;

      };

      


      return vm;

    });


})();
