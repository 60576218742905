(function () {
  'use strict';
  angular.module('com.module.permissions')
  
    .config(function($stateProvider){
        $stateProvider
        .state('app.permissions', {
          abstract: true,
          url: '/permissions',
          templateUrl: 'app/modules/permissions/views/main.html',
          controllerAs: 'vm',
          controller:function($scope,PermissionService,data){
            var vm = this;
            vm.availableModels = data.map(function(m){
              return m.name;
            });
            vm.models = PermissionService.models.__data;
            
            vm.selectModels = function(models){
              PermissionService.models.__data = models;
              $scope.$broadcast('model.selection',models);
            }
            
          },
          resolve:{
            data:function(PermissionService){
              PermissionService.models.set(['Project']);
              return PermissionService.get();
            }
          }
        })
        .state('app.permissions.list', {
          url: '/list',
          templateUrl: 'app/modules/permissions/views/list.roles.html',
          controllerAs: 'ctrl',
          controller: 'PermissionsListController'
        })
    })
    
})();
