(function() {
  'use strict';
  angular
    .module('com.module.settings')
    .controller('SettingSingleController', function($timeout, $state, SettingService, setting) {
      var vm = this;
      vm.setting = setting;
      vm.setting.data = vm.setting.data || {};
      vm.formFields = SettingService.getFormFields();
      vm.formOptions = {};
      vm.submit = function() {
        SettingService.upsert(vm.setting).then(function() {
          $state.go('^.list');
        });
      };
      // create the editor
      var container = document.getElementById("jsoneditor");
      var options = {
        onChange: dataChanged,
        mode: 'tree',
        modes: [
          // 'code', 
          // 'form', 
          'text',
          'tree',
          // 'view'
        ], // allowed modes
        onError: function(err) {
          alert(err.toString());
        },
        onModeChange: function(newMode, oldMode) {
          console.log('Mode switched from', oldMode, 'to', newMode);
        }

      };
      var editor = new JSONEditor(container, options);
      // set json
      editor.set(vm.setting.data);

      function dataChanged() {
        var json = editor.get();
        vm.setting.data = json;
        $timeout(function() {
          vm.preview = json;
        })
      }
      vm.preview = vm.setting.data;

    });

})();
