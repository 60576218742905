(function () {
  'use strict';
  angular
    .module('com.module.authproviders')
    .service('AuthProviderConnectionsService', function ($state, CoreService, Setting, gettextCatalog,$http,LoopBackAuth) {
      var vm = this;
      vm.authproviders = [];
      vm.find = function () {
        return $http.get(CoreService.env.apiUrl+'/AuthProviderConnections');
      };
      
      vm.getUserConnections = function (userId) {
        return $http.get(CoreService.env.apiUrl+'/SystemUsers/'+userId+'/authproviders').then(function(res){
          return res.data;
        })
      };

      vm.addUserConnection = function(userId,email,providerId) {
        return $http.put(CoreService.env.apiUrl + '/SystemUsers/' + userId + '/authproviders/rel/' + providerId, {
          email: email
        })
        .then(function(res){
          return res.data;
        })
        .then(function() {
            CoreService.toastSuccess(
              gettextCatalog.getString('Connection saved')
            );
          })
          .catch(function(err) {
            CoreService.toastError(
              gettextCatalog.getString('Error saving Connection'),
              err.toString()
            );
          });
      };

      vm.deleteUserConnection = function(userId, providerId, successCb, cancelCb) {
        successCb = successCb || function(){};
        cancelCb = cancelCb || function(){};
        CoreService.confirm(
          gettextCatalog.getString('Are you sure?'),
          gettextCatalog.getString('Deleting this cannot be undone'),
          function() {
              return $http.delete(CoreService.env.apiUrl + '/SystemUsers/' + userId + '/authproviders/rel/' + providerId)
              .then(function(){
                CoreService.toastSuccess(
                  gettextCatalog.getString('Connection deleted')
                )
                successCb();
              })
              .catch(function(err) {
                CoreService.toastError(
                  gettextCatalog.getString('Error deleting Connection'),
                  err.toString()
                )
                cancelCb();
              })
          },
          function() {
            cancelCb();
          }
        );
      };
      

      return vm;

    });

})();
