(function () {
  'use strict';
  angular
    .module('com.module.projects')
    .service('ProjectsService', function ($state, CoreService, $http, Project, gettextCatalog, AppAuth,SystemUser,$rootScope,$q) {
      var vm = this;
      
      vm.getProjects = function(user) {
        var d=$q.defer();
        if(user&&user.roles){
          if(user.roles.indexOf('superadmin')>=0) {
            console.log("SuperAdmin");
            return Project.find({filter:{include:'organization'}}).$promise;
          }
          else if(user.id) {
            console.log("Other user",user.id);
            return $http.get(CoreService.env.apiUrl +'/SystemUsers/'+user.id+'/linkedProjects',{params:{
              filter:{include:'organization'}
            }}).then(function(res){return res.data});
          }
          else {
            console.log("No userID",user.id);
            d.reject("No userId",user);
          }
        } else {
          console.log("No current user");
          d.reject('No current user yet');
        }
        return d.promise;
      };
      

      vm.getProject = function (id) {
        return Project.findById({
          id: id,
          filter:{include:'organization'}
        }).$promise;
      };

      vm.isPublic = function (id) {
        return $http.get(CoreService.env.apiUrl +'/Projects/'+id+'/access',
          {params:{filter:{where:{role:'student',systemuserId:'public'}}}})
          .then(function(res){
            console.log(res);
            return res.data[0];
          });
      };

      vm.togglePublic = function (project) {
        var id = project.id;
        return vm.isPublic(id).then(function(data){
            if(!project.isPublic && data && data.id){
              return $http.delete(CoreService.env.apiUrl +'/Projects/'+id+'/access/'+data.id);
            } else if(project.isPublic && !data) {
              return $http.post(CoreService.env.apiUrl +'/Projects/'+id+'/access',{role:'student',systemuserId:'public'});
            }
         });
      };
      
      vm.getMaximumSeats = function(projectId){
        return $http.get(CoreService.env.apiUrl +'/Projects/'+projectId+'/account')
        .then(function(res){if (res.data) return res.data.seats; else return null;});
      }
      
      vm.saveMaximumSeats = function(projectId,seats,isNew){
        return $http[isNew?'post':'put'](CoreService.env.apiUrl +'/Projects/'+projectId+'/account',{seats:seats})
          .then(function (res) {
            CoreService.toastSuccess('Seats saved: '+res.data.seats); 
            console.log(res);
          })
          .catch(function (err) {
            CoreService.toastError('Error saving seats',err.data.error.message);
            console.log(err);
          }
        );
      }

      vm.upsertProject = function (project) {
        delete project.organization;
        for (var prop in project.images){
          if (project.images[prop] && project.images[prop].id) project.images[prop] = project.images[prop].id;
        }
        var action = project.id ? Project.upsert(project).$promise : Project.create(project).$promise;
          return action.then(function (res) {
            CoreService.toastSuccess(
              gettextCatalog.getString('Project saved'),
              gettextCatalog.getString('Your project is safe with us!')
            );
            console.log("Project saved",res);
            return res;
          })
          .catch(function (err) {
            CoreService.toastError(
              gettextCatalog.getString('Error saving project '),
              gettextCatalog.getString('This project could no be saved: ') + err
            );
          }
        );
      };

      vm.deleteProject = function (id, successCb, cancelCb) {
        CoreService.confirm(
          gettextCatalog.getString('Are you sure?'),
          gettextCatalog.getString('Deleting this cannot be undone'),
          function () {
            Project.deleteById({id: id}, function () {
              CoreService.toastSuccess(
                gettextCatalog.getString('Project deleted'),
                gettextCatalog.getString('Your project is deleted!'));
              successCb();
            }, function (err) {
              CoreService.toastError(
                gettextCatalog.getString('Error deleting project'),
                gettextCatalog.getString('Your project is not deleted! ') + err);
              cancelCb();
            });
          },
          function () {
            cancelCb();
          }
        );
      };
      vm.get = function(filter) {
        return $http.get(CoreService.env.apiUrl + '/projects', {
          params: {
            filter: filter
          }
        }).then(function(res) { return res.data });
      }
      vm.getFormFields = function (projectId) {
        return [
          {
            key: 'name',
            type: 'input',
            templateOptions: {
              label: gettextCatalog.getString('Name'),
              required: true
            }
          },
          {
            key: 'shortName',
            type: 'input',
            templateOptions: {
              label: gettextCatalog.getString('Short Name'),
              required: true
            }
          },
          // {
          //   key: 'moduleNumber',
          //   type: 'input',
          //   templateOptions: {
          //     label: gettextCatalog.getString('Module Number'),
          //     required: false
          //   }
          // },
          // {
          //   key: 'website',
          //   type: 'input',
          //   templateOptions: {
          //     label: gettextCatalog.getString('Website/Link')
          //   }
          // },
          // {
          //   key: 'description',
          //   type: 'textarea',
          //   templateOptions: {
          //     label: gettextCatalog.getString('Description'),
          //   }
          // },
          // {
          //   key: 'projectType',
          //   type: 'select',
          //   templateOptions: {
          //     label: gettextCatalog.getString('Project Type'),
          //     options: [],
          //     valueProp: 'id',
          //     labelProp: 'label',
          //     required: true,
          //     placeholder:'Select Type'
          //   },
          //   controller: /* @ngInject */ function($scope, SettingService) {
          //     $scope.to.options = [
          //       {id:"lecture","label":"Lecture"},
          //       {id:"seminar","label":"Seminar"}
          //     ];
          //   }
          // },
          {
            key: 'semester',
            type: 'select',
            templateOptions: {
              label: gettextCatalog.getString('Semester'),
              options: [],
              valueProp: 'id',
              labelProp: 'label',
              required: true,
              placeholder:'Select semester'
            },
            controller: /* @ngInject */ function($scope, SettingService) {
              $scope.to.options = [];
              var year = new Date().getFullYear();
              for (var i=(year-3);i<=(year+3);i++) {
                var s = "Spring "+i;
                var f = "Fall "+i;
                $scope.to.options.push({id:f,label:f})
                $scope.to.options.push({id:s,label:s})
              }
            }
          },
          {
            key: 'isPublic',
            type: 'checkbox',
            templateOptions: {
              label: 'Is Public',
            },
            controller: /* @ngInject */ function($scope,$http,CoreService) {
              
              if(projectId) {
              vm.isPublic(projectId)
              .then(function(res){
                $scope.options.value(res && !!res.id);
                // note, the line above is shorthand for:
                // $scope.options.templateOptions.options = data;
              });
              }
            }
          },
          {
            key: 'organizationId',
            type: 'select',
            templateOptions: {
              label: 'Organization',
              options: [],
              valueProp: 'id',
              labelProp: 'name',
              required: true,
              placeholder:'Select Organization'
            },
            controller: /* @ngInject */ function($scope, OrganizationService) {
              OrganizationService.get().then(function(data){
                $scope.to.options = data;
                // note, the line above is shorthand for:
                // $scope.options.templateOptions.options = data;
                return data;
              });
            }
          },
          {
            key: 'sylvaStyleDefinitions',
            type: 'select',
            templateOptions: {
              label: 'SYLVA Style Definitions',
              options: [],
              valueProp: 'id',
              labelProp: 'label',
              required: false,
              placeholder:'Select style'
            },
            controller: /* @ngInject */ function($scope, CustomStylesService) {
              $scope.to.options = [];
              CustomStylesService.find().then(function(res){
                $scope.to.options = res.data.map(function(style){
                  return {
                    id:style.key,
                    label:style.data.label
                  };
                });
              });
            }
          },
          {
            key: 'styleDefinitions',
            type: 'select',
            templateOptions: {
              label: 'CREO Style Definitions',
              options: [],
              valueProp: 'id',
              labelProp: 'label',
              required: false,
              placeholder:'Select style'
            },
            controller: /* @ngInject */ function($scope, SettingService) {
              $scope.to.options = [];
              SettingService.findByKey('creoStyleDefinitions').then(function(res){
                $scope.to.options = res.data.map(function(style){
                  return {
                    id:style.id,
                    label:style.label
                  };
                });
              });
            }
          }
        ];
      };


    return vm;
    
    });

})();
