'use strict';

angular.module('com.module.organizations')
  .component('organizationUsers', {
      templateUrl: 'app/modules/organizations/components/organization-users/template.html',
      restrict: 'E',
      bindings:{
        'organization': '<'
      },
        controllerAs: 'vm',
        controller: function($uibModal, OrganizationUsers, GridHelper,CoreService) {
            var vm = this;

            // init config
            OrganizationUsers.setOrganization(vm.organization);
            vm.config={
                controllerAs:'vm'        ,        
                // disable edit
                actionColumns :{ 
                    editElement:{
                        disabled:true
                    }
                }
            };
            vm.closeForm = function(){
                // vm.formOpen = false;            
                vm.loadListData();
            };
            
            /* List */
            var schema = OrganizationUsers.gridSchema;
            vm.pagination = {
                pageNumber: 1,
                pageSize: 15,
                sort: null
            };
            vm.filters = {skip:0,limit:vm.pagination.pageSize};
            vm.loadListData = function(filters) {
                
                filters = filters || vm.filters;
                console.log("Req Filter", filters);
                
                // when you search make sure you dont skip records
                if (filters.where && Object.keys(filters.where).length > 0) {
                    vm.pagination.pageNumber = 1;
                    filters.skip = 0;
                }
              
                vm.promise = OrganizationUsers.get(filters).then(function(res) {
                    var headers = res.headers();
                    vm.gridOptions.totalItems = Number(headers['x-total-count']);
                    vm.gridOptions.data = res.data; //postProcess(res.data);
                });
                return vm.promise;
            };            
            vm.gridOptions = GridHelper.gridOptions(schema, vm.loadListData, vm.pagination, vm.filters,vm.config);
            vm.loadListData();

            /* Single */
            
            vm.searchUsers = function(search){
              if(search.length<1) return;
              var searchRegexp = new RegExp(search,'i').toString()
              var filters = {
                limit:20,
                where: 
                // {email:{regexp:searchRegexp}}
                {
                  or: [{
                    email:{regexp:searchRegexp}
                  },{
                    firstName:{regexp:searchRegexp}
                  },{
                    lastName:{regexp:searchRegexp}
                  }]
                }
              };
              
              vm.searchLoader = OrganizationUsers.searchUsers(filters).then(function(res) {
                  vm.searchLoader = false;
                  // console.log(res);
                  vm.availableUsers = res.data; //postProcess(res.data);
              });
            }

            vm.item = {};
            vm.viewElement = CoreService.viewElement;
            vm.createElement = function(item){
                console.log("Create",item);
                OrganizationUsers.link(item.id).then(function(){CoreService.success('Success'); vm.loadListData();}).catch(CoreService.error);
                vm.selected=null;  
            };
            vm.deleteElement = function(item){
                console.log("Delete",item);     
                CoreService.confirm('Are you sure?','Deleting this cannot be undone',
                  function () {
                    //   confirm
                    OrganizationUsers.unlink(item.id).then(function () {
                        CoreService.success('Deleted successfully',(item.name||item.id)+' deleted!');
                        vm.loadListData();
                    }).catch(CoreService.error);
                  },
                  function () {
                    //   cancel
                  }
                );
            };
            vm.submit = function(){
                var item = vm.item;
                vm.promise = OrganizationUsers.link(item.id)
                    .then(function(res){
                        CoreService.success('Saved successfully',res.data.id);
                        // vm.formOpen = !vm.formOpen;
                        vm.item=null;
                        vm.loadListData();
                    }).catch(CoreService.error);
            };
            
            /* Bulk import */
            vm.gridOptionsImport = {};
            
            
        }

  });
