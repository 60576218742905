(function () {
  'use strict';
  angular
    .module('com.module.authproviders')
    .config(function ($stateProvider) {
      $stateProvider
        .state('app.authproviders', {
          abstract: true,
          url: '/authproviders',
          templateUrl: 'app/modules/authproviders/views/main.html'
        })
        .state('app.authproviders.list', {
          url: '/list',
          templateUrl: 'app/modules/authproviders/views/list.html',
          controllerAs: 'ctrl',
          controller: function (AuthProvidersService) {
            var vm = this;
            
            vm.loading = AuthProvidersService.find().then(function(res){
              vm.authproviders = res.data;
            })
            
            
          }
        })
        .state('app.authproviders.add', {
          url: '/add',
          templateUrl: 'app/modules/authproviders/views/form.html',
          controllerAs: 'ctrl',
          controller:'AuthProvidersSingleController',
          resolve: {
            authprovider: function () {
              return {data:{}};
            }
          }
        })
        .state('app.authproviders.edit', {
          url: '/:id/edit',
          templateUrl: 'app/modules/authproviders/views/form.html',
          controllerAs: 'ctrl',
          controller:'AuthProvidersSingleController',
          resolve: {
            authprovider: function($stateParams, AuthProvidersService) {
              return AuthProvidersService.findById($stateParams.id);
            },
            previewComponent: function($stateParams, Setting) {
              return Setting.find({
                filter: {
                  where: {
                    key: 'admin.configuration'
                  }
                }
              }).$promise.then(function(res) {
                return res[0].data.stylePreviewLectureLink;
              });
            }
          }
        })
        .state('app.authproviders.delete', {
          url: '/:id/delete',
          template: '',
          controllerAs: 'ctrl',
          controller: function ($stateParams, $state, AuthProvidersService) {
            AuthProvidersService.delete($stateParams.id, function () {
              $state.go('^.list');
            }, function () {
              $state.go('^.list');
            });
          }
        });
    });

})();
