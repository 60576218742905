(function () {
  'use strict';
  angular
    .module('com.module.files')
    .config(function ($stateProvider) {
      $stateProvider
        .state('app.files', {
          abstract: true,
          url: '/files',
          templateUrl: 'app/modules/files/views/main.html',
          resolve:{
            admin:function(AppAuth,currentUser){
                return AppAuth.isAdmin(currentUser);
            }
          }
        })
        .state('app.files.list', {
          url: '',
          templateUrl: 'app/modules/files/views/list.html',
          controllerAs: 'ctrl',
          controller: 'FilesListController'
        })
        .state('app.files.upload', {
          url: '/upload',
          templateUrl: 'app/modules/files/views/upload.html',
          controllerAs: 'ctrl',
          controller: function (FileUploader, FileService) {
            FileService.setApi();
            this.uploader = FileService.uploader();
            this.uploader.onBeforeUploadItem = function(item) {
              item.url = item.url + '/' + item.file.componentModel + '/uploadFile';
              // item.headers['x-upload-filename'] = item.file.name;
            };
          }
        });
    });

})();
