(function () {
  'use strict';
  /**
   * @ngdoc function
   * @name com.module.users.controller:LoginCtrl
   * @description Login Controller
   * @requires $scope
   * @requires $routeParams
   * @requires $location
   * Contrller for Login Page
   **/
  angular
    .module('com.module.users')
    .controller('LoginCtrl', function ($rootScope,$scope, $routeParams, $location, CoreService, AppAuth, gettextCatalog) {

      var TWO_WEEKS = 1000 * 60 * 60 * 24 * 7 * 2;
      
      AppAuth.getAuthenticatedUser(function(cur){ if(cur.id) $location.path('/app'); });
      
      $scope.credentials = {
        ttl: TWO_WEEKS,
        rememberMe: true
      };

      $scope.schema = [
        {
          label: '',
          property: 'email',
          placeholder: gettextCatalog.getString('Email'),
          type: 'email',
          attr: {
            required: true,
            ngMinlength: 4
          },
          msgs: {
            required: gettextCatalog.getString('You need an email address'),
            email: gettextCatalog.getString('Email address needs to be valid'),
            valid: gettextCatalog.getString('Nice email address!')
          }
        },
        {
          label: '',
          property: 'password',
          placeholder: gettextCatalog.getString('Password'),
          type: 'password',
          attr: {
            required: true
          }
        },
        {
          property: 'rememberMe',
          label: gettextCatalog.getString('Stay signed in'),
          type: 'checkbox'
        }
      ];

      $scope.options = {
        validation: {
          enabled: true,
          showMessages: false
        },
        layout: {
          type: 'basic',
          labelSize: 3,
          inputSize: 9
        }
      };


      $scope.login = function () {
        
        var promise = AppAuth.login({ rememberMe: $scope.credentials.rememberMe },$scope.credentials);
        promise
        .then(function(res){
            var next = $location.nextAfterLogin || '/';
            $location.nextAfterLogin = null;
            CoreService.toastSuccess(gettextCatalog.getString('Logged in'),
              gettextCatalog.getString('You are logged in!'));
            if (next === '/login') { next = '/'; }
            $location.path(next);
        })
        .catch(function(res){
          console.error(res);
          $scope.loginError = res.data.error;
        });
      };

    });

})();
