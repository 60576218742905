(function() {
    'use strict';
    angular
        .module('com.module.core')
        .run(function(formlyConfig) {
            var ngModelAttrs = {};

            var attributes = [
                'ng-model',
                'min-date',
                'max-date',
                'date-disabled',
                'day-format',
                'month-format',
                'year-format',
                'year-range',
                'day-header-format',
                'day-title-format',
                'month-title-format',
                'date-format',
                'date-options',
                'hour-step',
                'minute-step',
                'show-meridian',
                'meridians',
                'readonly-time',
                'readonly-date',
                'hidden-time',
                'hidden-date',
                'mousewheel',
                'show-spinners',
                'current-text',
                'clear-text',
                'close-text'
            ];

            var bindings = [
                'ng-model',
                'min-date',
                'max-date',
                'date-disabled',
                'day-format',
                'month-format',
                'year-format',
                'year-range',
                'day-header-format',
                'day-title-format',
                'month-title-format',
                'date-format',
                'date-options',
                'hour-step',
                'minute-step',
                'show-meridian',
                'readonly-time',
                'readonly-date',
                'hidden-time',
                'hidden-date'
            ];

            angular.forEach(attributes, function(attr) {
                ngModelAttrs[camelize(attr)] = {
                    attribute: attr
                };
            });

            angular.forEach(bindings, function(binding) {
                ngModelAttrs[camelize(binding)] = {
                    bound: binding
                };
            });

            function camelize(string) {
                string = string.replace(/[\-_\s]+(.)?/g,

                    function(match, chr) {
                        return chr ? chr.toUpperCase() : '';
                    });
                // Ensure 1st char is always lowercase
                return string.replace(/^([A-Z])/, function(match, chr) {
                    return chr ? chr.toLowerCase() : '';
                });
            }

            formlyConfig.setType({
                name: 'custom',
                template: '<div id="jsoneditor" style="width: 100%; height: 400px;"></div>'
            });

            formlyConfig.setType({
                name: 'datetimepicker',
                template: '<br><datetimepicker ng-model="model[options.key]" show-spinners="true" date-format="M/d/yyyy" date-options="dateOptions"></datetimepicker>',
                wrapper: ['bootstrapLabel'],
                defaultOptions: {
                    ngModelAttrs: ngModelAttrs,
                    templateOptions: {
                        label: 'Time',
                        minDate: '04/01/2016'
                    }
                }
            });

            formlyConfig.setType({
                name: 'timepicker',
                template: '<uib-timepicker show-meridian="false" ng-model="model[options.key]"></uib-timepicker>',
                wrapper: [
                    'bootstrapLabel',
                    'bootstrapHasError'
                ],
                defaultOptions: {
                    ngModelAttrs: ngModelAttrs,
                    templateOptions: {
                        timepickerOptions: {}
                    }
                }
            });

            formlyConfig.setType({
                name: 'datepicker',
                templateUrl: 'app/modules/core/config/formly/datepicker.html',
                wrapper: [
                    'bootstrapLabel',
                    'bootstrapHasError'
                ],
                defaultOptions: {
                    ngModelAttrs: ngModelAttrs,
                    templateOptions: {
                        datepickerOptions: {}
                    }
                }
            });


            formlyConfig.setType({
                name: 'multiInput',
                templateUrl: 'app/modules/core/config/formly/multiInputTemplate.html',
                defaultOptions: {
                    noFormControl: true,
                    wrapper: ['bootstrapLabel', 'bootstrapHasError'],
                    templateOptions: {
                        inputOptions: {
                            wrapper: null
                        }
                    }
                },
                controller: /* @ngInject */ function($scope) {
                    $scope.copyItemOptions = copyItemOptions;

                    function copyItemOptions() {
                        return angular.copy($scope.to.inputOptions);
                    }
                }
            });


            var unique = 1;
            formlyConfig.setType({
                name: 'repeatSection',
                templateUrl: 'app/modules/core/config/formly/repeatSectionTemplate.html',
                controller: function($scope) {
                    $scope.formOptions = { formState: $scope.formState };
                    $scope.addNew = addNew;

                    $scope.copyFields = copyFields;


                    function copyFields(fields) {
                        fields = angular.copy(fields);
                        addRandomIds(fields);
                        return fields;
                    }

                    function addNew() {
                        $scope.model[$scope.options.key] = $scope.model[$scope.options.key] || [];
                        var repeatsection = $scope.model[$scope.options.key];
                        var lastSection = repeatsection[repeatsection.length - 1];
                        var newsection = {};
                        if (lastSection) {
                            newsection = angular.copy(lastSection);
                        }
                        repeatsection.push(newsection);
                    }

                    function addRandomIds(fields) {
                        unique++;
                        angular.forEach(fields, function(field, index) {
                            if (field.fieldGroup) {
                                addRandomIds(field.fieldGroup);
                                return; // fieldGroups don't need an ID
                            }

                            if (field.templateOptions && field.templateOptions.fields) {
                                addRandomIds(field.templateOptions.fields);
                            }

                            field.id = field.id || (field.key + '_' + index + '_' + unique + getRandomInt(0, 9999));
                        });
                    }

                    function getRandomInt(min, max) {
                        return Math.floor(Math.random() * (max - min)) + min;
                    }
                }
            });



        })

})();