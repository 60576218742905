angular
    .module('com.module.files')
    .controller('FilesListController', function($uibModal, FileService, GridHelper,CoreService,$state) {
            var vm = this;

            vm.cdnUrl = CoreService.env.cdnUrl;
            vm.id = $state.params.id;
            var FilesApiType = $state.current.name.split('.')[1];
            
            FileService.setApi(FilesApiType,vm.id);
            
            vm.config={
                controllerAs:'ctrl',
                // disable edit
                actionColumns :{ 
                    editElement:{
                        disabled:true
                    }
                }
            };
            
            /* List */
            var schema = FileService.gridSchema;
            vm.filters = {limit:15,skip:0};
            vm.pagination = {
                pageNumber: 1,
                pageSize: 15,
                sort: null
            };
            vm.loadListData = function(filters) {
                filters = filters || vm.filters;
                console.log("Req Filter", filters);
                
                // TODO : Fix
                // if(JSON.stringify(filters.where)!=JSON.stringify(vm.filters.where)) {
                //     vm.filters.skip=0;
                //     filters.skip=0;
                // }
                // vm.filters.where = Object.assign({},vm.filters.where,filters.where);
                
                vm.loading = FileService.get(filters).then(function(res) {
                    var headers = res.headers();
                    vm.gridOptions.totalItems = Number(headers['x-total-count']);
                    vm.gridOptions.data = res.data; //postProcess(res.data);
                });
            };
            vm.gridOptions = GridHelper.gridOptions(schema, vm.loadListData, vm.pagination, vm.filters,vm.config);
            vm.loadListData();
            
            vm.deleteElement = function(elem){
                FileService.delete(elem.id,
                function(){
                    vm.loadListData();
                },function(){
                    
                });
            };
            vm.viewElement = CoreService.viewElement;

        }

    );