(function () {
  'use strict';
  angular
    .module('com.module.core')
    .config(function (cfpLoadingBarProvider) {
        cfpLoadingBarProvider.includeSpinner = false;
    })
    .config(function (gravatarServiceProvider) {
        if(window.ENV!=='development') {
          gravatarServiceProvider.defaults = {
            "default": window.location.origin+window.location.pathname+"images/sylva_icon.png"
          }
        }
    })
    .config(function (LoopBackResourceProvider,CoreServiceProvider) {
        
        var conf=CoreServiceProvider.$get().env;
        // Use a custom auth header instead of the default 'Authorization'
        // LoopBackResourceProvider.setAuthHeader('X-Access-Token');
        
        // Change the URL where to access the LoopBack REST API server
        LoopBackResourceProvider.setUrlBase(conf.apiUrl);
    })
    .config(function(angularBusyDefaults) {    

      // angularBusyDefaults.message = 'Loading Stuff';
      // angularBusyDefaults.backdrop = false;
      // angularBusyDefaults.templateUrl = 'my_custom_template.html';
      // angularBusyDefaults.delay = 300;
      // angularBusyDefaults.minDuration = 700;
      // angularBusyDefaults.wrapperClass = 'my-class my-class2';
    
    })
    .run(function ($rootScope, gettextCatalog, SettingService) {

      // Left Sidemenu
      $rootScope.menu = [];

      // Add Sidebar Menu
      $rootScope.clearMenu = function(uisref){
        if(!uisref) $rootScope.menu = [];
        else {        
          $rootScope.menu = $rootScope.menu.filter(function(elem){
            return elem.sref!==uisref;
          })
        }
      }
      $rootScope.addMenu = function (name, uisref, icon) {
        var exists=false;        
        $rootScope.menu.forEach(function(elem){
          if(elem.sref===uisref) exists=true;
        })
        if(!exists) $rootScope.menu.push({
          name: name,
          sref: uisref,
          icon: icon
        });
      };
      
      // Get settings config from DB
      SettingService.getConf();

      // // Add Menu Dashboard
      // $rootScope.addMenu(gettextCatalog.getString('Dashboard'), 'app.home',
      //   'fa-dashboard');

      // // Dashboard
      // $rootScope.dashboardBox = [];

      // // Add Dashboard Box
      // $rootScope.addDashboardBox = function (name, color, icon, quantity, href) {
      //   $rootScope.dashboardBox.push({
      //     name: name,
      //     color: color,
      //     icon: icon,
      //     quantity: quantity,
      //     href: href
      //   });
      // };

      // // Get Settings for Database
      // $rootScope.setSetting = function (key, value) {

      //   Setting.find({
      //     filter: {
      //       where: {
      //         key: key
      //       }
      //     }
      //   }, function (data) {

      //     if (data.length) {
      //       data[0].value = value;
      //       data[0].$save();
      //     } else {
      //       Setting.create({
      //         key: key,
      //         value: value
      //       }, function (data) {
      //         console.log(data);
      //       });
      //     }
      //     $rootScope.loadSettings();
      //   });
      // };

      // // Load Settings blank
      // $rootScope.settings = {};

      // // Get Settings for Loopback Service
      // $rootScope.loadSettings = function () {
      //   Setting.find(function (settings) {
      //     $rootScope.settings.data = settings;
      //   });
      // };

    })

})();
