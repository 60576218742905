(function () {
  'use strict';
  angular
    .module('com.module.projects')
    .config(function ($stateProvider) {
      $stateProvider
        .state('app.projects', {
          abstract: true,
          url: '/projects',
          templateUrl: 'app/modules/projects/views/main.html'
        })
        .state('app.projects.list', {
          url: '',
          templateUrl: 'app/modules/projects/views/list.html',
          controllerAs: 'ctrl',
          controller: function (ProjectsService,currentUser,SettingService) {
            var vm = this;
            // return unique
            vm.loading = ProjectsService.getProjects(currentUser)
            .then(function(data){
              vm.projects = data.sort(function(a,b){
                return a.name>b.name?1:-1;
              });
            })
            vm.projectFilter = '';
            // $rootScope.addDashboardBox('Projects', 'bg-purple', 'ion-calendar', this.projects.length, 'app.projects.list');
            

          },
          resolve: {
          }
        })
        .state('app.projects.add', {
          url: '/add',
          templateUrl: 'app/modules/projects/views/form.html',
          controllerAs: 'ctrl',
          controller: function ($state, ProjectsService, project) {
            var vm = this;
            vm.project = project;
            vm.formFields = ProjectsService.getFormFields();
            vm.formOptions = {};
            vm.submit = function () {
              ProjectsService.upsertProject(vm.project)
              .then(function (project) {
                return ProjectsService.saveMaximumSeats(project.id,vm.maxSeats,vm.noSeats)
              })
              .then(function(){
                vm.noSeats = false;
                $state.go('^.list');
              });
            };
          },
          resolve: {
            hasAccess: function($state,currentUser){
              if(currentUser.roles.length===0) $state.go('^'); 
              return;
            },
            project: function () {
              return {};
            }
          }
        })
        .state('app.projects.edit', {
          url: '/:id/edit',
          templateUrl: 'app/modules/projects/views/form.html',
          controllerAs: 'ctrl',
          controller: function ($q,$state, ProjectsService, project,FileService,CoreService,CONFIG,$timeout) {
            var vm = this;
            vm.project = project;
            vm.project.images = vm.project.images || {};
            vm.formFields = ProjectsService.getFormFields(project.id);
            vm.formOptions = {};
            vm.submit = function () {
                ProjectsService.togglePublic(vm.project)
                .then(function(res){
                  delete vm.project.isPublic;
                  return $q.all([
                    ProjectsService.upsertProject(vm.project),
                    ProjectsService.saveMaximumSeats(project.id,vm.maxSeats,vm.noSeats)
                  ])
                  .then(function () {
                    $state.go('^.list');
                  });
                });
            };
            
            vm.noSeats=true;
            ProjectsService.getMaximumSeats(project.id).then(function(seats){
              if(seats) {
                vm.maxSeats = seats;
                vm.noSeats = false;
              }
            })
            vm.updateSeats = function(seats){
              ProjectsService.saveMaximumSeats(project.id,seats,vm.noSeats)
              .then(function(){
                vm.noSeats = false;
              });
            }
            
            
            vm.deleteBackground = function(item){
                if(item.images && item.images.background)
                FileService.delete(item.images.background.id || item.images.background,function(){
                    $timeout(function(){
                        vm.loadImages(item);
                    },50);
                },function(){})
            }
            vm.imageLoading = vm.loadImages = function (project){
              vm.project.images = vm.project.images || {};
              if (!project.images.background) {
                  vm.backgroundImage = null;
                  vm.imageLoading = null;
                  return;
              }
              var ids = {};
              Object.keys(project.images).forEach(function(prop){
                ids[project.images[prop]]=null;
              })
              return FileService.get({where:{id:{inq:Object.keys(ids)}}}).then(function(res){
                res.data.forEach(function(file){
                  ids[file.id] = file;
                })
                Object.keys(vm.project.images).forEach(function(prop){
                  var id = vm.project.images[prop];
                  vm.project.images[prop] = ids[id];
                })
                vm.backgroundImage = (vm.project.images && vm.project.images.background && vm.project.images.background.id) ? 
                  CoreService.env.cdnUrl+FileService.getPublicContainer(vm.project.images.background.container) +'/'+ vm.project.images.background.data.size["1024x768"] : null;
                vm.imageLoading = null;
                
              })
            }
            vm.loadImages(vm.project);
            
            FileService.setApi('projects',vm.project.id);
            vm.uploader = FileService.uploader({url:'/Projects/'+vm.project.id});
            vm.uploader.filters.push({
              name: 'imageFilter',
              fn: function(item /*{File|FileLikeObject}*/ , options) {
                var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
              }
            });
            // auto-attach the handlers
            vm.uploader.onBeforeUploadItem = function(item) {
              item.headers['x-upload-filename'] = item.file.name;
              item.headers['x-meta-data'] = JSON.stringify({data:
                {size:CONFIG.ProjectBackgroundSizes}
              });
              var url = '/image/uploadFile';
              if(vm.project.images && vm.project.images.background && vm.project.images.background.id) url = '/image/updateFile/'+vm.project.images.background.id;
              item.url = item.url + url;
            };
            
            
            vm.uploader.onCompleteItem = function(fileItem, response, status, headers) {
                console.info('onCompleteItem', fileItem, response, status, headers);
                vm.project.images = vm.project.images || {};
                vm.project.images.background = response.id;
                vm.imageLoading = ProjectsService.upsertProject(vm.project).then(function () { 
                  // $state.reload(true);
                    vm.uploader.queue = [];
                    CoreService.imageInfo();
                    // return $timeout(function(){
                    //     return vm.loadImages(vm.project);
                    // },500);
                });
            };
            
          },
          resolve: {
            project: function ($stateParams, ProjectsService) {
              return ProjectsService.getProject($stateParams.id);
            }
          }
        })
        .state('app.projects.view', {
          url: '/:id',
          abstract:true,
          templateUrl: 'app/modules/projects/views/view.html',
          controllerAs: 'ctrl',
          controller: function ($timeout,$state,project,currentUser) {
            var vm = this;
            vm.project = project;
            vm.activeTab = null;
            vm.tabs=[{
              state:'.syllabus',
              label:'Syllabus'
            },{
              state:'.users',
              label:'Users'
            },{
              state:'.teams',
              label:'Teams'
            },{
              state:'.lectures',
              label:'Lectures'
            },{
              state:'.assessments',
              label:'Assessments'
            },
            // {
            //   state:'.casestudies',
            //   label:'Case Studies'
            // },
            {
              state:'.files.list',
              label:'Files'
            }];
          },
          resolve: {
            project: function ($stateParams, ProjectsService) {
              return ProjectsService.getProject($stateParams.id);
            }
          }
        })
        .state('app.projects.view.syllabus', {
          url: '/syllabus',
          template: '<project-syllabus project="ctrl.project"></project-syllabus>'
        })
        .state('app.projects.view.users', {
          url: '/users',
          template: '<project-users project="ctrl.project"></project-users>'
        })
        .state('app.projects.view.teams', {
          url: '/teams',
          template: '<project-teams project="ctrl.project"></project-teams>'
        })
        .state('app.projects.view.lectures', {
          url: '/lectures',
          template: '<project-lectures project="ctrl.project"></project-lectures>'
        })
        .state('app.projects.view.assessments', {
          url: '/assessments',
          template: '<project-assessments project="ctrl.project"></project-assessments>'
        })
        .state('app.projects.view.casestudies', {
          url: '/casestudies',
          template: '<project-case-studies project="ctrl.project"></project-case-studies>'
        })
        .state('app.projects.view.files', {
          abstract: true,
          url: '/files',
          templateUrl: 'app/modules/files/views/main.html',
          controller:function($scope){
            $scope.projectFiles = true;
          }
        })
        .state('app.projects.view.files.list', {
          url: '',
          templateUrl: 'app/modules/files/views/list.html',
          controllerAs: 'ctrl',
          controller: 'FilesListController'
        })
        .state('app.projects.view.files.upload', {
          url: '/upload',
          templateUrl: 'app/modules/files/views/upload.html',
          controllerAs: 'ctrl',
          controller: function (FileUploader, FileService,$stateParams) {
            FileService.setApi('projects',$stateParams.id);
            this.uploader = FileService.uploader({url:'/Projects/'+$stateParams.id});
            // auto-attach the handlers
            this.uploader.onBeforeUploadItem = function(item) {
              item.headers['x-upload-filename'] = item.file.name;
              item.headers['x-meta-data'] = JSON.stringify({data:JSON.parse(item.file.data)});
              item.url = item.url + '/' + item.file.componentModel + '/uploadFile';
            };
          }
        })
        
        
        .state('app.projects.delete', {
          url: '/:id/delete',
          template: '',
          controllerAs: 'ctrl',
          controller: function ($state, ProjectsService, project) {
            ProjectsService.deleteProject(project.id, function () {
              $state.go('^.list');
            }, function () {
              $state.go('^.list');
            });
          },
          resolve: {
            project: function ($stateParams, ProjectsService) {
              return ProjectsService.getProject($stateParams.id);
            }
          }
        });
    }
  );

})();
