'use strict';

angular.module('com.module.projects')
    .config(function ($stateProvider) {
      $stateProvider
        .state('app.projects.view.assessments.edit', {
          url: '/:assessmentId',
          template: '<ui-view></ui-view>',
          abstract:true
        })
        .state('app.projects.view.assessments.edit.proposals', {
          url: '/proposals',
          templateUrl: 'app/modules/projects/components/project-assessments/proposals-list.html',
          controllerAs: 'ctrl',
          controller: function ($scope, $stateParams, AssessmentService,CoreService,$q) {
            var vm = this;
            
            vm.assessmentId = $stateParams.assessmentId;
            vm.cdn = CoreService.env.cdnUrl;
            load();
            function load(){
              vm.promise = AssessmentService.getProposals(vm.assessmentId).then(function(res){
                vm.proposals = res.data;
              });
            }
            vm.assessmentScope = $scope.$parent.$parent.vm;
            
            vm.acceptProposal = function(id){
              vm.assessmentScope.item.publication = id;
              AssessmentService.save(vm.assessmentScope.item);
              
              var p=[];
              // reject first all the accepted proposals
              vm.proposals
                .filter(function(item){return item.publicationStatus==='accepted'})
                .forEach(function(item){
                  p.push(AssessmentService.rejectProposal(item.id));
                })
              // now accept the one
              p.push(AssessmentService.acceptProposal(id))
             
              $q.all(p).then(function(result){
                load();
              });
            }
            vm.rejectProposal = function(id){
              AssessmentService.rejectProposal(id).then(load);
              vm.assessmentScope.item.publication = vm.assessmentScope.item.publication===id?null:vm.assessmentScope.item.publication;
              AssessmentService.save(vm.assessmentScope.item);
            }
            vm.view = CoreService.viewElement;
            vm.deleteProposal = function(id){
              if(vm.assessmentScope.item.publication === id) {
                window.alert('You cant delete this because its currently the published one, please accept another one first and then delete it');
              } else {
                CoreService.confirm('Are you sure?','Deleting this cannot be undone',
                  function () {
                    //   confirm
                    AssessmentService.deleteProposal(id).then(function (res) {
                        CoreService.success('Deleted successfully',res.data);
                        load();
                    }).catch(CoreService.error);
                  },
                  function () {
                    //   cancel
                  }
                );
              }
            }
          }
        })
        .state('app.projects.view.assessments.edit.upload', {
          url: '/upload-proposal',
          templateUrl: 'app/modules/files/views/upload.html',
          controllerAs: 'ctrl',
          controller: function (FileUploader, FileService,$stateParams) {
            FileService.setApi('assessments',$stateParams.assessmentId);
            this.uploader = FileService.uploader({url:'/Assessments/'+$stateParams.assessmentId+'/uploadProposal'});
            // auto-attach the handlers
            this.uploader.onBeforeUploadItem = function(item) {
              item.file.componentModel = '';
              item.url = item.url + '/uploadProposal';
            };
          }
        })
        
    }
  );
