(function () {
  'use strict';

  angular
    .module('com.module.core')
    .service('Validators', function () {
    
        var vm =  {
            'email':validateEmail,
            'emailRaw':new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        };
        
        function validateEmail(email) {
            return vm.emailRaw.test(email);
        };
        
        
        return vm
        
    });
})();