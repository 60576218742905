angular
    .module('com.module.projects')
    .component('projectSyllabus', {
        templateUrl: 'app/modules/projects/components/project-syllabus/template.html',
        bindings: {
            'project': '='
        },
        controllerAs: 'vm',
        controller: function($scope,$uibModal, SyllabusService, GridHelper,CoreService,$state) {
            var vm = this;
            vm.config={
                controllerAs:'vm'
            };

            // init config
            SyllabusService.setProject(vm.project);
            
            // fetch syllabus or create on the fly
            SyllabusService.get().then(function(res){
                vm.item = res.data;
            }).catch(function(res){
                if(res.status===404) {
                    // auto create one
                    console.log(vm.project);
                    var item ={
                        name:vm.project.name,
                        moduleNumber:vm.project.moduleNumber,
                        website:vm.project.website,
                        description:vm.project.description
                    };
                    SyllabusService.save(item).then(function(res){
                        vm.item = res.data;
                        console.log(vm.item);
                    });
                }
            })
            
            
            /* Single */
            
            vm.item = {};
            vm.formSchema = SyllabusService.getFormSchema();
            vm.submit = function(){
                var item = vm.item;
                vm.promise = SyllabusService.save(item)
                    .then(function(res){
                        CoreService.success('Saved successfully',res.data.id);
                        vm.item = res.data;
                    }).catch(CoreService.error);
            };
            
            
        }

    })