(function () {
  'use strict';
  angular
    .module('com.module.customstyles')
    .config(function ($stateProvider) {
      $stateProvider
        .state('app.customstyles', {
          abstract: true,
          url: '/customstyles',
          templateUrl: 'app/modules/customstyles/views/main.html'
        })
        .state('app.customstyles.list', {
          url: '/list',
          templateUrl: 'app/modules/customstyles/views/list.html',
          controllerAs: 'ctrl',
          controller: function (CustomStylesService) {
            var vm = this;
            
            vm.loading = CustomStylesService.find().then(function(res){
              vm.customstyles = res.data;
            })
            
            
          }
        })
        .state('app.customstyles.add', {
          url: '/add',
          templateUrl: 'app/modules/customstyles/views/form.html',
          controllerAs: 'ctrl',
          controller:'CustomStylesSingleController',
          resolve: {
            customstyle: function () {
              return {data:{}};
            },
            previewComponent: function($stateParams, Setting) {
              return Setting.find({
                filter: {
                  where: {
                    key: 'admin.configuration'
                  }
                }
              }).$promise.then(function(res) {
                return res[0].data.stylePreviewLectureLink;
              });
            }
          }
        })
        .state('app.customstyles.edit', {
          url: '/:id/edit',
          templateUrl: 'app/modules/customstyles/views/form.html',
          controllerAs: 'ctrl',
          controller:'CustomStylesSingleController',
          resolve: {
            customstyle: function($stateParams, CustomStylesService) {
              return CustomStylesService.findById($stateParams.id);
            },
            previewComponent: function($stateParams, Setting) {
              return Setting.find({
                filter: {
                  where: {
                    key: 'admin.configuration'
                  }
                }
              }).$promise.then(function(res) {
                return res[0].data.stylePreviewLectureLink;
              });
            }
          }
        })
        .state('app.customstyles.delete', {
          url: '/:id/delete',
          template: '',
          controllerAs: 'ctrl',
          controller: function ($stateParams, $state, CustomStylesService) {
            CustomStylesService.delete($stateParams.id, function () {
              $state.go('^.list');
            }, function () {
              $state.go('^.list');
            });
          }
        });
    });

})();
