(function () {
  'use strict';
  angular
    .module('com.module.files')
    .run(function ($rootScope, $http, CoreService, gettextCatalog, FileService) {

      // FileService.find()
      //   .then(function (data) {
      //     $rootScope.addDashboardBox(gettextCatalog.getString('Files'), 'bg-blue', 'ion-paperclip', data.length, 'app.files.list');
      //   });

    });

})();
