(function () {
  'use strict';
  /**
   * @ngdoc function
   * @name com.module.core.controller:MainCtrl
   * @description Login Controller
   * @requires $scope
   * @requires $state

   * @requires CoreService
   * @requires SystemUser
   * @requires gettextCatalog
   **/
  angular
    .module('com.module.core')
    .controller('MainCtrl', function ($timeout,$scope, $rootScope,currentUser, $state, AppAuth, CoreService, SystemUser, gettextCatalog, ProjectsService) {
      
      var vm = $scope;
  
      vm.currentUser = currentUser;
      vm.siteUrl = CoreService.env.siteUrl;
      
      /*Menu Configuration*/
      $rootScope.clearMenu();
      $rootScope.addMenu(gettextCatalog.getString('Projects'), 'app.projects.list', 'fa-calendar-o');
      
      if(vm.currentUser.roles.indexOf('superadmin')>=0) {
        $rootScope.addMenu(gettextCatalog.getString('Users'), 'app.users', 'fa-user');
        $rootScope.addMenu(gettextCatalog.getString('Organizations'), 'app.organizations.list', 'fa-sitemap');
        $rootScope.addMenu(gettextCatalog.getString('Files'), 'app.files.list', 'fa-file');
        $rootScope.addMenu(gettextCatalog.getString('Grading Schemes'),'app.gradingschemes.list', 'fa-file-text');
        $rootScope.addMenu(gettextCatalog.getString('SSO Providers'),'app.authproviders.list', 'fa-id-card');
        $rootScope.addMenu(gettextCatalog.getString('Reporting'),'app.reporting.list', 'fa-table');
        $rootScope.addMenu(gettextCatalog.getString('Custom Styles'),'app.customstyles.list', 'fa-paint-brush');
        $rootScope.addMenu(gettextCatalog.getString('Settings'),'app.settings.list', 'fa-cog');
        $rootScope.addMenu(gettextCatalog.getString('Permissions'),'app.permissions.list', 'fa-key');
      }
      
      vm.menuoptions = $rootScope.menu;   
      
      vm.isMenuActive = function(state){
        if(state==='app.users') return $state.is(state);
        var s = state.substring(0,state.lastIndexOf('.'));
        if(s.indexOf('.')===-1) return false;
        return $state.includes(s)
      }
        
      vm.logout = function () {
        AppAuth.logout(function () {
          CoreService.toastSuccess(gettextCatalog.getString('Logged out'),
            gettextCatalog.getString('You are logged out!'));
          $state.go('login');
        });
      };

    });

})();
