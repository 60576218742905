(function () {
  'use strict';
  angular
    .module('com.module.reporting')
    .config(function ($stateProvider) {
      $stateProvider
        .state('app.reporting', {
          abstract: true,
          url: '/reporting',
          templateUrl: 'app/modules/reporting/views/main.html'
        })
        .state('app.reporting.list', {
          url: '/list',
          templateUrl: 'app/modules/reporting/views/list.html',
          controllerAs: 'ctrl',
          controller: function (ReportingService) {
            var vm = this;
            
            vm.loading = ReportingService.find().then(function(res){
              vm.reporting = res.data;
            })
            
            
          }
        })
        .state('app.reporting.add', {
          url: '/add',
          templateUrl: 'app/modules/reporting/views/form.html',
          controllerAs: 'ctrl',
          controller:'ReportingSingleController',
          resolve: {
            report: function () {
              return {data:{}};
            },
            previewComponent: function($stateParams, Setting) {
              return Setting.find({
                filter: {
                  where: {
                    key: 'admin.configuration'
                  }
                }
              }).$promise.then(function(res) {
                return res[0].data.stylePreviewLectureLink;
              });
            }
          }
        })
        .state('app.reporting.edit', {
          url: '/:id/edit',
          templateUrl: 'app/modules/reporting/views/form.html',
          controllerAs: 'ctrl',
          controller:'ReportingSingleController',
          resolve: {
            report: function($stateParams, ReportingService) {
              return ReportingService.findById($stateParams.id);
            },
            previewComponent: function($stateParams, Setting) {
              return Setting.find({
                filter: {
                  where: {
                    key: 'admin.configuration'
                  }
                }
              }).$promise.then(function(res) {
                return res[0].data.stylePreviewLectureLink;
              });
            }
          }
        })
        .state('app.reporting.view', {
          url: '/:id',
          templateUrl: 'app/modules/reporting/views/view.html',
          controllerAs: 'ctrl',
          controller: function (report, CoreService, OrganizationService, ProjectsService, ReportingService, LoopBackAuth ) {
            var vm = this;
            
            var options = {
              // onChange: dataChanged,
              mode: 'tree',
              modes: [
                // 'code', 
                // 'form', 
                'text',
                'tree',
                // 'view'
              ], // allowed modes
              onError: function(err) {
                alert(err.toString());
              },
              onModeChange: function(newMode, oldMode) {
                console.log('Mode switched from', oldMode, 'to', newMode);
              }
            }
            var sourceDataPreview = new JSONEditor(document.getElementById("sourceDataPreview"), options);
            var outputDataPreview = new JSONEditor(document.getElementById("outputDataPreview"), options);
            
            vm.report = report.data;
            vm.selectedOrg = {};
            vm.availableOrgs = [];
            vm.availableProjects = [];
            OrganizationService.get().then(function(data){vm.availableOrgs = data;});
            
            
            vm.searchLoader = null;
            vm.searchProject = function(item) {
              // if (!name || name.length < 2) return;
              vm.searchLoader = ProjectsService.get({
                where: {
                  organizationId: item.id,
                  // name: {
                  //   like: name,
                  //   options: 'i'
                  // }
                }
              }).then(function(data) {
                vm.availableProjects = data;
                vm.searchLoader = null;
                return vm.availableProjects;
              });
            };
            vm.copySourceDataLink = function(){
              var url=CoreService.env.apiUrl+'/'
              // LoopBackAuth.accessTokenId
              switch (vm.report.sourceAPI.func) {
                case 'getAssessmentReportData':
                  if(!vm.selectedOrg.id) return;
                  url +='Organizations/'+vm.selectedOrg.id+'/getAssessmentReportData?access_token='+LoopBackAuth.accessTokenId
                  
                  if(vm.selectedProject) {
                    url+='&projectId='+vm.selectedProject.id
                  }
                  if(vm.selectedAssessment) {
                    url+='&assessmentId='+vm.selectedAssessment.id
                  }
                  break;
                default:
              }
              console.log("copySourceDataLink", url);
              vm.copyToClipboard(url);
            }
            
            vm.loadSourceData = function() {
              var config = {};
              var p = null;
              switch (vm.report.sourceAPI.func) {
                case 'getAssessmentReportData':
                  if(!vm.selectedOrg.id) return;
                  config.organizationId = vm.selectedOrg.id
                  if(vm.selectedProject) {
                    config.projectId = vm.selectedProject.id;
                  }
                  vm.searchLoader = true;
                  p = OrganizationService.getAssessmentReportData(config).then(function(data){
                    vm.sourceData = data;
                    sourceDataPreview.set(data);
                    vm.searchLoader = null;
                  })
                  // code
                  break;
                
                default:
                  console.error("Function not found");
                  // code
              }
              p.then(function(){
                vm.processData();
              })
            }
            
            vm.processData = function(){
              try {
                var fn = new Function("data",vm.report.preprocessFn);
                vm.outputData = fn(vm.sourceData);
                outputDataPreview.set(vm.outputData);
                vm.error = null;
                return true;
              } catch(err){
                console.log(err);
                vm.error = err.toString();
                return false;
              }
            }
            
            //helper functions
            vm.downloadObjectAsJson = CoreService.downloadObjectAsJson;
            vm.copyToClipboard = CoreService.copyToClipboard;
            
            vm.updateProcessFn = function(){
              var success = vm.processData();
              if(success) {
                ReportingService.upsert(report)
              }
            }
            
            
            console.log("Report", report);
          },
          resolve: {
            report: function ($stateParams, ReportingService) {
              return ReportingService.findById($stateParams.id);
            }
          }
        })
        .state('app.reporting.delete', {
          url: '/:id/delete',
          template: '',
          controllerAs: 'ctrl',
          controller: function ($stateParams, $state, ReportingService) {
            ReportingService.delete($stateParams.id, function () {
              $state.go('^.list');
            }, function () {
              $state.go('^.list');
            });
          }
        });
    });

})();
