'use strict';

angular.module('com.module.users')
  .service('UService', function($q,$http,CONFIG,CoreService,SchemaMaker,Validators,OrganizationUsers) {
    var vm = this;
    vm.filters = {};
    // basic endpoint
    vm.endpoint = function(){  return CoreService.env.apiUrl+'/SystemUsers';};
    
    // basic schema
    vm.schema = {
        "firstName": {
            "type": "string"
        },
        "lastName": {
            "type": "string"
        },
        "email": {
            "type": "string"
        },
        "emailVerified": {
            "type": "boolean"
        },
        "systemRoles.name": {
            "type": "relation"
        }
    };
    
    // create schema for angular-formly
    var originalEmailValue;
    vm.getFormSchema = function () {
        return [
          {
            key: 'firstName',
            type: 'input',
            templateOptions: {
              label: 'First Name'
            }
          },
          {
            key: 'lastName',
            type: 'input',
            templateOptions: {
              label: 'Last Name'
            }
          },
          {
            key: 'email',
            type: 'input',
            templateOptions: {
              label: 'Email',
              required:true
            },
            expressionProperties: {
              // 'templateOptions.disabled':'model.id'
            },
            asyncValidators: {
              emailValid: {
                expression: function($viewValue, $modelValue, scope) {
                  var p = $q.defer();
                  console.log($viewValue,$modelValue,scope.fc);
                  if(!originalEmailValue && scope.fc.$viewValue===scope.fc.$modelValue) {
                    originalEmailValue = scope.fc.$modelValue;
                  } else if(scope.fc.$viewValue===originalEmailValue) {
                    p.resolve(true);
                  } else if (Validators.email($viewValue)) {
                    scope.formOptions.loading = true;
                    vm.emailValid($viewValue).then(function(res) {
                      scope.formOptions.loading = false;
                      if (res.data.exists) {
                        p.reject('exist');
                        CoreService.toastWarning('Validation error', 'Email already exists!');
                      }
                      else {
                        p.resolve(true);
                        CoreService.toastSuccess('Valid Email');
                      }
                    });
                  }
                  else {
                    p.reject('Not Valid');
                  }

                  return p.promise;
                }
              }
            },
            modelOptions: {
              allowInvalid: false, updateOn: 'default', debounce: 500
            }
          },
          {
            key: 'emailVerified',
            type: 'checkbox',
            templateOptions: {
              label: 'Verified',
            }
          }
        ];    
    };
    
    // auto create schema for ui-grid;
    vm.gridSchema = SchemaMaker.uiGrid(vm.schema,vm.filters);
    
    /* CRUD methods */
    vm.get = function(filters){
        filters = filters || {};
        filters = Object.assign({},filters,vm.filters)
        return $http.get(vm.endpoint(),{
            params: {
                filter: filters
            }
        });
    }
    vm.save = function(item){
      if(item.id) return $http.post(vm.endpoint()+'/'+item.id + '/updateProfile',{
        id: item.id,
        firstName: item.firstName,
        lastName: item.lastName,
        email: item.email,
        emailVerified: item.emailVerified
      });
      else return $http.post(vm.endpoint()+'/invite',item);
    } 
    vm.deleteBulk = function(ids){
      return $http.post(CoreService.env.apiUrl+'/SystemUsers/deleteBulk',ids);
    }
    vm.delete = function(item){
      return $http.get(CoreService.env.apiUrl+'/SystemUsers/'+item.id+'/organizations')
        // remove him from organizations too
        // .then(function(res){
        //   var orgs = res.data;
        //   var p=[];
        //   if(orgs.length>0) {
        //     orgs.forEach(function(org){
        //       p.push(OrganizationUsers.unlink(item.id,org.id));  
        //     });
        //   }
        //   return $q.all(p);
          
        // })
        .then(function(){
          return $http.delete(vm.endpoint()+'/'+item.id);
        })
    }
    
    /* User seats management */
    vm.getUserSeats = function(userId){
      return $http.get(CoreService.env.apiUrl+'/SystemUsers/'+userId+'/account').then(function(res){
        return res.data;
      }).catch(function(err){
        if(err.status===404){
          return {
            systemuserId:userId,
            seats:0
          }
        }
      });
    }
    vm.updateUserSeats = function(userId,seats){
      return $http.post(CoreService.env.apiUrl+'/SystemUsers/updateSeatsForUser/'+userId,{seats:seats});
    }
    
    
    /* Extra Methods */
    vm.emailValid = function(email){
      return $http.get(vm.endpoint()+'/emailExists/'+email,{cache:true});
    }
      
    vm.userSessions = function(userId){
      return $http.get(CoreService.env.apiUrl+'/SystemUsers/'+userId+'/userSessions');
    }
    
    vm.createTempSession = function(userId){
      return $http.get(CoreService.env.apiUrl+'/SystemUsers/'+userId+'/createTempSession');
    }
    
      
    return vm;
  });
