angular
    .module('com.module.projects')
    .component('projectLectures', {
        templateUrl: 'app/modules/projects/components/project-lectures/template.html',
        bindings: {
            'project': '='
        },
        controllerAs: 'vm',
        controller: function($scope,$uibModal, LectureService, GridHelper,CoreService,$state) {
            var vm = this;

            // init config
            LectureService.setProject(vm.project);
            vm.config={
                controllerAs:'vm'
            };
            
            /* List */
            var schema = LectureService.gridSchema;
            vm.pagination = {
                pageNumber: 1,
                pageSize: 25,
                sort: null
            };
            vm.filters = {};
            vm.loadListData = function(filters) {
                
                filters = filters || vm.filters;
                console.log("Req Filter", filters);
                
                // when you search make sure you dont skip records
                if (filters.where && Object.keys(filters.where).length > 0) {
                    vm.pagination.pageNumber = 1;
                    filters.skip = 0;
                }
              
                vm.promise = LectureService.get(filters).then(function(res) {
                    var headers = res.headers();
                    vm.gridOptions.totalItems = Number(headers['x-total-count']);
                    vm.gridOptions.data = res.data.sort(function(a,b){return a.order-b.order;}); //postProcess(res.data);
                });
            };
            
            
            vm.gridOptions = GridHelper.gridOptions(schema, vm.loadListData, vm.pagination, vm.filters,vm.config);
            vm.gridOptions.rowTemplate= '<div grid="grid" class="ui-grid-draggable-row" draggable="true"><div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader, \'custom\': true }" ui-grid-cell></div></div>'

            vm.loadListData();
            // ui-grid-draggable-rows
            vm.dragEnable = true;
            vm.gridOptions.onRegisterApi = function (gridApi) {
                
                gridApi.draggableRows.on.rowFinishDrag(null, function () {
                    vm.changeOrder(vm.gridOptions.data)
                });
                
            };
            
            /* reorder function */
            vm.changeOrder = function(lectures){
                var changed=false;
                var ids=[];
                for (var i = 0; i < lectures.length; i++) {
                    ids.push(lectures[i].id);
                    if(lectures[i].order!==i) {
                        lectures[i].order = i;
                        changed=true;
                    }
                }
                if(changed) LectureService.saveOrder(ids).then(CoreService.success).catch(CoreService.error);
                else CoreService.warning({},'Order not changed')
            }
            
            /* Single */
            
            vm.item = {};
            vm.formSchema = LectureService.getFormSchema();
            vm.closeForm = function(){
                vm.formOpen = false;            
                vm.loadListData();
            };
            vm.viewElement = CoreService.viewElement;
            vm.createElement = function(item){
                console.log("Create",item);
                vm.formOpen = !vm.formOpen;
                vm.item={};
            };
            vm.editElement = function(item){
                console.log("Edit",item);
                vm.formOpen = !vm.formOpen;
                vm.item=item;
                $state.go('app.projects.view.lectures.edit.proposals',{lectureId:item.id});
            };
            vm.deleteElement = function(item){
                console.log("Delete",item);     
                CoreService.confirm('Are you sure?','Deleting this cannot be undone',
                  function () {
                    //   confirm
                    LectureService.delete(item).then(function () {
                        CoreService.success('Deleted successfully',(item.name||item.id)+' deleted!');
                        vm.loadListData();
                    }).catch(CoreService.error);
                  },
                  function () {
                    //   cancel
                  }
                );
            };
            vm.submit = function(){
                var item = vm.item;
                vm.promise = LectureService.save(item)
                    .then(function(res){
                        CoreService.success('Saved successfully',res.data.id);
                        vm.formOpen = !vm.formOpen;
                        vm.item=null;
                        vm.loadListData();
                    }).catch(CoreService.error);
            };
            
            
        }

    })