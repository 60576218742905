(function() {
  'use strict';
  angular
    .module('com.module.core')
    .service('CloudConfigService', function($q, $http, CoreService, AuthProvidersService, ProjectsService) {
      var vm = this;
      
      

      // create schema for angular-formly
      vm.getFormSchema = function(organizationId) {
        return [
          {
            key: 'domain',
            type: 'input',
            templateOptions: {
              label: 'Target Domain',
              required: false
            }
          },
          {
            key: 'auth_provider',
            type: 'select',
            templateOptions: {
              label: 'Login/Auth Provider',
              options: [],
              valueProp: 'value',
              labelProp: 'label',
              required: false,
              placeholder: 'Select Auth Provider'
            },
            controller: /* @ngInject */ function($scope) {
              AuthProvidersService.find().then(function(res){
                console.log("AuthProviders",res.data)
                $scope.to.options = [{
                  label: "SYLVA Password",
                  value: "sylva_password",
                }].concat(res.data.map(function(p){
                  return {
                    label: p.label,
                    value: p.id
                  }
                }))
              })
            }
          },
          {
            key: 'sso_autoprovision',
            type: 'checkbox',
            templateOptions: {
              label: 'Auto-provision users'
            }
          },
          {
            key: 'sso_default_project',
            type: 'select',
            templateOptions: {
              label: 'Default Project',
              options: [],
              valueProp: 'value',
              labelProp: 'label',
              required: false,
              placeholder: 'Select Default Project'
            },
            controller: /* @ngInject */ function($scope) {
              ProjectsService.get({where:{
                organizationId: organizationId
              }}).then(function(data){
                $scope.to.options = data && data.map(function(p){
                  return {
                    label: p.name,
                    value: p.id
                  }
                }) || [];
              })
            }
          },
          {
            key: 'page_title',
            type: 'input',
            templateOptions: {
              label: 'Page Title',
              required: false
            }
          },
          {
            key: 'favicon',
            type: 'input',
            templateOptions: {
              label: 'Favicon URL (Optional)',
              required: false
            }
          },
          {
            key: 'logo',
            type: 'input',
            templateOptions: {
              label: 'Logo - small image',
              required: false
            }
          },
          {
            key: 'help_icon',
            type: 'input',
            templateOptions: {
              label: 'Help Icon (Optional)',
              required: false
            }
          },
          {
            key: 'help_title',
            type: 'input',
            templateOptions: {
              label: 'Help Title (Optional)',
              required: false
            }
          },
          {
            key: 'help_url',
            type: 'input',
            templateOptions: {
              label: 'Help Url (Optional)',
              required: false
            }
          },
          {
            key: 'support_email',
            type: 'input',
            templateOptions: {
              label: 'Support Email Sender',
              required: false
            }
          },
          {
            key: 'email_config',
            type: 'textarea',
            templateOptions: {
              label: 'Custom Email Templates (Sendgrid) configuration. Available keys = {template,invitation,forgot_password,login_link}',
              required: false
            }
          },
          {
            key: 'webhook_config',
            type: 'textarea',
            templateOptions: {
              label: 'WebHook Config. Updates per User. Available keys = {enrollment,module_group,module} Available Actions = {started,completed} ',
              required: false
            }
          },
          {
            key: 'custom_config',
            type: 'textarea',
            templateOptions: {
              label: 'Partner custom JSON configuration (Optional)',
              required: false
            }
          },
          {
            key: 'custom_css',
            type: 'textarea',
            templateOptions: {
              label: 'Partner custom CSS (Optional)',
              required: false
            }
          },
          {
            key: 'custom_params',
            type: 'textarea',
            templateOptions: {
              label: 'Custom Parameters (Optional)',
              placeholder: 'custom_control=async&custom_flow_control=2...',
              required: false
            }
          }

        ]
      }




      return vm;
    });

})();
