'use strict';

angular.module('com.module.projects')
  .service('AssessmentService', function($http,CONFIG,CoreService,SchemaMaker) {
    var vm = this;
    vm.project = {};
    vm.setProject=function(project){vm.project=project;};    
    vm.filters = {limit:25,skip:0}
    
    
    /* Assessments Configuration */;
    
    // basic endpoint
    vm.endpoint = function(){ 
        return CoreService.env.apiUrl 
                +'/Projects/'+vm.project.id+'/assessments';
    };
    // basic schema
    vm.schema = {
        "name": {
            "type": "string"
        },
        // "status": {
        //     "type": "string"
        // },
        "publication": {
            "type": "string"
        },
        "type": {
            "type": "string"
        }
        // "order": {
        //     "type": "number"
        // }
    };
    
    // create schema for angular-formly
    vm.getFormSchema = function () {
        return [
          {
            key: 'name',
            type: 'input',
            templateOptions: {
              label: 'Name',
              required: true
            }
          },
          {
            key: 'type',
            type: 'select',
            templateOptions: {
              label: 'Type of assessment',
              options: [
                {id:'assessment'},
                {id:'exam'},
                // {id:'selftest'},
                // {id:'poll'}
              ],
              valueProp: 'id',
              labelProp: 'id',
              required: true,
              placeholder:'Select type of assessment'
            }
          },          
          {
            key: 'description',
            type: 'textarea',
            templateOptions: {
              label: 'Description',
            }
          },
          {
            key: 'startDate',
            type: 'datetimepicker',
            templateOptions: {
              label: 'Start accessible date',
            }
          },
          {
            key: 'endDate',
            type: 'datetimepicker',
            templateOptions: {
              label: 'End accessible date',
            }
          },
          {
            key: 'publicationDate',
            type: 'datetimepicker',
            templateOptions: {
              label: 'Date Published',
            }
          },          
          {
            "type": "checkbox",
            "key": "graded",
            "templateOptions": {
              "label": "Graded"
            }
          },
          {
            key: 'maximumAttempts',
            type: 'input',
            templateOptions: {
              label: 'Maximum attempts',
              type:"number",
              min:1,
              max:20
            }
          },          
          {
            key: 'passingThreshold',
            type: 'input',
            templateOptions: {
              label: 'Passing threshold (1-100)',
              type:"number",
              min:1,
              max:100
            }
          },
          {
            key: 'publicationStatus',
            type: 'input',
            templateOptions: {
              label: 'Status',
            }
          },
          {
            key: 'publication',
            type: 'input',
            templateOptions: {
              label: 'Published Proposal id',
            }
          },          
          {
            key: 'lectureId',
            type: 'select',
            templateOptions: {
              label: 'Lecture',
              options: [],
              valueProp: 'id',
              labelProp: 'name',
              // required: true,
              placeholder:'Select Lecture'
            },
            controller: /* @ngInject */ function($scope, LectureService) {
              LectureService.setProject(vm.project);
              LectureService.get().then(function(res){
                $scope.to.options = res.data;
                // note, the line above is shorthand for:
                // $scope.options.templateOptions.options = data;
                return res.data;
              });
            }
          },
          // {
          //   key: 'order',
          //   type: 'input',
          //   templateOptions: {
          //     label: 'Order',
          //   }
          // }
        ];    
    };
    
    // auto create schema for ui-grid;
    vm.gridSchema = SchemaMaker.uiGrid(vm.schema,vm.filters);
    vm.saveOrder = function(assessmentIds){
      return $http.post(CoreService.env.apiUrl  + '/Projects/'+vm.project.id+'/assessmentOrder/'+assessmentIds.join(','));
    };
    vm.getProposals = function(assessmentId) {
      return $http.get(CoreService.env.apiUrl  + '/Assessments/' + assessmentId+'/proposals',{params:{filter:{include:['file','systemUser']}}});
    };
    vm.acceptProposal = function(id) {
      return $http.put(CoreService.env.apiUrl  + '/AssessmentProposals/'+id,{publicationStatus:'accepted'});
    };
    vm.rejectProposal = function(id) {
      return $http.put(CoreService.env.apiUrl  + '/AssessmentProposals/'+id,{publicationStatus:'rejected'});
    };
    vm.deleteProposal = function(id) {
      return $http.delete(CoreService.env.apiUrl  + '/AssessmentProposals/'+id);
    };
    
    /* CRUD methods */
    vm.get = function(filters){
      filters = filters || {};
      filters = Object.assign({},filters,vm.filters);
      return $http.get(vm.endpoint(),{
          params: {
              filter: filters
          }
      });
    };
    vm.save = function(item){
      if(item.id) return $http.put(vm.endpoint()+'/'+item.id,item);
      else return $http.post(vm.endpoint(),item);
    };   
    vm.delete = function(item){
      return $http.delete(vm.endpoint()+'/'+item.id);
    };
      
    return vm;
  });
