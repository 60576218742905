(function () {
  'use strict';

  /*jshint sub:true*/
  /*jshint camelcase: false */

  angular
    .module('com.module.users')
    .factory('AppAuth', function ($q, SystemUser,$http,LoopBackAuth,CoreService) {
      var self = {
        currentUser:null,
        isAdmin:function(currentUser){
          currentUser = currentUser || self.currentUser || {};
          var p=$q.defer();
          if(currentUser.roles.indexOf("superadmin")>=0) p.resolve(true);
          else p.reject(true);
          return p.promise;
        },
        isCreoUser:function(currentUser){
          currentUser = currentUser || self.currentUser || {};
          var p=$q.defer();
          if(currentUser.roles.indexOf("creouser")>=0) p.resolve(true);
          else p.reject(true);
          return p.promise;
        },
        login: function (options,data,cb) {
          cb = cb || function(){};
          var promise = SystemUser.login(options,data).$promise;
          promise.then(function(res){
            console.log("Successfull Login",res);
              self.currentUser = {
                id:res.user.id,
                email:res.user.email,
                username:res.user.username,
                name:res.user.name,
                surname:res.user.surname,
                roles:res.roles,
                token:{
                  id:res.id,
                  ttl:res.ttl,
                  created:res.created
                }
              };
              cb(null,res);
              return res;
          }).catch(cb);
          return promise;
        },

        logout: function (cb) {
          cb = cb || function(){};
          //Destroy the access token.
          SystemUser.logout(function () {
            console.log("Successfull logout");
            self.currentUser = null;
            cb();
          });
        },
        getAuthenticatedUser: function(cb){
          cb = cb || function(){};
          var deferred = $q.defer();
          if(self.currentUser && !self.currentUser.then) {
            console.log("Found cached:",self.currentUser);
            
            // check if there is a cached user
            deferred.resolve(self.currentUser);
            cb(self.currentUser);
            
          }  else if(self.currentUser && self.currentUser.then){
            console.log("Found Promise:",self.currentUser);
            
            // check if this request is already running
            self.currentUser.then(function(){
              deferred.resolve(self.currentUser);
              cb(self.currentUser);
            });
          } 
          else {
            
            console.log("Nothing Found so starting request:",self.currentUser);
            self.currentUser = SystemUser.currentUser().$promise;
            self.currentUser.then(function(res){
              self.currentUser = res.user;
                                
              if(!window.AESG.canLogin({data:self.currentUser})) {
                // silent logout
                $http.post(CoreService.env.apiUrl + "/SystemUsers/logout",null,{ ignoreLoadingBar: true }).then(function(res){
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                });
                var error = {
                  code: "LOGIN_FAILED",
                  message: "login failed, not enough permissions",
                  name: "Error",
                  status: 401,
                  statusCode: 401
                }
                cb({data:{error:error}});
                return $q.reject({data:{error:error}});
              }
              
              deferred.resolve(self.currentUser);
              cb(self.currentUser);  
            }).catch(function(err){
              // hack logout user
              deferred.resolve({error:err});
            });
          }
          return deferred.promise;
        }
      };
      return self;
    });

})();
