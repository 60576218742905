angular
    .module('com.module.projects')
    .component('projectTeams', {
        templateUrl: 'app/modules/projects/components/project-teams/template.html',
        bindings: {
            'project': '='
        },
        controllerAs: 'vm',
        controller: function($scope,$uibModal, TeamService, GridHelper,CoreService,$state) {
            var vm = this;

            // init config
            TeamService.setProject(vm.project);
            vm.config={
                controllerAs:'vm'
            };
            
            /* List */
            var schema = TeamService.gridSchema;
            vm.pagination = {
                pageNumber: 1,
                pageSize: 25,
                sort: null
            };
            vm.filters = {};
            vm.loadListData = function(filters) {
                
                filters = filters || vm.filters;
                console.log("Req Filter", filters);
                
                // when you search make sure you dont skip records
                if (filters.where && Object.keys(filters.where).length > 0) {
                    vm.pagination.pageNumber = 1;
                    filters.skip = 0;
                }
              
                vm.promise = TeamService.get(filters).then(function(res) {
                    var headers = res.headers();
                    vm.gridOptions.totalItems = Number(headers['x-total-count']);
                    vm.gridOptions.data = res.data.sort(function(a,b){return a.order-b.order;}); //postProcess(res.data);
                });
            };
            
            vm.loadMembers = function(team){
                var teamId = team&&team.id?team.id:vm.item.id
                vm.promise = TeamService.getMembers(teamId).then(function(res){
                    vm.teamMembers = res.data;
                    vm.teamMembersIds = res.data.map(function(u){return u.id});
                })
            }
            
            vm.fillTeams = function(){
                CoreService.confirm('Are you sure?','This will distribute students',
                  function () {
                    //   confirm
                    vm.promise = TeamService.fillTeams().then(function(res){
                        CoreService.success('Success','Available students distributed into '+vm.teams.length+' teams')
                        vm.loadListData();
                        return true;
                    })
                  },
                  function () {
                    //   cancel
                  })
            }
            
            
            vm.gridOptions = GridHelper.gridOptions(schema, vm.loadListData, vm.pagination, vm.filters,vm.config);
            vm.gridOptions.rowTemplate= '<div grid="grid" class="ui-grid-draggable-row" draggable="true"><div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader, \'custom\': true }" ui-grid-cell></div></div>'

            vm.loadListData();
            
            /* Single */
            
            vm.item = {};
            vm.formSchema = TeamService.getFormSchema();    
            
            vm.searchUsers = function(search){
              if(search.length<1) return;
              var searchRegexp = new RegExp(search,'i').toString()
              var filters = {
                limit:20,
                where: 
                // {email:{regexp:searchRegexp}}
                {
                  or: [{
                    email:{regexp:searchRegexp}
                  },{
                    firstName:{regexp:searchRegexp}
                  },{
                    lastName:{regexp:searchRegexp}
                  }]
                }
              };
              
              vm.searchLoader = TeamService.searchUsers(filters).then(function(res) {
                  vm.availableUsers = res.data.filter(function(u){
                      return vm.teamMembersIds.indexOf(u.id)<0;
                  }); //postProcess(res.data);
                  vm.searchLoader = false;
              });
            }
            vm.linkUser = function(user){
                TeamService.linkUser(user.id,vm.item.id).then(function(){
                CoreService.success('Success'); 
                vm.loadMembers();
                    
                }).catch(CoreService.error);
                vm.selected=null;  
                vm.loadMembers();
            };
            vm.unlinkUser = function(user){
                TeamService.unlinkUser(user.id,vm.item.id).then(function () {
                vm.loadMembers();
                }).catch(CoreService.error);
            };
            
            
            
            vm.closeForm = function(){
                vm.formOpen = false;            
                vm.loadListData();
            };
            vm.viewElement = CoreService.viewElement;
            vm.createElement = function(item){
                vm.formOpen = !vm.formOpen;
                vm.item={};
            };
            vm.editElement = function(item){
                vm.formOpen = !vm.formOpen;
                vm.item=item;
                vm.loadMembers(item);
            };
            vm.deleteElement = function(item){
                console.log("Delete",item);     
                CoreService.confirm('Are you sure?','Deleting this cannot be undone',
                  function () {
                    //   confirm
                    TeamService.delete(item).then(function () {
                        CoreService.success('Deleted successfully',(item.name||item.id)+' deleted!');
                        vm.loadListData();
                    }).catch(CoreService.error);
                  },
                  function () {
                    //   cancel
                  }
                );
            };
            vm.submit = function(){
                var item = vm.item;
                vm.promise = TeamService.save(item)
                    .then(function(res){
                        CoreService.success('Saved successfully',res.data.id);
                        vm.formOpen = !vm.formOpen;
                        vm.item=null;
                        vm.loadListData();
                    }).catch(CoreService.error);
            };
            
            
        }

    })