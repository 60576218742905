'use strict';

angular
    .module('com.module.projects')
    .component('projectCaseStudies', {
        templateUrl: 'app/modules/projects/components/project-casestudies/template.html',
        bindings: {
            'project': '='
        },
        controllerAs: 'vm',
        controller: function($scope, $uibModal, CaseStudyService, GridHelper, CoreService, $state, $timeout, FileService, CONFIG,$http) {
            var vm = this;

            // init config
            CaseStudyService.setProject(vm.project);
            vm.config = {
                controllerAs: 'vm'
            };

            /* List */
            var schema = CaseStudyService.gridSchema;
            vm.pagination = {
                pageNumber: 1,
                pageSize: 25,
                sort: null
            };
            vm.filters = {};
            vm.loadListData = function(filters) {

                filters = filters || vm.filters;
                console.log("Req Filter", filters);

                // when you search make sure you dont skip records
                if (filters.where && Object.keys(filters.where).length > 0) {
                    vm.pagination.pageNumber = 1;
                    filters.skip = 0;
                }
                vm.promise = CaseStudyService.get(filters).then(function(res) {
                    var headers = res.headers();
                    vm.gridOptions.data = [];
                    $timeout(function() {
                        vm.gridOptions.totalItems = Number(headers['x-total-count']);
                        vm.gridOptions.data = res.data.sort(function(a, b) {
                            return a.order - b.order;
                        }); //postProcess(res.data);
                    });
                });
            };


            vm.gridOptions = GridHelper.gridOptions(schema, vm.loadListData, vm.pagination, vm.filters, vm.config);
            vm.gridOptions.rowTemplate = '<div grid="grid" class="ui-grid-draggable-row" draggable="true"><div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader, \'custom\': true }" ui-grid-cell></div></div>'

            vm.loadListData();
            // ui-grid-draggable-rows
            vm.dragEnable = true;
            vm.gridOptions.onRegisterApi = function(gridApi) {

                gridApi.draggableRows.on.rowFinishDrag(null, function() {
                    vm.changeOrder(vm.gridOptions.data)
                });
                vm.gridApi = gridApi;

            };

            /* reorder function */
            vm.changeOrder = function(casestudies) {
                var changed = false;
                var ids = [];
                for (var i = 0; i < casestudies.length; i++) {
                    ids.push(casestudies[i].id);
                    if (casestudies[i].order !== i) {
                        casestudies[i].order = i;
                        changed = true;
                    }
                }
                if (changed) CaseStudyService.saveOrder(ids).then(CoreService.success).catch(CoreService.error);
            }

            /* Single */

            vm.item = {};
            vm.formSchema = CaseStudyService.getFormSchema();
            vm.closeForm = function() {
                vm.formOpen = false;
                vm.loadListData();
            };
            vm.viewElement = CoreService.viewElement;
            vm.createElement = function(item) {
                console.log("Create", item);
                vm.formOpen = !vm.formOpen;
                vm.item = {};
            };
            vm.editElement = function(item) {
                console.log("Edit", item);
                vm.formOpen = !vm.formOpen;
                vm.item = item;
                
                vm.item.color_main = vm.item.color_main ||"#fff";
                vm.loadImages(vm.item);
                vm.loadVisitors(vm.item.id);

                vm.searchLoader = CaseStudyService.getTeam(item.id).then(function(res) {
                    vm.item.team = res.data[0];
                    vm.selected = vm.item.team;
                    vm.searchLoader = false;
                })
            };
            vm.deleteElement = function(item) {
                console.log("Delete", item);
                CoreService.confirm('Are you sure?', 'Deleting this cannot be undone',
                    function() {
                        //   confirm
                        CaseStudyService.delete(item).then(function() {
                            CoreService.success('Deleted successfully', (item.name || item.id) + ' deleted!');
                            vm.loadListData();
                        }).catch(CoreService.error);
                    },
                    function() {
                        //   cancel
                    }
                );
            };
            vm.submit = function() {
                var item = vm.item;
                vm.promise = CaseStudyService.save(item)
                    .then(function(res) {
                        CoreService.success('Saved successfully', res.data.id);
                        vm.formOpen = !vm.formOpen;
                        vm.item = null;
                        vm.loadListData();
                    }).catch(CoreService.error);
            };

            /* Visitors */
            
            console.log(CoreService.env);
            vm.abmaUrl = CoreService.env.abmaUrl;
            vm.loadVisitors = function(){
                vm.caseStudyVisitorsLoading = true;
                return CaseStudyService.getTokens(vm.item.id).then(function(res){
                    // console.log("Visitors",res.data);
                    vm.caseStudyVisitors = res.data;
                    vm.caseStudyVisitorsLoading = false;
                })
            }
            vm.addVisitor = function(v){
                vm.caseStudyVisitorsLoading = true;
                var data = {email:v,token:uuid(v.email)};
                CaseStudyService.createToken(vm.item.id,data).then(function(res){
                  vm.loadVisitors();  
                }).catch(CoreService.error);
            }
            vm.removeVisitor = function(v){
                vm.caseStudyVisitorsLoading = true;
                CaseStudyService.deleteToken(vm.item.id,v.id).then(function(res){
                  vm.loadVisitors();  
                }).catch(CoreService.error);
            }
            vm.notifyVisitor = function(v){
                var url = "https://abma.ac/cases/"+vm.item.id+'/presentation/'+v.token;
                var email = v.email;
                window.alert("Not Implemented yet");
            }

            /* Single */

            vm.searchLoader = CaseStudyService.searchTeams({})
                .then(function(res) {
                    vm.searchLoader = false;
                    vm.availableTeams = res.data; //postProcess(res.data);
                })

            vm.selectTeam = function(casestudyId, teamId) {
                console.log(casestudyId, teamId, vm.item);
                var prevTeamId = vm.item.team ? vm.item.team.id : false;

                if (prevTeamId === teamId) return;
                vm.searchLoader = CaseStudyService.linkTeam(casestudyId, teamId).then(function(result) {
                    if (prevTeamId) return CaseStudyService.unlinkTeam(casestudyId, prevTeamId);
                    return Promise.resolve(true);
                }).then(function() {
                    vm.searchLoader = false;
                    vm.closeForm();
                });
            }

            /* Background image upload */

            vm.item.images = vm.item.images || {};

            vm.deleteBackground = function(item){
                if(item.images && item.images.background)
                FileService.delete(item.images.background.id || item.images.background,function(){
                    $timeout(function(){
                        vm.loadImages(item);
                    },50);
                },function(){})
            }
            vm.imageLoading = vm.loadImages = function(item) {
                vm.item.images = vm.item.images || {};
                if (!item.images.background) {
                    vm.imageLoading = null;
                    vm.backgroundImage = null;
                    return;
                }
                var ids = {};
                Object.keys(item.images).forEach(function(prop) {
                    ids[item.images[prop]] = null;
                })
                return FileService.get({
                    where: {
                        id: {
                            inq: Object.keys(ids)
                        }
                    }
                }).then(function(res) {
                    res.data.forEach(function(file) {
                        ids[file.id] = file;
                    })
                    Object.keys(vm.item.images).forEach(function(prop) {
                        var id = vm.item.images[prop];
                        vm.item.images[prop] = ids[id];
                    })
                    vm.backgroundImage = (vm.item.images && vm.item.images.background && vm.item.images.background.id) ? CoreService.env.cdnUrl + vm.item.images.background.container + '/' + vm.item.images.background.data.size["1024x768"] : null;
                    vm.imageLoading = null;
                    
                })
            }

            FileService.setApi('projects', vm.project.id);
            vm.uploader = FileService.uploader({
                url: '/Projects/' + vm.project.id
            });
            vm.uploader.filters.push({
                name: 'imageFilter',
                fn: function(item /*{File|FileLikeObject}*/ , options) {
                    var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                    return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
                }
            });
            // auto-attach the handlers
            vm.uploader.onBeforeUploadItem = function(item) {
                item.headers['x-upload-filename'] = item.file.name;
                item.headers['x-meta-data'] = JSON.stringify({
                    data: {
                        size: CONFIG.ProjectBackgroundSizes
                    }
                });
                var url = '/image/uploadFile';
                vm.item.images = vm.item.images || {};
                if (vm.item.images && vm.item.images.background && vm.item.images.background.id) url = '/image/updateFile/' + vm.item.images.background.id;
                item.url = item.url + url;
            };


            vm.uploader.onCompleteItem = function(fileItem, response, status, headers) {
                console.info('onCompleteItem', fileItem, response, status, headers);
                vm.item.images = vm.item.images || {};
                vm.item.images.background = response.id;
                vm.imageLoading = CaseStudyService.save(vm.item).then(function() {
                    //   $state.reload(true);
                    vm.uploader.queue = [];
                    vm.imageLoading=null;
                    CoreService.imageInfo();
                    // return $timeout(function(){
                    //     return vm.loadImages(vm.item);
                    // },500);
                });
            };


        }

    })