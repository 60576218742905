(function() {
  'use strict';
  angular
    .module('com.module.authproviders')
    .controller('AuthProvidersSingleController', function($timeout, $state, AuthProvidersService, authprovider,CoreService) {
      var vm = this;
      vm.authprovider = authprovider;
      vm.authprovider.data = vm.authprovider.data || {};
      vm.formOptions = {};
      var api = CoreService.env.apiUrl;
      vm.previewUrl = api.substring(0,api.lastIndexOf('/api')) + '/sso/auth-callback/' + vm.authprovider.id;
      vm.samlMetadataUrl = api.substring(0,api.lastIndexOf('/api')) + '/sso/saml-metadata/' + vm.authprovider.id;
      vm.submit = function() {
        AuthProvidersService.upsert(vm.authprovider).then(function() {
          $state.go('^.list');
        });
      };
      
      // create the editor
      var container = document.getElementById("jsoneditor");
      var options = {
        onChange: dataChanged,
        mode: 'tree',
        modes: [
          // 'code', 
          // 'form', 
          'text',
          'tree',
          // 'view'
        ], // allowed modes
        onError: function(err) {
          alert(err.toString());
        },
        onModeChange: function(newMode, oldMode) {
          console.log('Mode switched from', oldMode, 'to', newMode);
        }

      };
      var editor = new JSONEditor(container, options);
      // set json
      editor.set(vm.authprovider.data);
      function dataChanged() {
        var json = editor.get();
        vm.authprovider.data = json;
      }
      
      vm.formFields = [
        {
          className: "col-xs-8",
          key: 'label',
          type: 'input',
          templateOptions: {
            label: 'Label',
            required: true
          }
        },
        {
          className: "col-xs-4",
          key: 'strategy',
          type: 'select',
          templateOptions: {
            required:true,
            label: 'Provider Type',      
            "options": [
              {
                "name": "SAML",
                "value": "saml"
              },
              {
                "name": "OpenID Connect",
                "value": "oidc"
              },
              {
                "name": "Email",
                "value": "email"
              },
              {
                "name": "Phone",
                "value": "phone"
              }
            ]
          }
        },
      ]



    });

})();
