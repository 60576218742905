(function () {
  'use strict';
  /**
   * @ngdoc overview
   * @name loopbackApp
   * @description
   * # loopbackApp
   *
   * Main module of the application.
   */
  angular
    .module('loopbackApp', [
      'angular-loading-bar',
      'angular.filter',
      'angularFileUpload',
      'oitozero.ngSweetAlert',
      'config',
      'formly',
      'formlyBootstrap',
      'naif.base64',
      'lbServices',
      'monospaced.elastic',
      // 'pmImageEditor',
      'angular-busy',
      'ngAnimate',
      'ngCookies',
      'ngResource',
      'ngRoute',
      'ngSanitize',
      'ngTouch',
      'ui.codemirror',
      'ui.bootstrap',
      'ui.bootstrap.datetimepicker',
      'ui.gravatar',
      'ui.grid',
      'ui.grid.importer',
      'ui.grid.autoResize',
      'ui.grid.pagination', 
      'ui.grid.resizeColumns', 
      'ui.grid.grouping',
      'ui.grid.draggable-rows',
      'ui.router',
      'toasty',
      'colorpicker.module',
      'autofields',
      'autofields.bootstrap.validation',
      'gettext',
      'ui.select',
      'com.module.core',
      'com.module.files',
      'com.module.about',
      'com.module.projects',
      'com.module.organizations',
      'com.module.users',
      'com.module.settings',
      'com.module.gradingschemes',
      'com.module.reporting',
      'com.module.authproviders',
      'com.module.customstyles',
      'com.module.permissions',
      // 'com.module.browser',
      // 'com.module.events',
      // 'com.module.notes',
      // 'com.module.pages',
      // 'com.module.posts',
      // 'com.module.products',
      // 'com.module.sandbox',
    ])    
    .config(['$httpProvider',
      function($httpProvider) {
        $httpProvider.defaults.withCredentials = true;
      },
    ])
    .run(function($rootScope,$location){
      
        // handle ui-router error redirect
        $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams, options){
            if(toState.name==='500_API_ERROR') { 
                event.preventDefault(); 
            }
        });

        //handle ui-router errors
        $rootScope.$on('$stateChangeError', function(event, toState, toParams, fromState, fromParams, error) {
            // this is required if you want to prevent the $UrlRouter reverting the URL to the previous valid location
            event.preventDefault();
            console.error('Ui Router error:', event, toState, toParams, fromState, fromParams, error);
            if(error.status===401) $location.path('/login');
            else $location.path('/error');
        });
    })
    
    // .run(function ($rootScope, $cookies, gettextCatalog) {

    //   $rootScope.locales = {
    //     'de': {
    //       lang: 'de',
    //       country: 'DE',
    //       name: gettextCatalog.getString('German')
    //     },
    //     'en': {
    //       lang: 'en',
    //       country: 'US',
    //       name: gettextCatalog.getString('English')
    //     },
    //     'es_MX': {
    //       lang: 'es_MX',
    //       country: 'MX',
    //       name: gettextCatalog.getString('Spanish')
    //     },
    //     'fr': {
    //       lang: 'fr',
    //       country: 'FR',
    //       name: gettextCatalog.getString('Français')
    //     },
    //     'nl': {
    //       lang: 'nl',
    //       country: 'NL',
    //       name: gettextCatalog.getString('Dutch')
    //     },
    //     'pt-BR': {
    //       lang: 'pt_BR',
    //       country: 'BR',
    //       name: gettextCatalog.getString('Portuguese Brazil')
    //     },
    //     'ru_RU': {
    //       lang: 'ru_RU',
    //       country: 'RU',
    //       name: gettextCatalog.getString('Russian')
    //     },
    //     'zh_CN': {
    //       lang: 'zh_CN',
    //       country: 'CN',
    //       name: gettextCatalog.getString('Chinese')
    //     }
    //   };

    //   var lang = $cookies.lang || navigator.language || navigator.userLanguage;

    //   $rootScope.locale = $rootScope.locales[lang];

    //   if (angular.isUndefined($rootScope.locale)) {
    //     $rootScope.locale = $rootScope.locales[lang];
    //     if (angular.isUndefined($rootScope.locale)) {
    //       $rootScope.locale = $rootScope.locales['en'];
    //     }
    //   }

    //   gettextCatalog.setCurrentLanguage($rootScope.locale.lang);

    // })

})();
