'use strict';

angular.module('com.module.projects')
  .service('LectureService', function($http,CONFIG,CoreService,SchemaMaker) {
    var vm = this;
    vm.project = {};
    vm.setProject=function(project){vm.project=project;};    
    vm.filters = {limit:25,skip:0}
    
    
    /* Lectures Configuration */;
    
    // basic endpoint
    vm.endpoint = function(){ 
        return CoreService.env.apiUrl 
                +'/Projects/'+vm.project.id+'/lectures';
    };
    // basic schema
    vm.schema = {
        "name": {
            "type": "string"
        },
        // "status": {
        //     "type": "string"
        // },
        "publication": {
            "type": "string"
        }
        // "order": {
        //     "type": "number"
        // }
    };
    
    // create schema for angular-formly
    vm.getFormSchema = function () {
        return [
          {
            key: 'name',
            type: 'input',
            templateOptions: {
              label: 'Name',
              required: true
            }
          },
          {
            key: 'description',
            type: 'textarea',
            templateOptions: {
              label: 'Description',
            }
          },
          {
            key: 'startDate',
            type: 'datetimepicker',
            templateOptions: {
              label: 'Start accessible date',
            }
          },
          {
            key: 'endDate',
            type: 'datetimepicker',
            templateOptions: {
              label: 'End accessible date',
            }
          },
          {
            key: 'publicationDate',
            type: 'datetimepicker',
            templateOptions: {
              label: 'Date Published',
            }
          },
          {
            key: 'publicationStatus',
            type: 'input',
            templateOptions: {
              label: 'Status',
            }
          },
          {
            key: 'publication',
            type: 'input',
            templateOptions: {
              label: 'Published Proposal id',
            }
          }
          // {
          //   key: 'order',
          //   type: 'input',
          //   templateOptions: {
          //     label: 'Order',
          //   }
          // }
        ];    
    };
    
    // auto create schema for ui-grid;
    vm.gridSchema = SchemaMaker.uiGrid(vm.schema,vm.filters);
    vm.saveOrder = function(lectureIds){
      return $http.post(CoreService.env.apiUrl  + '/Projects/'+vm.project.id+'/lectureOrder/'+lectureIds.join(','));
    };
    vm.getProposals = function(lectureId) {
      return $http.get(CoreService.env.apiUrl  + '/Lectures/' + lectureId+'/proposals',{params:{filter:{include:['file','systemUser']}}});
    };
    vm.acceptProposal = function(id) {
      return $http.put(CoreService.env.apiUrl  + '/LectureProposals/'+id,{publicationStatus:'accepted'});
    };
    vm.rejectProposal = function(id) {
      return $http.put(CoreService.env.apiUrl  + '/LectureProposals/'+id,{publicationStatus:'rejected'});
    };
    vm.deleteProposal = function(id) {
      return $http.delete(CoreService.env.apiUrl  + '/LectureProposals/'+id);
    };
    
    /* CRUD methods */
    vm.get = function(filters){
      filters = filters || {};
      filters = Object.assign({},filters,vm.filters);
      return $http.get(vm.endpoint(),{
          params: {
              filter: filters
          }
      });
    };
    vm.save = function(item){
      if(item.id) return $http.put(vm.endpoint()+'/'+item.id,item);
      else return $http.post(vm.endpoint(),item);
    };   
    vm.delete = function(item){
      return $http.delete(vm.endpoint()+'/'+item.id);
    };
      
    return vm;
  });
