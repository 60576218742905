'use strict';

angular.module('com.module.projects')
    .config(function ($stateProvider) {
      $stateProvider
        .state('app.projects.view.lectures.edit', {
          url: '/:lectureId',
          template: '<ui-view></ui-view>',
          abstract:true
        })
        .state('app.projects.view.lectures.edit.proposals', {
          url: '/proposals',
          templateUrl: 'app/modules/projects/components/project-lectures/proposals-list.html',
          controllerAs: 'ctrl',
          controller: function ($scope, $stateParams,FileService, LectureService,CoreService,$q) {
            var vm = this;
            
            vm.lectureId = $stateParams.lectureId;
            vm.cdn = CoreService.env.cdnUrl;
            load();
            function load(){
              vm.promise = LectureService.getProposals(vm.lectureId).then(function(res){
                vm.proposals = res.data
                .map(function(item){
                  item.file.container = FileService.getPublicContainer(item.file.container);
                  return item;
                });
              });
            }
            vm.lectureScope = $scope.$parent.$parent.vm;
            
            vm.acceptProposal = function(id){
              vm.lectureScope.item.publication = id;
              LectureService.save(vm.lectureScope.item);
              
              var p=[];
              // reject first all the accepted proposals
              vm.proposals
                .filter(function(item){return item.publicationStatus==='accepted'})
                .forEach(function(item){
                  p.push(LectureService.rejectProposal(item.id));
                })
              // now accept the one
              p.push(LectureService.acceptProposal(id))
             
              $q.all(p).then(function(result){
                load();
              });
            }
            vm.rejectProposal = function(id){
              LectureService.rejectProposal(id).then(load);
              vm.lectureScope.item.publication = vm.lectureScope.item.publication===id?null:vm.lectureScope.item.publication;
              LectureService.save(vm.lectureScope.item);
            }
            vm.view = CoreService.viewElement;
            vm.deleteProposal = function(id){
              if(vm.lectureScope.item.publication === id) {
                window.alert('You cant delete this because its currently the published one, please accept another one first and then delete it');
              } else {
                CoreService.confirm('Are you sure?','Deleting this cannot be undone',
                  function () {
                    //   confirm
                    LectureService.deleteProposal(id).then(function (res) {
                        CoreService.success('Deleted successfully',res.data);
                        load();
                    }).catch(CoreService.error);
                  },
                  function () {
                    //   cancel
                  }
                );
              }
            }
          }
        })
        .state('app.projects.view.lectures.edit.upload', {
          url: '/upload-proposal',
          templateUrl: 'app/modules/files/views/upload.html',
          controllerAs: 'ctrl',
          controller: function (FileUploader, FileService,$stateParams) {
            FileService.setApi('lectures',$stateParams.lectureId);
            this.uploader = FileService.uploader({url:'/Lectures/'+$stateParams.lectureId+'/uploadProposal'});
            // auto-attach the handlers
            this.uploader.onBeforeUploadItem = function(item) {
              item.file.componentModel = '';
              item.url = item.url + '/uploadProposal';
            };
          }
        })
        
    }
  );
