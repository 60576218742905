(function () {
  'use strict';
  angular
    .module('com.module.files')
    .service('FileService', function ($http, CONFIG, CoreService, SchemaMaker,FileUploader, uiGridConstants, gettextCatalog,LoopBackAuth) {
      var vm = this;
      vm.api = CoreService.env.apiUrl+'/Files';
      
      vm.getPublicContainer = function(container){
        return CoreService.env.StorageContainers.filter(function(c){return c.value===container})[0].id;
      };
      
      vm.setApi=function(type,id){
        
        var schema = Object.assign({},vm.schema);
        
        switch (type) {
          case 'lectures':
          vm.api = CoreService.env.apiUrl+'/Lectures/'+id;
          vm.uploadApi = CoreService.env.apiUrl+'/Lectures/'+id;
          break;
          
          case 'projects':
          vm.api = CoreService.env.apiUrl+'/Projects/'+id+'/files';
          vm.uploadApi = CoreService.env.apiUrl+'/Projects/'+id;
          
          // custom projects schema
          delete schema["organization.name"];
          delete schema["project.name"];
          delete schema["container"];
          vm.gridSchema = SchemaMaker.uiGrid(schema,vm.filters);
          
          break;
          case 'organizations':
          vm.api = CoreService.env.apiUrl+'/Organizations/'+id+'/files';
          vm.uploadApi = CoreService.env.apiUrl+'/Organizations/'+id;
          
          // custom organizations schema
          delete schema["organization.name"];
          delete schema["project.name"];
          delete schema["container"];
          vm.gridSchema = SchemaMaker.uiGrid(schema,vm.filters);
          
          break;
          default:
          vm.api = CoreService.env.apiUrl+'/Files';
          vm.uploadApi = CoreService.env.apiUrl+'/Files';
          
          // auto create schema for ui-grid;
          vm.gridSchema = SchemaMaker.uiGrid(schema,vm.filters);
        }
      }
      
      vm.filters = {};
    
      // basic schema
      vm.schema = {
        "FileName": {
          field:"name",
          type: "string",
          // cellTemplate: '<div class="ui-grid-cell-contents">{{row.entity.source.substring(row.entity.source.lastIndexOf(\'/\')+1)}}</div>',
        },
        "download":{
          field: 'Download',
          enableSorting: false,
          enableFiltering: false,
          enableColumnMenu: false,
          enableCellEdit: false,
          cellClass:'showDownloadInGrid',
          cellTemplate: '<div class="ui-grid-cell-contents text-center"><file-downloader file="row.entity"></file-downloader></div>',
          width:90
        },
        "size": {
          type: "number",
          "cellFilter":'formatByte:this'
        },
        "componentModel": {
          type: "string",
          width:150
        },
        "container": {
          type: "string",
          width:120,              
          filter: {
            type: uiGridConstants.filter.SELECT,
            selectOptions: CoreService.env.StorageContainers
          }
        },
        "project.name": {
          enableSorting: false,
          enableFiltering: false,
          enableColumnMenu: false,
          enableCellEdit: false,
          type: "relation"
        },
        "organization.name": {
          enableSorting: false,
          enableFiltering: false,
          enableColumnMenu: false,
          enableCellEdit: false,
          type: "relation"
        }
      };
    
      
      vm.get = function (filters) {
        filters = filters || {};
        filters = Object.assign({},filters,vm.filters);
        return $http.get(vm.api,{ params: { filter: filters } });
      };
      
      vm.uploader = function(config){
        config = config || {};
        
        return new FileUploader({    
          url: vm.uploadApi,
          withCredentials: false,
          autoUpload: false,
          method: 'POST',
          removeAfterUpload: false,
          headers: {
              authorization: LoopBackAuth.accessTokenId
          }
        });
      }
      vm.delete = function (id, successCb, cancelCb) {
        CoreService.confirm(
          gettextCatalog.getString('Are you sure?'),
          gettextCatalog.getString('Deleting this cannot be undone'),
          function () {
            $http.delete(vm.api+'/'+ encodeURIComponent(id)).success(
              function () {
                CoreService.toastSuccess(
                  gettextCatalog.getString('File deleted'),
                  gettextCatalog.getString('Your file is deleted!')
                );
                successCb();
              });
          }, function (err) {
            CoreService.toastError(
              gettextCatalog.getString('Error deleting file'),
              gettextCatalog.getString('Your file is not deleted! ') + err);
            cancelCb();
          });
      };

    });

})();
