'use strict';

angular.module('com.module.projects')
  .component('projectUsers', {
      templateUrl: 'app/modules/projects/components/project-users/template.html',
      restrict: 'E',
      bindings:{
        'project': '='
      },
      controllerAs: 'vm',
      controller: function($q, $rootScope, $state, $timeout, ProjectUsers,uiGridConstants, CoreService,CONFIG,TeamService ) {
        var vm = this;
        ProjectUsers.projectId = vm.project.id;
        
        vm.selected={};
        vm.selectedRoles=[];
        vm.roles = CONFIG.ProjectRoles;
        vm.viewElement = CoreService.viewElement;
        vm.gridOptions = {
          enablePaginationControls: true,
          paginationPageSizes: [15],
          paginationPageSize: 15,
          enableFiltering: true,
          onRegisterApi: function(gridApi) {
            vm.gridApi = gridApi;
            
             vm.gridApi.core.on.filterChanged( null, function() {
              var grid = this.grid;
              var filters = grid.columns.reduce(function(acc,cur) {
                if (cur.filters[0].term && cur.field.indexOf('_label')===-1) acc[cur.field] = cur.filters[0].term;
                return acc;
              }, {});
              console.log("Filters:",filters);
            });
            
            vm.gridApi.core.on.sortChanged( null, function() {
              var grid = this.grid;
              var sort;
              for (var i=0,len=grid.columns.length;i<len;i++){
                sort=grid.columns[i].sort;
                if (sort.direction && sort.priority===0) {
                  sort.field=grid.columns[i].field;
                  break;
                }
              }
              console.log("Sort:",sort);
            });
            
          },
          columnDefs: [
            { name: 'email', cellTooltip:true, displayName: 'Email',width:250},    
            { name: 'firstName', cellTooltip:true, displayName: 'Name',width:100},    
            { name: 'lastName', cellTooltip:true, displayName: 'Surname',width:150},    
            { name: 'teams', cellTooltip:true, displayName: 'Teams',width:150},    
            { 
              name: 'roles_label', 
              displayName: 'Roles', 
              filter: {
                type: uiGridConstants.filter.SELECT,
                selectOptions: CONFIG.ProjectRoles
              }
            },
            {
              field: 'View',
              enableFiltering: false,
              enableColumnMenu: false,
              enableCellEdit: false ,
              cellTemplate: 
                '<div class="ui-grid-cell-contents">'+
                '<a href="" style="margin:2px" title="View" class="btn btn-xs btn-default" ng-click="grid.appScope.vm.viewElement(row.entity)"><i class="fa fa-eye"></i></a>'+
                '</div>',
              width: 70
            },
            {
              field: 'Edit',
              enableFiltering: false,
              enableColumnMenu: false,
              enableCellEdit: false ,
              cellTemplate: 
                '<div class="ui-grid-cell-contents">'+
                '<a href="" title="Edit" class="btn btn-xs btn-warning" style="margin:2px" ng-click="grid.appScope.vm.editElement(row.entity)"><i class="fa fa-pencil"></i></a>'+
                '</div>',
              width: 70
            },
            {
              field: 'Delete',
              enableFiltering: false,
              enableColumnMenu: false,
              enableCellEdit: false ,
              cellTemplate: 
                '<div class="ui-grid-cell-contents">'+
                '<a href="" title="Delete" class="btn btn-xs btn-danger" style="margin:2px" ng-click="grid.appScope.vm.deleteAllRoles(row.entity)"><i class="fa fa-trash"></i></a>'+
                '</div>',
              width: 70
            }
          ]
        };
        
          // BULK importer
          vm.gridOptionsImport = {
            enableGridMenu: true,
            enableFiltering: true,
            importerDataAddCallback: function ( grid, newObjects ) {
              vm.gridOptionsImport.data = newObjects;
              // fix height
              $timeout(function(){
                angular.element(document.getElementById('user-bulk-import-grid'))
                .css('height','100%');
              },500)
            },
            onRegisterApi: function(gridApi) { vm.gridApiImport = gridApi; },
          };
          var handleFileSelect = function( event ){
            event=event.originalEvent;
            var target = event.srcElement || event.target;
            // console.log(target);
            if (target && target.files && target.files.length === 1) {
              var fileObject = target.files[0];
              vm.gridApiImport.importer.importFile( fileObject );
              // target.form.reset();
            }
          };
          $('.file-chooser').change(handleFileSelect);
          vm.bulkProcess = function(){
            var users = vm.bulkDemoAccounts===true 
            ? vm.gridOptionsImport.data.map(function(u){u.demo=true; return u;}) 
            : vm.gridOptionsImport.data.map(function(u){ delete u.demo; return u;});
            vm.promise = ProjectUsers.inviteBulk(users)
              .then(function(res){
                // console.log(res);
                vm.selectedChanges=1;
                vm.editElement({});
              }).catch(console.log);
            return vm.promise;
          }
          
          vm.closeForm = function(){
            // hide form
            vm.formOpen=false;
            if(vm.selectedChanges>0) vm.initLoad();
          };
          /* Edit/Create single */
          vm.editElement = function(obj){
            if(obj.roles) {
              vm.selectedRoles=obj.roles.map(function(role){return role.role});
              vm.selected=obj;
            } else {
              vm.selected={};
              vm.selectedRoles=[];
            }
            
            // show form
            vm.formOpen=true; 
            vm.selectedChanges=0;
          }
          
          vm.selectUser = function(user){
            vm.selectedRoles=[];
            // check if user has already roles
            vm.searchLoaderRoles=ProjectUsers.load(user.id).then(function(res){
              vm.searchLoaderRoles=false;
              if(res.length>0) {
                var users = ProjectUsers.groupRoles(res);
                vm.selectedRoles=users[0].roles.map(function(role){return role.role});
              }
            })
          }
          
          vm.deleteAllRoles = function(user){
            vm.submitRoles(user,[]).then(function(){vm.initLoad()});
          }
          
          vm.submitRoles = function(user,roles){
            
            user.systemuserId = user.systemuserId || user.id;
            user.roles = user.roles || roles || [];
            
            var oldRoles = [];
            var deleteRoles=user.roles.filter(function(oldrole){ oldRoles.push(oldrole.role); return roles.indexOf(oldrole.role)===-1;});
            var insertRoles=roles
              .filter(function(newrole){ return oldRoles.indexOf(newrole)===-1;})
              .map(function(role){ return {
                systemuserId:user.systemuserId,
                projectId:vm.project.id,
                role:role
              }});
            // console.log("oldRoles:",oldRoles);
            // console.log("newRoles:",roles);
            // console.log("deleteRoles:",deleteRoles);
            // console.log("insertRoles:",insertRoles);
            var promises=[];
            var insertRolesArr = [];
            deleteRoles.forEach(function(o){promises.push(ProjectUsers.delete(o));})
            insertRoles.forEach(function(o){insertRolesArr.push(o);})
            vm.selectedChanges=(insertRoles.length||0)+(deleteRoles.length||0);
            if(insertRolesArr.length>0) promises.push(
              ProjectUsers.insert(insertRolesArr,user)
            )
            vm.promise=$q.all(promises).then(function(){
              vm.closeForm();
            })
            return vm.promise;
            
          }
          
          
          /* DATA HANDLING */
          vm.availableUsers=[];
          vm.searchUsers = function(search){
            if(search.length<1) return;
            var searchRegexp = new RegExp(search,'i').toString()
            var filters = {
              // limit:20,
              where: 
              // {email:{regexp:searchRegexp}}
              {
                or: [{
                  email:{regexp:searchRegexp}
                },{
                  firstName:{regexp:searchRegexp}
                },{
                  lastName:{regexp:searchRegexp}
                }]
              }
            };
            vm.searchLoaderUser = ProjectUsers.searchUsers(vm.project.organizationId,filters).then(function(res) {
                vm.searchLoaderUser = false;
                vm.availableUsers = res.data;
            });
          }

          // get all 
          vm.initLoad = function(){
            vm.promise=ProjectUsers.load().then(function(res) {
              res = res || [];
              vm.accessRoles=res;
              vm.loadGrid();
              vm.users =  res.filter(function(i){return !!i.systemUser;}).map(function(item) {
                item.email = item.systemUser.email;
                return item;
              });
            });
          };
          
          vm.loadGrid = function(data){
            data = data || vm.accessRoles;
            vm.gridOptions.data = ProjectUsers.groupRoles(data);
            CoreService.log(vm.gridOptions.data);
          };
        
          // Insert
          vm.insertElement = function(obj){
            ProjectUsers.insert(obj);
          };
          // Update
          vm.updateElement = function(obj){
            ProjectUsers.update(obj);
          }
          // Delete
          vm.deleteElement = function(obj){
            ProjectUsers.delete(obj).then(function(){
              vm.gridOptions.data = vm.gridOptions.data.filter(function(item){
                return obj.id!==item.id;
              })
            });
          }
          
          // 
          
          // init config
          TeamService.setProject(vm.project);
          vm.fillTeams = function(){
            var distributableStudents = vm.gridOptions.data.filter(function(s){
                return s.teams==="" && s.roles_label.indexOf("Student")>=0;
            });
            CoreService.confirm('Are you sure?','This will distribute '+distributableStudents.length +' students',
              function () {
                //   confirm
                vm.promise = TeamService.fillTeams().then(function(res){
                  CoreService.success('Success','Students have been distributed into teams.');
                  vm.initLoad();
                  return true;
                })
              },
              function () {
                //   cancel
              })
          }
            
        
          // Boot
          vm.initLoad();
          
      
        
      }    
          
  });
