(function() {
  'use strict';

  angular
    .module('com.module.core')
    .service('GridHelper', function($timeout) {

      var vm = this;

      vm.gridOptions = function(schema, getPageData, paginationOptions, filterOptions, config, gridApiBinding) {
        // Configuration
        var controllerAs = config?'.'+config.controllerAs||'':'';
        
        // actionColumns
        config = config || {};
        var actionColumns = [];
        config.actionColumns = config.actionColumns || {};
        // defaults
        config.actionColumns.viewElement = config.actionColumns.viewElement || {
          template:'<a href="" style="margin:2px" title="View" class="btn btn-xs btn-default" ng-click="grid.appScope'+controllerAs+'.viewElement(row.entity)"><i class="fa fa-eye"></i></a>'
        }
        config.actionColumns.editElement = config.actionColumns.editElement || {
          template:'<a href="" style="margin:2px" title="Edit" class="btn btn-xs btn-warning" ng-click="grid.appScope'+controllerAs+'.editElement(row.entity)"><i class="fa fa-pencil"></i></a>'
        }
        config.actionColumns.deleteElement = config.actionColumns.deleteElement || {
          template:'<a href="" style="margin:2px" title="Delete" class="btn btn-xs btn-danger" ng-click="grid.appScope'+controllerAs+'.deleteElement(row.entity)"><i class="fa fa-trash"></i></a>'
        }
        if(!config.actionColumns.viewElement.disabled) actionColumns.push(createActionColumn('view',config.actionColumns.viewElement.template)); 
        if(!config.actionColumns.editElement.disabled) actionColumns.push(createActionColumn('edit',config.actionColumns.editElement.template)); 
        if(!config.actionColumns.deleteElement.disabled) actionColumns.push(createActionColumn('delete',config.actionColumns.deleteElement.template)); 
        
        return {
          enableFiltering: true,
          enableColumnResizing: true,
          paginationPageSizes: [15],
          paginationPageSize: paginationOptions.pageSize || 15,
          useExternalPagination: true,
          useExternalSorting: true,
          useExternalFiltering: true,
          onRegisterApi: function(gridApi) {
            // vm.gridApi = gridApi;0
            gridApiBinding = gridApi;

            // external pagination
            gridApi.pagination.on.paginationChanged(null, function(newPage, pageSize) {
              paginationOptions.pageNumber = newPage;
              paginationOptions.pageSize = pageSize;

              filterOptions.limit = paginationOptions.pageSize;
              filterOptions.skip = paginationOptions.pageSize * (paginationOptions.pageNumber - 1);
              getPageData();
            });

            // external filters
            gridApi.core.on.filterChanged(null, function() {
              var grid = this.grid;
              var filters = grid.columns.reduce(function(acc, cur) {
                if (cur.field.indexOf('_label') === -1 &&
                  cur.filters[0].term &&
                  cur.filters[0].term.length > 0) {
                  acc[cur.field] = {
                    "regexp": new RegExp(cur.filters[0].term, 'i').toString()
                  };
                  // or like {"like":cur.filters[0].term}
                }
                return acc;
              }, {});
              
              if (Object.keys(filters).length > 0) {

                filterOptions.where = filters;
                // debounce 1sec
                $timeout.cancel(vm.filtersDebounced);
                vm.filtersDebounced = $timeout(function() {
                  getPageData();
                }, 1000);
              }
              else {

                delete filterOptions.where;
                // do not debounce for clearing filters
                getPageData();
              }
            });

            // external sorting
            gridApi.core.on.sortChanged(null, function() {
              var grid = this.grid;
              var sort;
              for (var i = 0, len = grid.columns.length; i < len; i++) {
                sort = grid.columns[i].sort;
                if (sort.direction && sort.priority === 0) {
                  sort.field = grid.columns[i].field;
                  break;
                }
              }
              if (sort.direction) {
                filterOptions.order = sort.field + ' ' + sort.direction.toUpperCase();
              }
              else {
                delete filterOptions.order;
              }
              getPageData();
            });

          },
          columnDefs: schema.concat(actionColumns)
          
        };

      };
      
      
      function createActionColumn(field,template){
        return {
          name: field,
          enableSorting: false,
          enableFiltering: false,
          enableColumnMenu: false,
          enableCellEdit: false,
          cellTemplate: '<div class="ui-grid-cell-contents">' + template + '</div>',
          width: 60
        }
      }

      return vm;

    });


})();
