(function () {
  'use strict';
  angular
    .module('com.module.gradingschemes')
    .config(function ($stateProvider) {
      $stateProvider
        .state('app.gradingschemes', {
          abstract: true,
          url: '/gradingschemes',
          templateUrl: 'app/modules/gradingschemes/views/main.html'
        })
        .state('app.gradingschemes.list', {
          url: '/list',
          templateUrl: 'app/modules/gradingschemes/views/list.html',
          controllerAs: 'ctrl',
          controller: function (GradingSchemeService) {
            var vm = this;
            
            vm.loading = GradingSchemeService.find().then(function(res){
              vm.gradingschemes = res.data;
            })
            
            
          }
        })
        .state('app.gradingschemes.add', {
          url: '/add',
          templateUrl: 'app/modules/gradingschemes/views/form.html',
          controllerAs: 'ctrl',
          controller:'GradingSchemeSingleController',
          resolve: {
            gradingscheme: function () {
              return {data:{scheme:[]}};
            }
          }
        })
        .state('app.gradingschemes.edit', {
          url: '/:id/edit',
          templateUrl: 'app/modules/gradingschemes/views/form.html',
          controllerAs: 'ctrl',
          controller:'GradingSchemeSingleController',
          resolve: {
            gradingscheme: function ($stateParams, GradingSchemeService) {
              return GradingSchemeService.findById($stateParams.id);
            }
          }
        })
        .state('app.gradingschemes.delete', {
          url: '/:id/delete',
          template: '',
          controllerAs: 'ctrl',
          controller: function ($stateParams, $state, GradingSchemeService) {
            GradingSchemeService.delete($stateParams.id, function () {
              $state.go('^.list');
            }, function () {
              $state.go('^.list');
            });
          }
        });
    });

})();
