(function () {
  'use strict';

  /*jshint sub:true*/
  /*jshint camelcase: false */

angular
    .module('com.module.organizations',[])
    .config(function ($stateProvider) {
      $stateProvider
        .state('app.organizations', {
          abstract: true,
          url: '/organizations',
          templateUrl: 'app/modules/organizations/views/main.html',
          resolve:{
            admin:function(AppAuth,currentUser){
                return AppAuth.isAdmin(currentUser);
            }
          }
        })
        .state('app.organizations.list', {
          url: '',
          templateUrl: 'app/modules/organizations/views/list.html',
          controllerAs: 'ctrl',
          controller: function (OrganizationService) {
            var vm = this;
            vm.loading = OrganizationService.get().then(function(data){
                // console.log("Organizations list: ",data);
                vm.organizations = data.sort(function(a,b){
                  return a.name>b.name?1:-1;
                });
            });
            vm.organizationFilter = '';
          }
        })
        .state('app.organizations.add', {
          url: '/add',
          templateUrl: 'app/modules/organizations/views/form.html',
          controllerAs: 'ctrl',
          controller: function ($state, OrganizationService, organization) {
            var vm = this;

            vm.organization = organization;
            vm.formFields = OrganizationService.getFormSchema();
            vm.formOptions = {};
            vm.submit = function () {
              OrganizationService.save(vm.organization).then(function () {
                $state.go('^.list');
              });
            };
          },
          resolve: {
            organization: function () {
              return {};
            }
          }
        })
        .state('app.organizations.edit', {
          url: '/:id/edit',
          templateUrl: 'app/modules/organizations/views/form.html',
          controllerAs: 'ctrl',
          controller: function ($state, OrganizationService, organization, BillingService, LTIservice, CloudConfigService, CoreService) {
            var vm = this;
            vm.organization = organization;
            vm.organization.allowedEmails = vm.organization.allowedEmails||[];
            vm.lti_enabled = vm.organization.lti;
            vm.isProd = window.ENV==='production';
            vm.ltiDomain = vm.isProd?'lti':'lti-test';
            vm.formFields = OrganizationService.getFormSchema();
            vm.formOptions = {};
            vm.submit = function () {
              OrganizationService.save(vm.organization)
              .then(function () {
                var p = [];
                if(vm.organizationBilling) {
                  p.push(vm.updateBilling())
                }
                if(vm.organization.lti) {
                  vm.LTI_config.name = vm.organization.name;
                  p.push(OrganizationService.updateLTIconfig(organization.id,vm.LTI_config));
                }
                if(vm.CloudConfig_config.domain) {
                  vm.CloudConfig_config.name = vm.organization.name;
                  p.push(OrganizationService.updateCloudConfig(organization.id,vm.CloudConfig_config));
                }
                return Promise.all(p)
              })
              .then(function(res){
                $state.go('^.list');
              })
              .catch(function(err){
                CoreService.error(err)
              })
            };
            
            /* Billing details => Create/update Stripe customer */
            if(organization.billing) {
              vm.organizationBilling = Object.assign({},organization.billing);
              vm.enableBilling = true;
              delete organization.billing;
            }              
            
            vm.billingformFields = BillingService.getFormSchema();
            vm.billingformOptions = {};
            vm.updateBilling = function() {
              // update vat type
              vm.organizationBilling.vatType = BillingService.getVATtype(vm.organizationBilling.country);
              return OrganizationService.updateBilling(vm.organization.id,vm.organizationBilling)
            }
            
            
            // Handle LTI Configuration
            vm.LTI_formFields = LTIservice.getFormSchema();
            vm.LTI_formOptions = {};
            // auto load lti config
            OrganizationService.getLTIconfig(organization.id).then(function(config){
              vm.LTI_config = config;
            });
            
            // Handle CloudConfig Configuration
            vm.CloudConfig_formFields = CloudConfigService.getFormSchema(vm.organization.id);
            vm.CloudConfig_formOptions = {};
            // auto load lti config
            OrganizationService.getCloudConfig(organization.id).then(function(config){
              config.webhook_config = config.webhook_config || '{"enrollment":null, "module_group": null, "module": null}';
              config.email_config = config.email_config || '{"template":null, "invitation":{"text":null, "btn_text":null, "btn_link": null}, "forgot_password": null, "login_link": null}';
              vm.CloudConfig_config = config;
            });
            
            
          
          },
          resolve: {
            organization: function ($stateParams, OrganizationService) {
              return OrganizationService.getById($stateParams.id);
            }
          }
        })
        .state('app.organizations.view', {
          url: '/:id',
          abstract:true,
          templateUrl: 'app/modules/organizations/views/view.html',
          controllerAs: 'ctrl',
          controller: function ($timeout,$state,organization) {
            var vm = this;
            vm.organization = organization;            
            vm.activeTab = null;
            vm.tabs=[{
              state:'.users',
              label:'Users'
            },{
              state:'.reports.list',
              label:'Report Templates'
            },{
              state:'.files.list',
              label:'Files'
            }];
          },
          resolve: {
            organization: function ($stateParams, OrganizationService) {
              return OrganizationService.getById($stateParams.id);
            }
          }
        })      
        
        .state('app.organizations.view.users', {
          url: '/users',
          template: '<organization-users organization="ctrl.organization"></organization-users>'
        })
        
        .state('app.organizations.view.files', {
          abstract: true,
          url: '/files',
          templateUrl: 'app/modules/files/views/main.html',
          controller:function($scope){
            $scope.projectFiles = true;
          }
        })
        .state('app.organizations.view.files.list', {
          url: '',
          templateUrl: 'app/modules/files/views/list.html',
          controllerAs: 'ctrl',
          controller: 'FilesListController'
        })
        .state('app.organizations.view.files.upload', {
          url: '/upload',
          templateUrl: 'app/modules/files/views/upload.html',
          controllerAs: 'ctrl',
          controller: function (FileUploader, FileService,$stateParams) {
            this.defaultComponentModel = 'other';
            FileService.setApi('organizations',$stateParams.id);
            this.uploader = FileService.uploader({url:'/Organizations/'+$stateParams.id});
            // auto-attach the handlers
            this.uploader.onBeforeUploadItem = function(item) {
              item.headers['x-upload-filename'] = item.file.name;
              item.url = item.url + '/' + item.file.componentModel + '/uploadFile';
            };
          }
        })
        .state('app.organizations.view.reports', {
          abstract: true,
          url: '/reports',
          templateUrl: 'app/modules/files/views/main.html',
          controller:function($scope){
            $scope.projectFiles = true;
          }
        })
        .state('app.organizations.view.reports.list', {
          url: '',
          templateUrl: 'app/modules/files/views/list.html',
          controllerAs: 'ctrl',
          controller: function($uibModal, FileService, GridHelper, CoreService, $state) {
            var vm = this;

            vm.cdnUrl = CoreService.env.cdnUrl;
            vm.id = $state.params.id;
            var FilesApiType = $state.current.name.split('.')[1];

            FileService.setApi(FilesApiType, vm.id);

            vm.config = {
              controllerAs: 'ctrl',
              // disable edit
              actionColumns: {
                editElement: {
                  disabled: true
                }
              }
            };

            /* List */
            var schema = FileService.gridSchema;
            vm.filters = { limit: 15, skip: 0, where: {componentModel: 'ReportTemplate'} };
            vm.pagination = {
              pageNumber: 1,
              pageSize: 15,
              sort: null
            };
            vm.loadListData = function(filters) {
              filters = filters || vm.filters;
              console.log("Req Filter", filters);
              vm.loading = FileService.get(filters).then(function(res) {
                var headers = res.headers();
                vm.gridOptions.totalItems = Number(headers['x-total-count']);
                vm.gridOptions.data = res.data; //postProcess(res.data);
              });
            };
            vm.gridOptions = GridHelper.gridOptions(schema, vm.loadListData, vm.pagination, vm.filters, vm.config);
            vm.loadListData();

            vm.deleteElement = function(elem) {
              FileService.delete(elem.id,
                function() {
                  vm.loadListData();
                },
                function() {

                });
            };
            vm.viewElement = CoreService.viewElement;

          }

        })
        .state('app.organizations.view.reports.upload', {
          url: '/upload',
          templateUrl: 'app/modules/files/views/upload.html',
          controllerAs: 'ctrl',
          controller: function (FileUploader, FileService,$stateParams) {
            FileService.setApi('organizations',$stateParams.id);
            var vm = this;
            vm.defaultComponentModel = 'ReportTemplate';
            vm.uploader = FileService.uploader({url:'/Organizations/'+$stateParams.id});
            // auto-attach the handlers
            vm.uploader.onBeforeUploadItem = function(item) {
              item.headers['x-upload-filename'] = item.file.name;
              item.url = item.url + '/' + item.file.componentModel + '/uploadFile';
            };
          }
        })
        .state('app.organizations.delete', {
          url: '/:id/delete',
          template: '',
          controllerAs: 'ctrl',
          controller: function ($state, CoreService,OrganizationService, organization) {
            CoreService.confirm('Are you sure?','Deleting this cannot be undone',
              function () {
                //   confirm
                OrganizationService.delete(organization).then(function () {
                    CoreService.success('Deleted successfully',(organization.name||organization.id)+' deleted!');
                    $state.go('^.list');
                }).catch(CoreService.error);
              },
              function () {
                //   cancel
              }
            );
          },
          resolve: {
            organization: function ($stateParams, OrganizationService) {
              return OrganizationService.getById($stateParams.id);
            }
          }
        });
    }
  )

   
    
})();