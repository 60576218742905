(function () {
  'use strict';
  angular
    .module('com.module.gradingschemes')
    .service('GradingSchemeService', function ($state, CoreService, Setting, gettextCatalog,$http) {
      var vm = this;
      vm.gradingschemes = [];
      vm.find = function () {
        return $http.get(CoreService.env.apiUrl+'/Settings/key/grading.scheme');
      };
      
      vm.getConf = function() {
        return $http.get(CoreService.env.apiUrl + '/Settings/key/grading.scheme', {
            ignoreLoadingBar: true
          })
          .then(function(res) {
            var roles = res.data[0].data.allowedLoginRoles;
            console.log(res);
            window.AESG.allowedLoginRoles = roles;
          })
      }

      vm.key = function (key) {
        for(var i=0,len=vm.gradingschemes.length;i<len;i++){
          if(vm.gradingschemes[i].key===key) return vm.gradingschemes[i];
        }
        return null;
      };

      vm.findById = function (id) {
        return Setting.findById({
          id: id
        }).$promise;
      };
      
      vm.findByKey = function (key) {
        return Setting.find({
          filter:{
            where:{
              key: key
            }
          }
        }).$promise.then(function(res){
          return res[0];
        });
        
      }

      vm.upsert = function (gradingscheme) {
        return Setting.upsert(gradingscheme).$promise
          .then(function () {
            CoreService.toastSuccess(
              gettextCatalog.getString('Setting saved'),
              gettextCatalog.getString('Your grading scheme is safe with us!')
            );
          })
          .catch(function (err) {
            CoreService.toastError(
              gettextCatalog.getString('Error saving grading scheme '),
              gettextCatalog.getString('This grading scheme could no be saved: ' + err)
            );
          }
        );
      };

      vm.delete = function (id, successCb, cancelCb) {
        CoreService.confirm(
          gettextCatalog.getString('Are you sure?'),
          gettextCatalog.getString('Deleting this cannot be undone'),
          function () {
            Setting.deleteById({id: id}, function () {
              CoreService.toastSuccess(
                gettextCatalog.getString('Setting deleted'),
                gettextCatalog.getString('Your grading scheme is deleted!'));
              successCb();
            }, function (err) {
              CoreService.toastError(
                gettextCatalog.getString('Error deleting grading scheme'),
                gettextCatalog.getString('Your grading scheme is not deleted! ') + err);
              cancelCb();
            });
          },
          function () {
            cancelCb();
          }
        );
      };


      return vm;

    });

})();
