(function () {
  'use strict';
  angular
    .module('com.module.reporting')
    .service('ReportingService', function ($state, CoreService, Setting, gettextCatalog,$http,LoopBackAuth) {
      var vm = this;
      vm.reporttemplates = [];
      vm.find = function () {
        return $http.get(CoreService.env.apiUrl+'/Settings/key/report.template');
      };
      
      vm.key = function (key) {
        for(var i=0,len=vm.reporttemplates.length;i<len;i++){
          if(vm.reporttemplates[i].key===key) return vm.reporttemplates[i];
        }
        return null;
      };

      vm.findById = function (id) {
        return Setting.findById({
          id: id
        }).$promise;
      };
      
      vm.findByKey = function (key) {
        return Setting.find({
          filter:{
            where:{
              key: key
            }
          }
        }).$promise.then(function(res){
          return res[0];
        });
        
      }

      vm.upsert = function (report) {
        return Setting.upsert(report).$promise
          .then(function () {
            CoreService.toastSuccess(
              gettextCatalog.getString('Report saved')
              
            );
          })
          .catch(function (err) {
            CoreService.toastError(
              gettextCatalog.getString('Error saving report template '),
              gettextCatalog.getString('This report template could no be saved: ' + err)
            );
          }
        );
      };

      vm.delete = function (id, successCb, cancelCb) {
        CoreService.confirm(
          gettextCatalog.getString('Are you sure?'),
          gettextCatalog.getString('Deleting this cannot be undone'),
          function () {
            Setting.deleteById({id: id}, function () {
              CoreService.toastSuccess(
                gettextCatalog.getString('Report deleted'),
                gettextCatalog.getString('Your report template is deleted!'));
              successCb();
            }, function (err) {
              CoreService.toastError(
                gettextCatalog.getString('Error deleting report template'),
                gettextCatalog.getString('Your report template is not deleted! ') + err);
              cancelCb();
            });
          },
          function () {
            cancelCb();
          }
        );
      };
      

      return vm;

    });

})();
