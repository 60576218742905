(function() {
  'use strict';
  angular
    .module('com.module.gradingschemes')
    .controller('GradingSchemeSingleController', function($timeout, $state, GradingSchemeService, gradingscheme) {
      var vm = this;
      vm.gradingscheme = gradingscheme;
      vm.gradingscheme.data = vm.gradingscheme.data || {};
      vm.formOptions = {};
      vm.gradingschemeData = vm.gradingscheme.data; 
      vm.submit = function() {
        if(!vm.gradingscheme.key) {
          vm.gradingscheme.type = 'grading.scheme';
          console.log("Creating new GradingScheme", gradingscheme);
        }        
        GradingSchemeService.upsert(vm.gradingscheme).then(function() {
          $state.go('^.list');
        });
      };
      vm.formFields = [{
          className: "col-xs-7",
          key: 'data.label',
          type: 'input',
          templateOptions: {
            label: 'Label',
            required: true
          }
        },
        {
          className: "col-xs-2",
          key: 'data.strict',
          type: 'select',
          templateOptions: {
            required:true,
            label: 'Grade rounding',      
            "options": [
              {
                "name": "disabled",
                "value": true
              },
              {
                "name": "enabled",
                "value": false
              }
            ]
          }
        },
        {
          className: "col-xs-3",
          key: 'data.passingThreshold',
          type: 'input',
          templateOptions: {
            type: 'number',
            min: 0,
            max: 100,
            label: 'Passing Threshold (%)',
            required: true
          }
        }
      ]



    });

})();
