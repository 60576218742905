(function() {
  'use strict';
  angular
    .module('com.module.core')
    .service('LTIservice', function($q, $http, CoreService) {
      var vm = this;
      
      

      // create schema for angular-formly
      vm.getFormSchema = function() {
        return [
          {
            key: 'lti1p0',
            type: 'checkbox',
            templateOptions: {
              label: 'LTI Version 1.0'
            }
          },
          {
            key: 'lti1p1',
            type: 'checkbox',
            templateOptions: {
              label: 'LTI Version 1.1'
            }
          },
          {
            key: 'lti1p3',
            type: 'checkbox',
            templateOptions: {
              disabled:true,
              label: 'LTI Version 1.3'
            }
          },
          {
            key: 'consumer_key',
            type: 'input',
            templateOptions: {
              label: 'OAuth consumer key',
              required: false
            }
          },
          {
            key: 'shared_secret',
            type: 'input',
            templateOptions: {
              label: 'OAuth shared secret',
              required: false
            }
          },
          {
            key: 'signature_method',
            type: 'select',
            defaultValue: 'HMAC-SHA1',
            templateOptions: {
              label: 'OAuth signature method',
              options: [],
              valueProp: 'value',
              labelProp: 'label',
              required: false,
              placeholder: 'Select LTI version'
            },
            controller: /* @ngInject */ function($scope) {
              $scope.to.options = [
                {value:'HMAC-SHA256',label: 'HMAC-SHA256'},
                {value:'HMAC-SHA1',label: 'HMAC-SHA1'}
              ]
            }
          },
          {
            key: 'favicon',
            type: 'input',
            templateOptions: {
              label: 'Favicon URL (Optional)',
              required: false
            }
          },
          {
            key: 'logo',
            type: 'input',
            templateOptions: {
              label: 'Logo - small image',
              required: false
            }
          },
          {
            key: 'url',
            type: 'input',
            templateOptions: {
              label: 'Partner url',
              required: false
            }
          },
          {
            key: 'help_icon',
            type: 'input',
            templateOptions: {
              label: 'Help Icon (Optional)',
              required: false
            }
          },
          {
            key: 'help_title',
            type: 'input',
            templateOptions: {
              label: 'Help Title (Optional)',
              required: false
            }
          },
          {
            key: 'help_url',
            type: 'input',
            templateOptions: {
              label: 'Help Url (Optional)',
              required: false
            }
          },
          {
            key: 'custom_config',
            type: 'textarea',
            templateOptions: {
              label: 'Partner custom JSON configuration (Optional)',
              required: false
            }
          },
          {
            key: 'custom_css',
            type: 'textarea',
            templateOptions: {
              label: 'Partner custom CSS (Optional)',
              required: false
            }
          },
          {
            key: 'custom_params',
            type: 'textarea',
            templateOptions: {
              label: 'Custom Parameters (Optional)',
              placeholder: 'custom_control=async&custom_flow_control=2...',
              required: false
            }
          }

        ]
      }




      return vm;
    });

})();
