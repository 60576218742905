(function () {
  'use strict';
  angular
    .module('com.module.authproviders')
    .service('AuthProvidersService', function ($state, CoreService, Setting, gettextCatalog,$http,LoopBackAuth) {
      var vm = this;
      vm.authproviders = [];
      vm.find = function () {
        return $http.get(CoreService.env.apiUrl+'/AuthProviders');
      };
      
      vm.findById = function (id) {
        return $http.get(CoreService.env.apiUrl+'/AuthProviders/'+id).then(function(res){
          return res.data;
        })
      };

      vm.upsert = function(provider) {
        var p;
        if (provider.id) {
          // put
          p = $http.put(CoreService.env.apiUrl + '/AuthProviders/' + provider.id, provider);
        }
        else {
          // post
          p = $http.post(CoreService.env.apiUrl + '/AuthProviders', provider);

        }
        p
        .then(function(res){
          return res.data;
        })
        .then(function() {
            CoreService.toastSuccess(
              gettextCatalog.getString('Provider saved')
            );
          })
          .catch(function(err) {
            CoreService.toastError(
              gettextCatalog.getString('Error saving provider'),
              err.toString()
            );
          });
        return p;
      };

      vm.delete = function(id, successCb, cancelCb) {
        CoreService.confirm(
          gettextCatalog.getString('Are you sure?'),
          gettextCatalog.getString('Deleting this cannot be undone'),
          function() {
            $http.delete(CoreService.env.apiUrl + '/AuthProviders/' + id)
              .then(function(){
                CoreService.toastSuccess(
                  gettextCatalog.getString('Provider deleted')
                )
                successCb();
              })
              .catch(function(err) {
                CoreService.toastError(
                  gettextCatalog.getString('Error deleting provider'),
                  err.toString()
                )
                cancelCb();
              })
          },
          function() {
            cancelCb();
          }
        );
      };
      

      return vm;

    });

})();
