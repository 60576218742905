(function () {
  'use strict';
  angular
    .module('com.module.users')
    .service('UserService', function ($state, CoreService, SystemUser, gettextCatalog, SystemAccess) {
      
      var SystemAccessFilter = {"filter": {"include":["role","systemUser"]}}
      this.find = function (all) {
        if(all) return SystemUser.find().$promise;
        else return SystemAccess.find(SystemAccessFilter).$promise.then(function(res){
          var users = res.reduce(function(acc,cur){
            // transform to user
            var id = cur.principalId;
            if(acc[id]) {
              acc[id].roles.push(cur.role)              
            } else {
              acc[id] = Object.assign({},cur.systemUser,{roles:[cur.role]})
            }
            return acc;
          },{});
          var usersArray=[];
          for (var key in users){
            usersArray.push(users[key]);
          }
          return usersArray;
        });
      };

      this.findById = function (id) {
        return SystemUser.findById({
          id: id,
          filter:{include:"systemRoles"}
        }).$promise.then(function(res){
          res.roles = res.systemRoles;
          delete res.systemRoles;
          return res;
        });
      };
      this.deleteSystemRole = function(user,role) {
          return SystemAccess.findOne({filter:{where:{
            roleId:role.id,
            principalType:'USER',
            principalId:user.id
          }}}).$promise
          .then(function(res){
            return SystemAccess.destroyById({id:res.id}).$promise
          })
          .then(CoreService.success)
          .catch(CoreService.error);
      };
      this.insertSystemRole = function(user,role){
        return SystemAccess.create({
          principalId:user.id,
          principalType:'USER',
          roleId:role.id
        }).$promise
          .then(CoreService.success)
          .catch(CoreService.error);
      }

      this.upsert = function (user) {
        return SystemUser.upsert(user).$promise
          .then(function () {
            CoreService.toastSuccess(
              gettextCatalog.getString('SystemUser saved'),
              gettextCatalog.getString('Your user is safe with us!')
            );
          })
          .catch(function (err) {
            CoreService.toastError(
              gettextCatalog.getString('Error saving user '),
              gettextCatalog.getString('This user could no be saved: ' + err)
            );
          }
        );
      };

      this.delete = function (id, successCb, cancelCb) {
        CoreService.confirm(
          gettextCatalog.getString('Are you sure?'),
          gettextCatalog.getString('Deleting this cannot be undone'),
          function () {
            SystemUser.deleteById({id: id}, function () {
              CoreService.toastSuccess(
                gettextCatalog.getString('SystemUser deleted'),
                gettextCatalog.getString('Your user is deleted!'));
              successCb();
            }, function (err) {
              CoreService.toastError(
                gettextCatalog.getString('Error deleting user'),
                gettextCatalog.getString('Your user is not deleted! ') + err);
              cancelCb();
            });
          },
          function () {
            cancelCb();
          }
        );
      };


      this.getFormFields = function (formType) {
        var form = [
          {
            key: 'email',
            type: 'input',
            templateOptions: {
              label: gettextCatalog.getString('Email'),
              required: true
            },
            expressionProperties: {
              'templateOptions.disabled':'model.id'
            }
          },
          {
            key: 'firstName',
            type: 'input',
            templateOptions: {
              label: gettextCatalog.getString('First name'),
              required: true
            }
          },
          {
            key: 'lastName',
            type: 'input',
            templateOptions: {
              label: gettextCatalog.getString('Last name'),
              required: true
            }
          }
        ];
        if (formType === 'add') {
          form.push({
            key: 'password',
            type: 'input',
            templateOptions: {
              label: gettextCatalog.getString('Password'),
              required: true
            }
          });
        }
        return form;
      };

    });

})();
